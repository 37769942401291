import React, { useEffect, useState } from "react";
import "../../assets/css/front/style.css";
import "../../assets/css/front/crypto.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { GiCampfire } from "react-icons/gi";
import { getPostFromFirebaseByCategory } from "../../server/firebase.config";
import Moment from "react-moment";
import { Link } from "react-router-dom";
const Crypto = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [crypto, setCrypto] = useState(null);

  const effect = useEffect(() => {
    const subs = getPostFromFirebaseByCategory("crypto").subscribe((item) => {
      setCrypto(item);
    });

    return () => {
      subs.unsubscribe();
    };
  }, []);

  return (
    <>
      <Header></Header>

      <div className="clearfix"></div>

      <div className="crypto">
        <div className="crypto_head">
          <div className="crypto_head_title">&nbsp;Cryptocurrency</div>

          <div className="crypto_head_subtitle">
            Your insights to keep you afloat
          </div>
        </div>

        <Container fluid className="crypto_posts">
          <Row>
            {crypto?.length === 0 ? (
              <div>No crypto news</div>
            ) : (
              crypto?.map((item) => (
                <Col xs="12" md="3">
                  <Card className="main-carousel">
                    <img src={item?.mainImage?.imageUrl} />

                    {item?.hot === "yes" ? (
                      <div class="crypto-hot">
                        <GiCampfire></GiCampfire> &nbsp;Hot
                      </div>
                    ) : (
                      <> </>
                    )}

                    {item?.new === "yes" ? (
                      <div class="crypto-new">New</div>
                    ) : (
                      <> </>
                    )}

                    <Card.Body>
                      <Card.Title className="main-carousel_title">
                        {item?.title}
                      </Card.Title>
                      <Card.Text>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${item?.editorData.substring(
                              0,
                              "100"
                            )}...`,
                          }}
                        ></div>
                      </Card.Text>
                      <span>
                        {/* {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(item?.timestamp)}{" "}
                      &nbsp; */}

                        <Moment format="D MMM YYYY" interval={10000} withTitle>
                          {item?.timestamp}
                        </Moment>
                      </span>
                      <Link to={`/single?id=${item.id}`}>
                        <Button className="main-carousel_btn">Read More</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>

        <div className="more-news">
          <div className="more-news_btn">Load More</div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Crypto;
