import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/styleNew.css";
import oc from "../../assets/images/dashboard/oc.png";
import { Button } from "react-bootstrap";
import { AdvancedChart } from "react-tradingview-embed";
import video from "../../assets/images/dashboard/vid.png";
import { getPostFromFirebaseByCategory } from "../../server/firebase.config";
import { GiCampfire } from "react-icons/gi";

const Tech = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [tech, setTech] = useState(null);

  const effect = useEffect(() => {
    const subscription = getPostFromFirebaseByCategory("technicals").subscribe(
      (item) => {
        setTech(item);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <Container  className="mb">
              <Row className="row">

              {tech?.length === 0 ? (
                  <></>
                ) : (
                  tech?.map((item) => (
                <Col sm="12" md="3" >
                  <Card className="main-carousel">
                    <img src={item?.mainImage?.imageUrl} />

                    {item?.hot === "yes" ? (
                          <div class="crypto-hot">
                            <GiCampfire></GiCampfire> &nbsp;Hot
                          </div>
                        ) : (
                          <> </>
                        )}

                        {item?.new === "yes" ? (
                          <div class="crypto-new">New</div>
                        ) : (
                          <> </>
                        )}

                    <Card.Body>
                      <Card.Title className="main-carousel_title">
                      {item?.title}
                      </Card.Title>
                      <Card.Text>
                      <div
                              dangerouslySetInnerHTML={{
                                __html: `${item?.editorData?.substring(
                                  0,
                                  "50"
                                )}...`,
                              }}
                            ></div>
                      </Card.Text>

                      <Card.Text>
                      28 Aug 2021
                      </Card.Text>

                      <a href={`/view-post?id=${item.id}`}>
                            <Button className="main-carousel_btn">
                              Read More
                            </Button>
                          </a>
                    </Card.Body>
                  </Card>
                </Col>
                ))
                )}

              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Tech;
