import React, { useEffect, useState } from "react";
import "../../../assets/css/clients/sidebarnew.css";
import { Link } from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import {
  FaBitcoin,
  FaNewspaper,
  FaSignal,
  FaSignOutAlt,
  FaWifi,
  FaChartArea,
  FaVideo,
} from "react-icons/fa";
import {
  FaTelegram,
  FaInstagram,
  FaShoppingCart,
  FaTwitter,
  FaUserAlt,
  FaYoutube,
} from "react-icons/fa";
import { CgMediaLive } from "react-icons/cg";
// import {SiManageic} from "react-icons/si";
import { BiSupport } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { GrUpgrade } from "react-icons/gr";
import { RiDashboardLine } from "react-icons/ri";
import { firstValueFrom } from "rxjs";
import { fireAuthState } from "../../../server/firebase.config";
import {
  getSettings,
  getAscCourses,
  getOneUsers,
} from "../../../server/firebase.config";

const Sidebar = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [Courses, setCourses] = useState(null);
  const [socials, setSocials] = useState(null);
  const [User, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [signals, setSignals] = useState(null);
  const [online, setOnline] = useState(null);
  const [livee, setLive] = useState(null);
  const [allSub, setAllSub] = useState(null);
  const [darkMode, setDarkMode] = useState("light");
  const [slideSidebar, setslideSidebar] = useState(false);

  // console.log(User);
  const effect = useEffect(() => {
    firstValueFrom(getSettings())
      .then((item) => {
        //   console.log(item)
        setSocials(item);
      })
      .catch((error) => {
        console.log(error);
      });

    const subs = [];

    firstValueFrom(fireAuthState)
      .then((user) => {
        if (user) {
          setUser(user);
          // alert(user.uid)
          const subscribe = getOneUsers(user.uid).subscribe((item) => {
            setUsers(item);

            // alert(item.subscription);

            if (item.subscription === "signals") {
              setSignals(true);
              setOnline(false);
              setLive(false);
              setAllSub(false);
            } else if (item.subscription === "online") {
              setSignals(false);
              setOnline(true);
              setLive(false);
              setAllSub(false);
            } else if (item.subscription === "live") {
              setSignals(false);
              setOnline(false);
              setLive(true);
              setAllSub(false);
            } else if (item.subscription === "all") {
              setAllSub(true);
              setSignals(false);
              setOnline(false);
              setLive(false);
            }

            // console.log(item?.subscription)
          });

          subs.push(subscribe);
        }
      })

      .catch((error) => {
        console.log(error);
      });

    firstValueFrom(getAscCourses())
      .then((course) => {
        // alert(course)
        if (course) {
          setCourses(course);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // const subscription = getAscCourses().subscribe((item) => {
    //   setCourses(item);
    // });

    return () => {
      subs.forEach((element) => {
        element.unsubscribe();
      });
      // subscription.unsubscribe();
    };
  }, []);

  const setMode = (mode) => {
    window.localStorage.setItem("darkMode", mode);
    setDarkMode(mode);
  };

  const editForm1 = () => {
    setDarkMode("dark");
  };

  const slide = () => {
    setslideSidebar(true);
  };


  return (
    <div className="sidebarNew">
        {/* <div className="menu_icon">
            <GiHamburgerMenu className="menu_icon"></GiHamburgerMenu>
        </div> */}
      <Link to="/home">
        <div className="side_logo"></div>
      </Link>

      <div className="side_menuNew">
        <li className="menulistNew">
          <Link to="/dashboard">
            {" "}
            <RiDashboardLine className="icon"></RiDashboardLine> &nbsp;
            Dashboard{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/course">
            {" "}
            <FaWifi className="icon"></FaWifi> &nbsp;
            Online Course{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/chart">
            {" "}
            <FaChartArea className="icon"></FaChartArea> &nbsp;
            Chart{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/signals">
            {" "}
            <FaSignal className="icon"></FaSignal> &nbsp;
            Signals{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/live">
            {" "}
            <CgMediaLive className="icon"></CgMediaLive> &nbsp;
            Live Course{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/videos">
            {" "}
            <FaVideo className="icon"></FaVideo> &nbsp;
            Videos{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/tech">
            {" "}
            <FiSettings className="icon"></FiSettings> &nbsp;
            Technicals{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/tech-articles">
            {" "}
            <FiSettings className="icon"></FiSettings> &nbsp;
            Technical Articles{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/nft">
            {" "}
             &nbsp;
            NFT's{" "}
          </Link>
        </li>

        <li className="menulistNew">
          <Link to="/support">
            {" "}
            <BiSupport className="icon"></BiSupport> &nbsp;
            Support{" "}
          </Link>
        </li>
        </div>
        {socials?.map((item) => (
          <ul
            style={{
              marginTop: "10px",
              marginLeft: "-20px",
              bottom: "0",
              position: "fixed",
            }}
          >
            <li style={{ display: "inline", padding: "10px" }}>
              {" "}
              <a href={item?.telegram} target="_blank">
                <FaTelegram></FaTelegram>{" "}
              </a>
            </li>
            <li style={{ display: "inline", padding: "10px" }}>
              {" "}
              <a href={item?.twitter} target="_blank">
                <FaTwitter></FaTwitter>{" "}
              </a>
            </li>
            <li style={{ display: "inline", padding: "10px" }}>
              {" "}
              <a href={item?.instagram} target="_blank">
                <FaInstagram></FaInstagram>{" "}
              </a>
            </li>
            <li style={{ display: "inline", padding: "10px" }}>
              {" "}
              <a href={item?.youtube} target="_blank">
                <FaYoutube></FaYoutube>{" "}
              </a>
            </li>
          </ul>
        ))}

      
    </div>
  );
};

export default Sidebar;
