import React, { useEffect, useState } from "react";

import "../../../assets/css/clients/sidebar.css";
import logo1 from "../../../assets/images/logo/logo1.png";
import Slide from '@material-ui/core/Slide';
import {
  FaDownload,
  FaNewspaper,
  FaImage,
  FaSignOutAlt,
  FaWifi,
  FaUsers,
  FaStore,
  FaCogs,
  FaEnvelope,
} from "react-icons/fa";
import { CgMediaLive } from "react-icons/cg";
import { FcElectricalSensor } from "react-icons/fc";
import { BiSupport } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { RiDashboardLine, RiAdminFill } from "react-icons/ri";
import {userlogout} from "../../../server/firebase.config";
const Sidebar = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [slideSidebar1, setslideSidebar1] = useState(false);
  const [slideSidebar2, setslideSidebar2] = useState(false);
  const [slideSidebar3, setslideSidebar3] = useState(false);
  const [slideSidebar4, setslideSidebar4] = useState(false);
  const [slideSidebar5, setslideSidebar5] = useState(false);

  const [slideSidebar6, setslideSidebar6] = useState(false);

  



  const slide1 = () => {
    setslideSidebar1(true);
    
  };

  const slide2 = () => {
    setslideSidebar2(true);
  };

  return (
    <div className="sidebar">
      <div className="side_logo"></div>

      <div className="side_menu">
        <li className="menulist">
          <a href="/bl4ck444/dashboard">
            {" "}
            <RiDashboardLine className="icon"></RiDashboardLine> &nbsp;
            Dashboard{" "}
          </a>
        </li>

        <li className="menulist">
          <div
            className="sub-menu_main"
            onClick={() => {
              setslideSidebar1(!slideSidebar1);
            }}
          >
            {" "}
            <FaNewspaper className="icon"></FaNewspaper> &nbsp; Post
          </div>

          <Slide direction="left" in={slideSidebar1} mountOnEnter unmountOnExit
          {...(slideSidebar1 ? { timeout: 300 } : {})}
          style={{ display: `${slideSidebar1 ? "block" : "none"}` }}
        >
          <div
            className="sub-menu"
            
          >
            <div className="menulist">
              <a href="/bl4ck444/add-post"> Add Post</a>
            </div>
            <div className="menulist">
              <a href="/bl4ck444/view-post"> View Post</a>
            </div>

            <div className="menulist">
              <a href="/bl4ck444/weekly-breakdowns"> Weekly Breakdown</a>
            </div>

            <div className="menulist">
              <a href="/bl4ck444/videos"> Videos</a>
            </div>
          </div>
          </Slide>
        </li>

        

        <li className="menulist">
          <div
            className="sub-menu_main"
            onClick={() => {
              setslideSidebar2(!slideSidebar2);
            }}
          >
            {" "}
            <FaUsers className="icon"></FaUsers> &nbsp; Users
          </div>

          <Slide direction="left" in={slideSidebar2} mountOnEnter unmountOnExit
          {...(slideSidebar2 ? { timeout: 300 } : {})}
          style={{ display: `${slideSidebar2 ? "block" : "none"}` }}
        >
          <div
            className="sub-menu2"
            style={{ display: `${slideSidebar2 ? "block" : "none"}` }}
          >
            <div className="menulist">
              <a href="/bl4ck444/add-user"> Add User</a>
            </div>
            <div className="menulist">
              <a href="/bl4ck444/view-user"> View User</a>
            </div>
          </div>
          </Slide>
        </li>

        <li className="menulist">
          <div
            className="sub-menu_main"
            onClick={() => {
              setslideSidebar3(!slideSidebar3);
            }}
          >
            {" "}
            <FaStore className="icon"></FaStore> &nbsp; Store
          </div>

          <Slide direction="left" in={slideSidebar3} mountOnEnter unmountOnExit
          {...(slideSidebar3 ? { timeout: 300 } : {})}
          style={{ display: `${slideSidebar3 ? "block" : "none"}` }}
        >
          <div
            className="sub-menu3"
            style={{ display: `${slideSidebar3 ? "block" : "none"}` }}
          >
            <div className="menulist">
              <a href="/bl4ck444/add-products"> Add</a>
            </div>
            <div className="menulist">
              <a href="/bl4ck444/view-products"> View</a>
            </div>

            <div className="menulist">
              <a href="/bl4ck444/inventory"> Inventory</a>
            </div>
          </div>
          </Slide>
        </li>

        <li className="menulist">
          <div
            className="sub-menu_main"
            onClick={() => {
              setslideSidebar4(!slideSidebar4);
            }}
          >
            {" "}
            <FaWifi className="icon"></FaWifi> &nbsp; Online Course
          </div>

          <Slide direction="left" in={slideSidebar4} mountOnEnter unmountOnExit
          {...(slideSidebar4 ? { timeout: 300 } : {})}
          style={{ display: `${slideSidebar4 ? "block" : "none"}` }}
        >
          <div
            className="sub-menu4"
            style={{ display: `${slideSidebar4 ? "block" : "none"}` }}
          >
            <div className="menulist">
              <a href="/bl4ck444/add-course"> Add</a>
            </div>
            <div className="menulist">
              <a href="/bl4ck444/all-courses"> View</a>
            </div>
          </div>
          </Slide>
        </li>

        <li className="menulist">
          <div
            className="sub-menu_main"
            onClick={() => {
              setslideSidebar5(!slideSidebar5);
            }}
          >
            {" "}
            <CgMediaLive className="icon"></CgMediaLive> &nbsp; Live Course
          </div>

          <Slide direction="left" in={slideSidebar5} mountOnEnter unmountOnExit
          {...(slideSidebar5 ? { timeout: 300 } : {})}
          style={{ display: `${slideSidebar5 ? "block" : "none"}` }}
        >
          <div
            className="sub-menu5"
            style={{ display: `${slideSidebar5 ? "block" : "none"}` }}
          >
            <div className="menulist">
              <a href="/bl4ck444/live-course"> Add</a>
            </div>
            <div className="menulist">
              <a href="/bl4ck444/live"> View</a>
            </div>
          </div>
          </Slide>
        </li>


        <li className="menulist">
          <a href="/bl4ck444/signals">
            {" "}
            <BiSupport className="icon"></BiSupport> &nbsp; Signals{" "}
          </a>  
        </li>



        <li className="menulist">
          <a href="/bl4ck444/images">
            {" "}
            <FaImage className="icon"></FaImage> &nbsp; Images{" "}
          </a>
        </li>

        {/* <li className="menulist">
          <div
            className="sub-menu_main"
            onClick={() => {
              setslideSidebar6(!slideSidebar6);
            }}
          >
            {" "}
            <RiAdminFill className="icon"></RiAdminFill> &nbsp; Admins
          </div>

          <div
            className="sub-menu6"
            style={{ display: `${slideSidebar6 ? "block" : "none"}` }}
          >
            <div className="menulist">
              <a href="/course"> Add</a>
            </div>
            <div className="menulist">
              <a href="/course"> View</a>
            </div>
          </div>
        </li> */}

<li className="menulist">
          <a href="/bl4ck444/newsletter">
            {" "}
            <FaEnvelope className="icon"></FaEnvelope> &nbsp; Newsletter{" "}
          </a>
        </li>

        <li className="menulist">
          <a href="/bl4ck444/support">
            {" "}
            <BiSupport className="icon"></BiSupport> &nbsp; Support{" "}
          </a>
        </li>

        <li className="menulist">
          <a href="/bl4ck444/settings">
            {" "}
            <FaCogs className="icon"></FaCogs> &nbsp; Settings{" "}
          </a>
        </li>

        

        
      </div>

      {/* <div className="sidebar_footer">
        <a onClick={() => {
                            userlogout();
                            window.location.href = "/auth";
                            // <Redirect to="/auth" />

                            
                          }}>
          <FaSignOutAlt></FaSignOutAlt> &nbsp; Log Out
        </a>
      </div> */}
    </div>
  );
};

export default Sidebar;
