import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import blog1 from "../../assets/images/news/s1.png";
import blog2 from "../../assets/images/news/s2.png";
import queryString from "query-string";
  import { getOneClass } from "../../server/firebase.config";
const CoursePage = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [Class, setClass] = useState(null);


  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];
  const mainId = Object.values(id)[1];

  console.log(realId);
  console.log(mainId);
  const effect = useEffect(() => {
    

      const subscription = getOneClass(mainId, realId).subscribe((item) => {
          // console.log(item)  
        setClass(item);
      });
  
      return () => {
        subscription.unsubscribe();
        // subs.unsubscribe()
      };
  }, []);

  return (
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>

          <div className="clearfix"></div>

          <div fluid className="mb course">

          <Container fluid>
          <Row>
          {/* {Class?.map((item) => ( */}
            <Col xs="12" md="12" className="">
              <div className="single_head">
                <div className="single_head_title">
                {Class?.title}
                </div>
              </div>

              {/* <div className="single_img">
              <img src={item?.mainImage?.imageUrl} />
              </div> */}

              <div className="single_body">

              <div
                            dangerouslySetInnerHTML={{
                              __html: `${Class?.editorData}`,
                            }}
                          ></div>
              </div>

             
            </Col>
            {/* ))
          } */}
            </Row>
            </Container>
           
          </div>
        </Col>
      </Row>

      </Container>
    </div>
  );
};

export default CoursePage;
