import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { config } from "rxjs";
import {
  editSettings,
  getSettings,
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import queryString from "query-string";
import Toast from "react-bootstrap/Toast";

const Socials = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  


  const [socials, setSocials] = useState(null);

  

  const effect = useEffect(() => {
    firstValueFrom(getSettings())
      .then((item) => {
        //   console.log(item)
        setSocials(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    

    // console.log(valueFields);

    // return

    editSettings( valueFields);
    alert("Content Updated");
  };

 

  return (
    <div>
      <Container fluid>

      

      <div className="clearfix"></div>
      {/* <div className="mb"> */}
      <Container fluid>
        <Row>
          <Col sm="12" md="12" className="livechart">
              {socials?.map((item, index) => 
            <Form
              className="add-form"
              id="form"
              onSubmit={(event) => {
                formData(event);
              }}
              key={index}
            >

<Row>
          <Col sm="12" md="6">         
              <div className="form-group">
                <label for="telegram">Telegram</label>
                <input
                  type="text"
                  id="telegram"
                  className="form-control"
                  defaultValue={item?.telegram}
                  name="telegram"
                />
              </div>
              </Col> 

              <Col sm="12" md="6">         
              <div className="form-group">
                <label for="twitter">Twitter</label>
                <input
                  type="text"
                  id="twitter"
                  className="form-control"
                  defaultValue={item?.twitter}
                  name="twitter"
                />
              </div>
              </Col> 

              <Col sm="12" md="6">         
              <div className="form-group">
                <label for="instagram">Instagram</label>
                <input
                  type="text"
                  id="instagram"
                  className="form-control"
                  defaultValue={item?.instagram}
                  name="instagram"
                />
              </div>
              </Col> 

              <Col sm="12" md="6">         
              <div className="form-group">
                <label for="youtube">Youtube</label>
                <input
                  type="text"
                  id="youtube"
                  className="form-control"
                  defaultValue={item?.youtube}
                  name="youtube"
                />
              </div>
              </Col> 

              </Row>

              

              <div className="form-group">
                <Button type="submit" variant="outline-dark">
                  Publish
                </Button>
              </div>
            </Form>
             )} 
          </Col>
        </Row>
      </Container>
      {/* </div> */}


      </Container>
    </div>
  );
};

export default Socials;
