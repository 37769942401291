import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import { getPostFromFirebaseByCategory } from "../../server/firebase.config";

const Feeds = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [news, setNews] = useState(null);

  const effect = useEffect(() => {
    const subscription = getPostFromFirebaseByCategory("market").subscribe(
      (item) => {
        setNews(item);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>

            <Container fluid className="mb feeds">
              <div className="news_head">
                <div className="news_head_title"> News Feeds</div>

                <div className="news_head_subtitle">
                  Your insights to keep you afloat
                </div>
              </div>

              <Row>
                <Col xs="12" md="12">
                  <Row>
                    {news?.length === 0 ? (
                      <></>
                    ) : (
                      news?.map((item) => (
                        <Col xs="12" md="12" className="news_post_main">
                          <Row>
                            <Col xs="12" md="2" className="news_post_main_img">
                              <img src={item?.mainImage?.imageUrl} />
                            </Col>

                            <Col xs="12" md="6" className="news_post_main2">
                              <div className="news_post_main_title">
                                {" "}
                                {item?.title}
                              </div>

                              <div className="news_post_main2_bottom">
                                <div className="news_post_main_by">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: `${item?.editorData.substring(
                                        0,
                                        "100"
                                      )}...`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Col>

                            <Col xs="12" md="4" className="news_post_main_btn">
                              <a href={`/view-post?id=${item.id}`}>
                                <div className="news_post_main_btn-btn">
                                  Read More
                                </div>
                              </a>
                            </Col>
                          </Row>
                        </Col>
                      ))
                    )}
                  </Row>
                </Col>
              </Row>

              <div className="more-news">
                <div className="more-news_btn">Load More</div>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Feeds;
