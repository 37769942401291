import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../../assets/css/clients/sidebar.css";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineDown } from "react-icons/ai";
import {
  fireAuthState,
  getOneUsers,
  userlogout,
} from "../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import Switch from "react-switch";

const Header = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [User, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [checked, setChecked] = useState(false);

  const [socials, setSocials] = useState(null);

  const effect = useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
  if (currentTheme !== null) {
    document.documentElement.setAttribute("data-theme", currentTheme);
    const savedTheme = currentTheme === "dark" ? true : false;
    setChecked(savedTheme);
  }

    firstValueFrom(fireAuthState)
      .then((user) => {
        if (user) {
          // console.log(user);
          setUser(user);

        } else {
          setUser(false);
        }
      })

      .catch((error) => {
        window.location.href = "/auth";
        console.log(error);
      });

    const id = User?.uid;
    // console.log(id);
    const subscribe = getOneUsers(id).subscribe((item) => {
      setUsers(item)
      // console.log(item)
      // if (item?.admin == 'yes'){
      //   <></>
      // }
      // else{
      //   window.location.href = "/dashboard";
      // }
      ;
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  

  const handleChange = () => {
    setChecked(!checked);
    if (checked === false) {
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark"); //add this
    }
    if (checked === true) {
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light"); //add this
    }
  };

  return (
    <div className="header">
      <div className="header_center">
        <div className="clearfix"></div>
      </div>

      <div className="header_right">
        <div className="notify_tab">
          {/* <BsBell className="noti"></BsBell> */}
          <label>
            <Switch onColor="#cf121f" onChange={handleChange} checked={checked} />
          </label>
        </div>

        <div className="profile_tab">
          <div className="display_name">
            <BiUserCircle></BiUserCircle> <span>{users?.username}</span> &nbsp;{" "}
            <AiOutlineDown></AiOutlineDown>
          </div>

          <div className="profile_tab_dropdown">
            <a href="/profile">
              <div className="profile_tab_menulist">Profile</div>
            </a>
            <a
              onClick={() => {
                userlogout();
                window.location.href = "/auth";
              }}
            >
              <div className="profile_tab_menulist">Log out</div>
            </a>
          </div>
        </div>

        {/* <div className="drop">
                <img src={drop} />
            </div> */}
      </div>
    </div>
  );
};

export default Header;
