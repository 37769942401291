import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/clients/style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {addLivecourse} from "../../../../server/firebase.config";


const LiveCourse = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->


  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    

    // console.log(valueFields);

    // return

    addLivecourse(valueFields)
    event.target.reset()

    // setshowToast(true)
    // document.querySelector(".add-form").value = null;
    
  };

  return (
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>

          <div className="clearfix"></div>
          <div className="mb">
           

            <Container fluid className="admin_dashboard_stat_card2">
              <Row>
                <Col sm="12" md="12">
                  <Card className="stat_card2">
                      <h3 style={{textAlign:'center', marginTop:'20px'}}> Go Live</h3>
                  <Form className="add-form" onSubmit={(event) => {
                      formData(event);
                    }}>
                      <div className="form-group">
                          <input className="form-control" type="text" placeholder="www.example.com" name="url"></input>
                      </div>
                      <div className="form-group">
                                          <label for="chat">Chat</label>
                                          <select id="chat" className="form-control" name="chat">
                                              
                                              <option value="enable">Enable</option>
                                              <option value="disable">Disable</option>
                                          </select>
                                      </div>

                                      <div className="form-group">
                                          <Button type="submit" variant="outline-dark">Go Live</Button>
                                      </div>

                        </Form>
                  </Card>
                </Col>
                
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      </Container>
    </div>
  );
};

export default LiveCourse;
