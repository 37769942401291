import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { config } from "rxjs";
import {
    addVideos,
  getVideos,
  deleteVideos,
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import { MDBNotification } from "mdbreact";
// import Toast from "react-bootstrap/Toast";
// import ToastContainer from 'react-bootstrap/ToastContainer';
import UploadAdapter, {
  uploadResult$,
} from "../../../../utils/custom-ck-adapter";
import { Prompt } from "react-router";

const AddVideos = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [showToast, setshowToast] = useState(false);

  const imageDetails = useRef(null);

  const editorData = useRef("");
  const [technicals, setTechnicals] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const effect = useEffect(() => {
    const subscription = getVideos().subscribe((item) => {
      setTechnicals(item);
    });

    const sub = uploadResult$.subscribe((item) => {
      // console.log(item);
      imageDetails.current = item;
    });

    return () => {
      subscription.unsubscribe();
      sub.unsubscribe();
    };
  }, []);

  const formData = (event) => {
    event.preventDefault();
    // const publish = event.nativeEvent.submitter.publish;
    // const confirmation = publish === 'publish'? window.confirm('Are you sure want to post this?') : window.confirm('Are you sure want to draft this?');
    // if(!confirmation){
    //     return
    //   }

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    // console.log(file.current);

    // valueFields = { ...valueFields, video: editorData.current };
    valueFields = { ...valueFields, timestamp: Date.now() };

    // console.log(valueFields);

    // return

    addVideos(valueFields);
    document.getElementById("form").reset();
    // document.getElementById("editor").setData("");
    alert("Post Added");
    setshowToast(true);
  };

  return (
    <>
      <Prompt
        when={true}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <div>
        <Container fluid>
          <Row>
            <Col md="2" className="sidebar-col">
              <Sidebar></Sidebar>
            </Col>
            <Col sm="12" md="10" className="mainbody">
              <Header></Header>

              {/* <ToastContainer position="top-end" className="p-3"> */}

              {/* <Toast onClose={() => setshowToast(false)} show={showToast} delay={3000} className="toast" autohide>
      
      <Toast.Body className="success">Post Added</Toast.Body>
    </Toast> */}

              <MDBNotification
                onClose={() => setshowToast(false)}
                show={showToast}
                fade
                iconClassName="text-primary"
                message="Post Added"
                style={{
                  position: "fixed",
                  top: "10px",
                  background: "green",
                  color: "white",
                  right: "10px",
                  zIndex: 9999,
                }}
              />
              {/* </ToastContainer> */}

              <div className="clearfix"></div>
              <div className="mb">
                <Container fluid>
                  <div className="admin_hero_head">
                    <Button onClick={handleShow} variant="outline-dark">
                      Add
                    </Button>
                  </div>

                  <Row>
                    {technicals?.length === 0 ? (
                      <></>
                    ) : (
                      technicals?.map((item) => (
                        <Col sm="12" md="3">
                          <Card className="crypto_card">
                            {/* {item?.video}

                            <div dangerouslySetInnerHTML={{ __html: ` ${item?.video} `,}}
                            ></div> */}
                            <iframe
                            src={item?.video} 
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                            <Card.Body className="crypto_cardBody">
                              <a href="product-page">
                                {" "}
                                <p className="prodTitle">{item.title}</p>{" "}
                              </a>

                              <div>
                                {" "}
                                <span className="prodby">
                                  {" "}
                                  {/* <a href={`/admin/edit-post?id=${item.id}`}>
                                    {" "}
                                    <FaEdit className="icons"></FaEdit>{" "}
                                  </a>{" "} */}
                                </span>{" "}
                                &nbsp;
                                <span
                                  className="proddate"
                                  onClick={() => {
                                    deleteVideos(item.id);
                                  }}
                                >
                                  <FaTrash className="FaCalendar"></FaTrash>{" "}
                                </span>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    )}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Videos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <form id="form" className="" onSubmit={formData}>
                  <div className="form-group">
                    <label for="title">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                    />
                  </div>
                  <div className="form-group">
                    <label for="body">Link</label>
                    {/* <CKEditor
                      id="editor"
                      onReady={(editor) => {
                        editor.plugins.get(
                          "FileRepository"
                        ).createUploadAdapter = (loader) => {
                          return new UploadAdapter(loader);
                        };
                      }}
                      editor={ClassicEditor}
                      config={(config.height = "500")}
                      // console
                      onChange={(event, editor) => {
                        // console.log(editor.getData());
                        editorData.current = editor.getData();
                        return false;
                        // console.log( { event, editor, data } );
                      }}
                      name="body"
                    /> */}

                    <input type="url" className="form-control" id="body" name="video"/>
                  </div>

                  <div className="form-group">
                    <Button type="submit" variant="outline-danger">
                      Publish
                    </Button>
                  </div>
                </form>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default AddVideos;
