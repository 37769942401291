import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../assets/css/front/style.css";
import logo1 from "../../assets/images/logo/logo1.png"
import Header from "../front/components/header";
import Footer from "../front/components/footer";
import { Button } from "react-bootstrap";
import {resetPassword} from "../../server/firebase.config";
const ForgotAuth = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

 



const recover = (event) => {
  event.preventDefault();

  const values = Array.from(event.target.elements)
    .map((item) => ({
      name: item.name,
      value: item.value,
    }))
    .filter((val) => val.name !== "");

  let valueFields = {};

  for (const items in values) {
    const { name, value } = values[items];
    valueFields[name] = value;
  }
  

  // console.log(valueFields);

  // return
  resetPassword(valueFields.email)
  .then(() => {
    document.getElementById("form").reset();
    alert('Link has been sent to your email')

  })
  
  

    .catch((error) => {
      console.log(error);
      if(error.code === 'auth/user-not-found'){
        alert('User Not found')
      }
    });
    


    
};

  return (
    <div className="authbody">
      {/* <Header></Header> */}

      <div className="clearfix"></div>

      <div className="auth" style={{marginTop:"90px"}}>
        <div className="auth_header">
          <img src={logo1} />
        </div>


        <form onSubmit={recover} id="form">

              <div className="form-group">
                <label for="email">Email</label>
                <input className="form-control" type="text" id="email" name="email"></input>
              </div>

              

              <Button type="submit" className="auth_form_submit">
                Recover
              </Button>
        </form>

        <div style={{textAlign:"center"}}>
          Not a member? <a href="/signup">Sign Up</a>
        </div>
      </div>

      {/* <Footer></Footer> */}
    </div>
  );
};

export default ForgotAuth;
