import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { config } from "rxjs";
import {
  getSignalMembership,
  getLiveMembership,
  getOnlineMembership,
  editLiveMembership,
  editOnlineMembership,
  editSignalMembership,
  createProduct
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import queryString from "query-string";
import Toast from "react-bootstrap/Toast";

const Plans = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [signals, setSignals] = useState(null);
  const [live, setLive] = useState(null);
  const [online, setOnline] = useState(null);

  const liveData = useRef("");
  const onlineData = useRef("");
  const signalData = useRef("");
  const pricev = useRef("");
  const planName = useRef("");
  pricev.current = signals?.price;
  planName.current = signals?.plan;

   // Vat
   var vat = 15;
   var totalAfter = (vat / 100) * pricev.current;
 
   var totalAfterVat = totalAfter + parseInt(pricev.current);
   // console.log(totalAfterVat);
 
   var percentToGet = 50;
   //Calculate the percent.
   var percent = (percentToGet / 100) * totalAfterVat;

  //  alert(percent)

  const effect = useEffect(() => {
    firstValueFrom(getSignalMembership())
      .then((item) => {
        //   console.log(item)
        setSignals(item);
      })
      .catch((error) => {
        console.log(error);
      });

    firstValueFrom(getLiveMembership())
      .then((item) => {
        //   console.log(item)
        setLive(item);
      })
      .catch((error) => {
        console.log(error);
      });

    firstValueFrom(getOnlineMembership())
      .then((item) => {
        //   console.log(item)
        setOnline(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const signalsForm = async (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    if (signalData.current) {
      // them upload the file ,
      // then get the download url ;
      //   console.log(file.current);
      valueFields = {
        ...valueFields,
        body: signalData.current,
      };
    }
    // console.log(valueFields);

    // return
   

    await editSignalMembership(valueFields);


    await createProduct({
      productId : "ZGIIwQ0ap6qOboZyRDGR",
      name: "signals",
      description: signals.body,
      productPrice: signals.price,
      // productPrice: Number(percent)
    })

    
    alert("Content Updated");
  };

  const liveForm = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    if (liveData.current) {
      // them upload the file ,
      // then get the download url ;
      //   console.log(file.current);
      valueFields = {
        ...valueFields,
        body: liveData.current,
      };
    }

    // console.log(valueFields);

    // return

    editLiveMembership(valueFields);
    alert("Content Updated");
  };

  const onlineForm = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    if (onlineData.current) {
      // them upload the file ,
      // then get the download url ;
      //   console.log(file.current);
      valueFields = {
        ...valueFields,
        body: onlineData.current,
      };
    }

    // console.log(valueFields);

    // return

    editOnlineMembership(valueFields);
    alert("Content Updated");
  };

  return (
    <div>
      <Container fluid>
        <div className="clearfix"></div>
        {/* <div className="mb"> */}
        <Container fluid>
          <Row>
            <Col sm="12" md="12" className="livechart">
              <Form
                className="add-form"
                id="form"
                onSubmit={(event) => {
                  signalsForm(event);
                }}
              >
                <h2 className="text-center"> Signals</h2>
                {/* {signals?.map((item) => ( */}
                  <Row>
                    <Col sm="12" md="6">
                      <div className="form-group">
                        <label for="live">Price</label>
                        <input
                          type="text"
                          id="live"
                          className="form-control"
                          defaultValue={signals?.price}
                          name="price"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6">
                      <div className="form-group">
                        <label for="duration">Duration</label>

                        <select
                          id="duration"
                          className="form-control"
                          name="duration"
                        >
                          <option defaultValue={signals?.duration}>{signals?.duration}</option>
                          <option value="Month">Month</option>
                          <option value="Year">Year</option>
                          <option value="Lifetime">Lifetime</option>
                        </select>
                      </div>
                    </Col>

                    <Col sm="12" md="12">
                      <div className="form-group">
                        <label for="body">Body</label>
                        <CKEditor
                          data={signals?.body}
                          editor={ClassicEditor}
                          config={(config.height = "500")}
                          onChange={(event, editor) => {
                            signalData.current = editor.getData();
                            // console.log( { event, editor, data } );
                          }}
                          name="body"
                        />
                      </div>
                    </Col>
                  </Row>
                {/* // ))} */}

                <div className="form-group">
                  <Button type="submit" variant="outline-dark">
                    Publish
                  </Button>
                </div>
              </Form>

              <Form
                className="add-form"
                id="form"
                onSubmit={(event) => {
                  liveForm(event);
                }}
              >
                <h2 className="text-center"> Live</h2>
                {/* {live?.map((item) => ( */}
                  <Row>
                    <Col sm="12" md="6">
                      <div className="form-group">
                        <label for="live">Price</label>
                        <input
                          type="text"
                          id="live"
                          className="form-control"
                          defaultValue={live?.price}
                          name="price"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6">
                      <div className="form-group">
                        <label for="duration">Duration</label>

                        <select
                          id="duration"
                          className="form-control"
                          name="duration"
                        >
                          <option defaultValue={live?.duration}>{live?.duration}</option>
                          <option value="Month">Month</option>
                          <option value="Year">Year</option>
                          <option value="Lifetime">Lifetime</option>
                        </select>
                      </div>
                    </Col>

                    <Col sm="12" md="12">
                      <div className="form-group">
                        <label for="body">Body</label>
                        <CKEditor
                          data={live?.body}
                          editor={ClassicEditor}
                          config={(config.height = "500")}
                          onChange={(event, editor) => {
                            liveData.current = editor.getData();
                            // console.log( { event, editor, data } );
                          }}
                          name="body"
                        />
                      </div>
                    </Col>
                  </Row>
                {/* ))} */}

                <div className="form-group">
                  <Button type="submit" variant="outline-dark">
                    Publish
                  </Button>
                </div>
              </Form>

              <Form
                className="add-form"
                id="form"
                onSubmit={(event) => {
                  onlineForm(event);
                }}
              >
                <h2 className="text-center"> Online</h2>
                {/* {online?.map((item) => ( */}
                  <Row>
                    <Col sm="12" md="6">
                      <div className="form-group">
                        <label for="live">Price</label>
                        <input
                          type="text"
                          id="live"
                          className="form-control"
                          defaultValue={online?.price}
                          name="price"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6">
                      <div className="form-group">
                        <label for="duration">Duration</label>

                        <select
                          id="duration"
                          className="form-control"
                          name="duration"
                        >
                          <option defaultValue={online?.duration}>{online?.duration}</option>
                          <option value="Month">Month</option>
                          <option value="Year">Year</option>
                          <option value="Lifetime">Lifetime</option>
                        </select>
                      </div>
                    </Col>

                    <Col sm="12" md="12">
                      <div className="form-group">
                        <label for="body">Body</label>
                        <CKEditor
                          data={online?.body}
                          editor={ClassicEditor}
                          config={(config.height = "500")}
                          onChange={(event, editor) => {
                            onlineData.current = editor.getData();
                            // console.log( { event, editor, data } );
                          }}
                          name="body"
                        />
                      </div>
                    </Col>
                  </Row>
                {/* ))} */}

                <div className="form-group">
                  <Button type="submit" variant="outline-dark">
                    Publish
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
        {/* </div> */}
      </Container>
    </div>
  );
};

export default Plans;
