import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { firstValueFrom } from "rxjs";
import queryString from "query-string";
import { createSignal, editSignal, getSignal, getOneSignal, deleteSignal  } from "../../../../server/firebase.config";
import { FiEdit } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const DashSignals = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [signals, setSignals] = useState(null);
  const [onesignals, setOneSignals] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const idRef = useRef("");

  const [editshow, setEditShow] = useState(false);
  const handleEditShow = () => setEditShow(true);
  const handleEditClose = () => setEditShow(false);
  

//   let url = window.location.href;
//   let id = queryString.parse(url);
//   const realId = Object.values(id)[0];
  
  const location = useLocation();

  const effect = useEffect(() => {
    const query = new URLSearchParams(location.search);
    firstValueFrom(getSignal())
      .then((item) => {
        // console.log(item);
        setSignals(item);
      })
      .catch((error) => {
        console.log(error);
      });

      idRef.current = query.get("id");

      firstValueFrom(getOneSignal(query.get("id")))
      .then((item) => {
        // console.log(item);
        setOneSignals(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [location]);

//  console.log(idRef.current)


  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    // console.log(valueFields);

    // return

    createSignal(valueFields);
    alert("Signal Created");
    document.getElementById("form").reset();
  };





  const editformData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    // console.log(valueFields);

    // return

    editSignal(idRef.current, valueFields);
    alert("Signal Edited");
    // document.getElementById("form").reset();
  };

  

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <div className="mb">
              <Container fluid>
                  <div style={{float: 'right'}}>
                  <Button variant="outline-success" onClick={handleShow}>Add Signals</Button>
                  </div>
                <Row>

                {signals?.map((item) => (
                  <Col sm="12" md="3" className="signal_box">
                     <div className="signal_box_edit"> <Link to ={`/bl4ck444/signals?id=${item.id}`}><FiEdit onClick={handleEditShow}></FiEdit> </Link></div>
                    <div className="signal_box_img">
                      <img src="" />
                    </div>

                    <div className="signal_box_title">{item?.title}</div>

                    <div className="signal_box_type">
                      <div className="signal_box_type_title">Type</div>

                      <div className="signal_box_type_body" style={{ color :` ${item?.type === "buy"? 'green': 'red'}`} }>{item?.type}</div>
                    </div>
                    <div className="signal_box_type">
                    <div className="signal_box_type_title">Price</div>

                    <div className="signal_box_type_body">{item?.price}</div>
                    </div>

                    <div className="signal_box_type">
                    <div className="signal_box_type_title">Stop Loss</div>

                    <div className="signal_box_type_body" style={{color: 'red'}}>{item?.stopLoss}</div>
                    </div>

                    <div className="signal_box_type">
                    <div className="signal_box_type_title">Take Profit</div>

                    <div className="signal_box_type_body">{item?.takeProfit}</div>
                    </div>

                    <div className="signal_box_type">
                    <div className="signal_box_type_title">Status</div>

                    <div className="signal_box_type_body" style={{ color :` ${item?.status === "active"? 'green': 'red'}`} }>{item?.status}</div>
                    </div>


                    <FaTrash onClick={() => {
                              deleteSignal(item.id);
                            }}></FaTrash>
                  </Col>
                  ))}
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Signals</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="form" onSubmit={(event) => {
                  formData(event);
                }}>
              <div className="form-group">
                  <label for="title">Title</label>
                  <input type="text" className="form-control" id="title" name="title" />
              </div>
              <div className="form-group">
                  <label for="type">Type</label>
                  <select className="form-control" id="type" name="type">
                      <option value="buy">
                          Buy
                      </option>

                      <option value="sell">
                          Sell
                      </option>

                      <option value="buyLimit">
                          Buy Limit
                      </option>

                      <option value="sellLimit">
                          Sell Limit
                      </option>

                      <option value="buyStop">
                          Buy Stop
                      </option>

                      <option value="sellStop">
                          Sell Stop
                      </option>
                  </select>
              </div>

              <div className="form-group">
                  <label for="price">Price</label>
                  <input type="text" className="form-control" id="price" name="price" />
              </div>

              <div className="form-group">
                  <label for="loss">Stop Loss</label>
                  <input type="text" className="form-control" id="loss" name="stopLoss" />
              </div>

              <div className="form-group">
                  <label for="profit">Take Profit</label>
                  <input type="text" className="form-control" id="profit" name="takeProfit" />
              </div>

              <div className="form-group">
                  <label for="remarks">Remarks</label>
                  <input type="text" className="form-control" id="remarks" name="remarks" />
              </div>

              <div className="form-group">
                  <label for="status">Status</label>
                  <select className="form-control" id="status" name="status">
                      <option value="active">
                          Active
                      </option>

                      <option value="close">
                          Close
                      </option>
                  </select>
              </div>

              <div className="form-group">
              <Button type="submit" variant="outline-success" >Add Signals</Button>
              </div>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>









      <Modal show={editshow} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Signals</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form  onSubmit={(event) => {
                  editformData(event);
                }}>
              <div className="form-group">
                  <label for="title">Title</label>
                  <input type="text" className="form-control" id="title" defaultValue={onesignals?.title} name="title" />
              </div>
              <div className="form-group">
                  <label for="type">Type</label>
                  <select className="form-control" id="type" name="type">
                      <option defaultValue={onesignals?.type}>{onesignals?.type}</option>
                      <option value="buy">
                          Buy
                      </option>

                      <option value="sell">
                          Sell
                      </option>

                      <option value="buyLimit">
                          Buy Limit
                      </option>

                      <option value="sellLimit">
                          Sell Limit
                      </option>

                      <option value="buyStop">
                          Buy Stop
                      </option>

                      <option value="sellStop">
                          Sell Stop
                      </option>
                  </select>
              </div>

              <div className="form-group">
                  <label for="price">Price</label>
                  <input type="text" className="form-control" defaultValue={onesignals?.price} id="price" name="price" />
              </div>

              <div className="form-group">
                  <label for="loss">Stop Loss</label>
                  <input type="text" className="form-control" defaultValue={onesignals?.stopLoss} id="loss" name="stopLoss" />
              </div>

              <div className="form-group">
                  <label for="profit">Take Profit</label>
                  <input type="text" className="form-control" defaultValue={onesignals?.takeProfit} id="profit" name="takeProfit" />
              </div>

              <div className="form-group">
                  <label for="remarks">Remarks</label>
                  <input type="text" defaultValue={onesignals?.remarks} className="form-control" id="remarks" name="remarks" />
              </div>

              <div className="form-group">
                  <label for="status">Status</label>
                  <select className="form-control" id="status" name="status">
                      <option defaultValue={onesignals?.status}>
                      {onesignals?.status}
                      </option>
                      <option value="active">
                          Active
                      </option>

                      <option value="close">
                          Close
                      </option>
                  </select>
              </div>

              <div className="form-group">
              <Button type="submit" variant="outline-success" >Edit Signals</Button>
              </div>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default DashSignals;
