import React, { useEffect, useState } from "react";
import "../../assets/css/front/style.css";
import "../../assets/css/front/store.css";
import Header from "./components/header";
import Footer from "./components/footer";
import  Container from "react-bootstrap/Container";
import  Row from "react-bootstrap/Row";
import  Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';

const Faqs = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  return (
    <>
      <Header></Header>

      <div className="clearfix"></div>

      <div className="crypto">
        <div className="crypto_head">
          <div className="crypto_head_title">&nbsp; Frequently Asked Question</div>
        </div>

        <Container>
          <Row>
            
          </Row>
        </Container>
      </div>


       <Footer></Footer>
     </>
  );
};

export default Faqs;
