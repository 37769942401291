import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaEdit, FaTrash, FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { config } from "rxjs";
import {
    editCoupon,
  addCoupon,
  getCoupons,
  deleteCoupon
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import Table from "react-bootstrap/Table";
import queryString from "query-string";
import Toast from "react-bootstrap/Toast";

const Coupon = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  


  const [coupon, setCoupon] = useState(null);

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handleAddClose = () => setShowAdd(false);
  const handleAddShow = () => setShowAdd(true);

  const handleEditClose = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);

  

  const effect = useEffect(() => {
    firstValueFrom(getCoupons())
      .then((item) => {
          // console.log(item)
        setCoupon(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);








  const addForm = async (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    
    valueFields = { ...valueFields, regDate: Date.now() };
    // console.log(valueFields);

    // return
try {

   await addCoupon( valueFields);
   document.getElementById("form").reset();
   await alert("Coupon Added");
    
} catch (error) {
    console.log(error)
    alert("Error adding coupon");
}
    
  };













  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    

    // console.log(valueFields);

    // return

    editCoupon( valueFields);
    alert("Content Updated");
  };

 

  return (
    <div>
      <Container fluid>

      

      <div className="clearfix"></div>
      {/* <div className="mb"> */}
      <Container fluid>
        <Row>
          <Col sm="12" md="12" className="livechart">
<div style={{marginBottom: '50px'}}>
          <Button  variant="primary" style={{float: 'right'}} onClick={handleAddShow}>
        Add Coupon
      </Button>
      </div>
          <Table striped bordered hover >
  <thead>
    <tr>
      <th>Coupon</th>
      <th>Created</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
  {coupon?.map((item) => 
    <tr>
      <td>{item?.coupon}</td>
      <td>{item?.regDate}</td>
      <td>{item?.status}</td>
      <td>
         <FaTrash onClick={()=> {deleteCoupon(item.id)} }></FaTrash>
         {/* <FaEdit onClick={handleEditShow} ></FaEdit> */}
        </td>
    </tr>
  )}
  </tbody>
</Table>
          </Col>
        </Row>
      </Container>
      {/* </div> */}


      <Modal show={showAdd} onHide={handleAddClose}>
        <Modal.Header closeButton>
          <Modal.Title> Add Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form id="form" onSubmit={addForm}>
                <div className="form-group">
                    <label for="coupon">Coupon Code</label>
                    <input className="form-control" type="text" id="coupon" name="coupon" />
                </div>
                <div className="form-group">
                    <label for="coupon">Percentage</label>
                    <input className="form-control" type="text" id="coupon" name="percentage" />
                </div>
                <div className="form-group">
                    <label for="coupon">Status</label>
                    <select className="form-control" id="coupon" name="status">
                        <option value="enable">Enabled</option>
                        <option value="disable">Disabled</option>

                    </select>
                </div>
                <div className="form-group">
                    <label for="expDate">Expiry Date</label>
                    <input className="form-control" type="date" id="expDate" name="expDate" />
                </div>

                <div className="form-group">
                    <Button type="submit" variant="dark">Add</Button>
                </div>
            </Form>
        </Modal.Body>
       
      </Modal>


      <Modal show={showEdit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
       
      </Modal>


      </Container>
    </div>
  );
};

export default Coupon;
