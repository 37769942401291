import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import blog1 from "../../assets/images/news/s1.png";
import blog2 from "../../assets/images/news/s2.png";
import {
    FaFacebook,
    FaInstagram,
    FaNewspaper,
    FaTwitter,
  } from "react-icons/fa";
  import {
    getOnePostFromFirebase
  } from "../../server/firebase.config";
  import queryString from "query-string";
  import { FacebookShareButton, TwitterShareButton } from "react-share";

const View = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [post, setPost] = useState(null);

  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];

  const effect = useEffect(() => {
    const subscription = getOnePostFromFirebase(realId).subscribe(
      (item) => {
        setPost(item);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);


  return (
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>

          <div className="clearfix"></div>

          <div fluid className="mb course">

          <Container fluid>
          <Row>
            <Col xs="12" md="12" className="">
              <div className="single_head">
                <div className="single_head_title">
                  {post?.title}
                </div>
              </div>

              <div className="single_img">
                <img src={post?.mainImage?.imageUrl} />
              </div>

              <div className="single_body">
              <div dangerouslySetInnerHTML={{ __html: `${post?.editorData}` }}></div>
              
              </div>


              <div className="share">
                <span className="share_title">SHARE</span>

                {/* <div className="share_btns"> */}
                <span className="share_btn1">
                  {" "}
                  <FacebookShareButton
                    url={`http://obsidianforex.com/single?id=${realId}`}
                    quote={"share on facebook"}
                    hashtag={"#hashtag"}
                    description={"aiueo"}
                    className="Demo__some-network__share-button"
                  >
                    <FaFacebook></FaFacebook>
                  </FacebookShareButton>
                </span>
                <span className="share_btn">
                  {" "}
                  <TwitterShareButton
                    title={"test"}
                    url={`http://obsidianforex.com/single?id=${realId}`}
                    hashtags={["hashtag1", "hashtag2"]}
                  >
                    <FaTwitter></FaTwitter>
                  </TwitterShareButton>
                </span>
                {/* </div> */}
              </div>
            </Col>
            </Row>
            </Container>
           
          </div>
        </Col>
      </Row>

      </Container>
    </div>
  );
};

export default View;
