import React, { useEffect, useState } from "react";
import "../../assets/css/front/style.css";
import "../../assets/css/front/news.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {
  FaFacebook,
  FaInstagram,
  FaNewspaper,
  FaTwitter,
} from "react-icons/fa";
import { GiCampfire } from "react-icons/gi";
import {
  getOnePostFromFirebase,
  getPostFromFirebase,
  getFourPostFromFirebase,
} from "../../server/firebase.config";
import queryString from "query-string";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstagramShareButton,
} from "react-share";
import { ReactSEOMetaTags } from "react-seo-meta-tags";
import { Helmet } from "react-helmet";
import MetaTags from "react-meta-tags";
const Single = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [post, setPost] = useState(null);
  const [news, setNews] = useState(null);

  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];

  const effect = useEffect(() => {
    const subscription = getOnePostFromFirebase(realId).subscribe((item) => {
      setPost(item);
    });

    const subs = getFourPostFromFirebase().subscribe((item) => {
      setNews(item);
    });

    return () => {
      subscription.unsubscribe();
      subs.unsubscribe();
    };
  }, []);

  // const meta = {
  //   title: `${post?.title}`,
  //   description: '',
  //   canonical: `https://obsidianfx.com/single?id=${realId}`,
  //   meta: {
  //       charset: 'utf-8',
  //       name: {
  //           keywords: 'react,meta,document,html,tags'
  //       }
  //   }
  // }

  const siteMetadata = {
    url: `https://obsidianfx.com/single?id=${realId}`,
    title: `${post?.title}`,
    // datePublished: '2019-10-06T13:56:03.123Z',
    // description: 'This is a 200 character long description of this web page which is quite interesting and which describes its contents well with a lot of relevant keywords and isn\'t just general marketing mumbo-jumbo.',
    language: "en-US",
    image: `${post?.mainImage?.imageUrl}`,
    site: {
      siteName: "Obsidian Fx",
      searchUrl: "https://obsidianfx.com",
    },
  };

  return (
    <>
      <Helmet>
        {/* <meta property="og:title" content={post?.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://obsidianforex.com/single?id=${realId}`}
        />
        <meta property="og:image:url" content={post?.mainImage?.imageUrl} />
        <meta property="og:description" content={`${post?.editorData.substring(0, "100")}`} /> */}
       

        <title>{post?.title}</title>
           <meta name="description" content={`${post?.editorData.substring(0, "100")}`} />
           <meta property="og:title" content={post?.title} />
           <meta property="og:image" content={post?.mainImage?.imageUrl} />
      </Helmet>

      <Header></Header>
      {/* <ReactSEOMetaTags
      website={{ ...siteMetadata }}></ReactSEOMetaTags> */}

      <div className="clearfix"></div>

      <div className="single">
        <Container fluid>
          <Row>
            <Col sm="12" md="8" className="">
              <div className="single_head">
                <div className="single_head_title">{post?.title}</div>
              </div>

              <div className="single_img">
                <img src={post?.mainImage?.imageUrl} />
              </div>

              <div className="single_body">
                <div
                  dangerouslySetInnerHTML={{ __html: `${post?.editorData}` }}
                ></div>
                {/* {post?.editorData} */}
              </div>

              <div className="share">
                <span className="share_title">SHARE</span>

                {/* <div className="share_btns"> */}
                <span className="share_btn1">
                  {" "}
                  <FacebookShareButton
                    url={`http://obsidianforex.com/single?id=${realId}`}
                    quote={"share on facebook"}
                    hashtag={"#hashtag"}
                    description={"aiueo"}
                    className="Demo__some-network__share-button"
                  >
                    <FaFacebook></FaFacebook>
                  </FacebookShareButton>
                </span>
                <span className="share_btn">
                  {" "}
                  <TwitterShareButton
                    title={"test"}
                    url={`http://obsidianforex.com/single?id=${realId}`}
                    hashtags={["hashtag1", "hashtag2"]}
                  >
                    <FaTwitter></FaTwitter>
                  </TwitterShareButton>
                </span>
                {/* <span className="share_btn">
                  {" "}

                  <InstagramShareButton
                    title={"test"}
                    url={`http://obsidianforex.com/single?id=${realId}`}
                    hashtags={["hashtag1", "hashtag2"]}
                  >
                    <FaInstagram></FaInstagram>
                  </InstagramShareButton>
                  
                </span> */}
                {/* </div> */}
              </div>
            </Col>

            <Col xs="12" md="4" className="">
              <div className="single_head">
                <div className="single_head_title">Featured Blog Post</div>

                {/* <div className="news_head_subtitle">
            Your insights to keep you afloat
          </div> */}
              </div>

              <Row>
                {news?.length === 0 ? (
                  <></>
                ) : (
                  news?.map((item) => (
                    <Col sm="12" md="12">
                      <Card className="crypto_card">
                        <Card.Img
                          variant="top"
                          className="crypto_cardImg"
                          src={item?.mainImage?.imageUrl}
                        />

                        {item?.hot === "yes" ? (
                          <div class="crypto-hot">
                            <GiCampfire></GiCampfire> &nbsp;Hot
                          </div>
                        ) : (
                          <> </>
                        )}

                        {item?.new === "yes" ? (
                          <div class="crypto-new">New</div>
                        ) : (
                          <> </>
                        )}
                        <Card.Body>
                          <Card.Title className="main-carousel_title">
                            {item?.title}
                          </Card.Title>
                          <Card.Text className="single_body">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${item?.editorData.substring(
                                  0,
                                  "100"
                                )}...`,
                              }}
                            ></div>
                            {/* {item?.editorData.substring(0, "30")}... */}
                          </Card.Text>
                          <a href={`/single?id=${item.id}`}>
                            <Button className="main-carousel_btn">
                              Read More
                            </Button>
                          </a>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
            </Col>
          </Row>
        </Container>

        {/* <div className="more-news">

            <div className="more-news_btn">
                Load More
            </div>

        </div> */}
      </div>

      <Footer></Footer>
    </>
  );
};

export default Single;
