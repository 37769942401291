import React, { useEffect, useState } from "react";
import "../../assets/css/front/style.css";
import "../../assets/css/front/store.css";
// import Header from "./components/header";
// import Footer from "./components/footer";
// import  Container from "react-bootstrap/Container";
// import  Row from "react-bootstrap/Row";
// import  Col from "react-bootstrap/Col";
// import  Card from "react-bootstrap/Card";
// import img from "../../assets/images/technicals/1.jpg";
// import {GiCampfire} from "react-icons/gi";
// import {FaUser} from "react-icons/fa";
// import {FaCalendar} from "react-icons/fa";
// import storem1 from "../../assets/images/store/1.png";
// import storem2 from "../../assets/images/store/2.png";
// import storem3 from "../../assets/images/store/3.png";
// import storem4 from "../../assets/images/store/4.png";
// import storem5 from "../../assets/images/store/5.png";

// import p1 from "../../assets/images/store/p1.png";
// import p2 from "../../assets/images/store/p2.jpeg";
// import p3 from "../../assets/images/store/p3.jpeg";
// import p4 from "../../assets/images/store/p4.png";
// import p5 from "../../assets/images/store/p5.jpeg";

import  Button from "react-bootstrap/Button";
import logo from "../../assets/images/logo/logo2.png";
import {Link} from "react-router-dom";

const Store = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  return (
    <>

<div className="cryptocurrencybg">
<img src={logo}  style={{marginLeft: '30%', float: 'center', height: '200px', width: '50%' }} />

<div className=" cryptocurrency">

  Store coming soon :)

<br></br>
  <Link to="/"> <Button variant="outline-danger">Back</Button> </Link>
  </div>


      

      
    </div>



{/* 
      <Header></Header>

      <div className="clearfix"></div>

      <div className="store">

      <div className="store_head">
          <div className="store_head_title">&nbsp;Store</div>

          <div className="store_head_subtitle">
            Your insights to keep you afloat
          </div>
        </div>

        <Container fluid className="store_menu">

          <Row>
            <Col xs="6" md="2" className="store_menu-menu">
              <div>
              <a href="">
              <img src={storem1} />
              <div class="centered">Apparel</div>
              </a>
              </div>
            </Col>

            <Col xs="6" md="2" className="store_menu-menu">
              <div>
            <a href="">
              <img src={storem2} />
              <div class="centered">Books</div>
              </a>
              </div>
            </Col>

            <Col xs="6" md="2" className="store_menu-menu">
              <div>
            <a href="">
              <img src={storem3} />
              <div class="centered">BOT</div>
              </a>
              </div>
            </Col>

            <Col xs="6" md="2" className="store_menu-menu">
              <div>
            <a href="">
              <img src={storem4} />
              <div class="centered">Softwares</div>
              </a>
              </div>
            </Col>

            <Col xs="6" md="2" className="store_menu-menu">
              <div>
            <a href="">
              <img src={storem5} />
              <div class="centered">Stickers</div>
              </a>
              </div>
            </Col>
          </Row>

        </Container>

          <Container fluid className="store_posts">
              <Row>

                <Col sm="12" md="3" >
                <Card className="store_card">
                <Card.Img variant="top" className="store_cardImg" src={p1} />
                <div class="store-new">New</div>
                <Card.Body className="store_cardBody">
                  <a href="product-page">
                    {" "}
                    <p className="prodTitle">Lorem ipsum solar one</p>{" "}
                  </a>

                  <div>
                    {" "}
                    <span className="price"> $300</span> &nbsp;
                    <span className="salePrice">$200</span>
                  </div>
                </Card.Body>
              </Card>

                </Col>


                <Col sm="12" md="3" >
                <Card className="store_card">
                <Card.Img variant="top" className="store_cardImg" src={p2} />
                <Card.Body className="store_cardBody">
                  <a href="product-page">
                    {" "}
                    <p className="prodTitle">Lorem ipsum solar one</p>{" "}
                  </a>

                  <div>
                    {" "}
                    <span className="price"> $300</span> &nbsp;
                    <span className="salePrice">$200</span>
                  </div>
                </Card.Body>
              </Card>

                </Col>


                <Col sm="12" md="3" >
                <Card className="store_card">
                <Card.Img variant="top" className="store_cardImg" src={p3} />
                <div class="store-hot"><GiCampfire></GiCampfire> &nbsp;Hot</div>
                <div class="store-new">New</div>
                <Card.Body className="store_cardBody">
                  <a href="product-page">
                    {" "}
                    <p className="prodTitle">Lorem ipsum solar one</p>{" "}
                  </a>

                  <div>
                    {" "}
                    <span className="price"> $300</span> &nbsp;
                    <span className="salePrice">$200</span>
                  </div>
                </Card.Body>
              </Card>

                </Col>


                <Col sm="12" md="3" >
                <Card className="store_card">
                <Card.Img variant="top" className="store_cardImg" src={p4} />
                <Card.Body className="store_cardBody">
                  <a href="product-page">
                    {" "}
                    <p className="prodTitle">Lorem ipsum solar one</p>{" "}
                  </a>

                  <div>
                    {" "}
                    <span className="price"> $300</span> &nbsp;
                    <span className="salePrice">$200</span>
                  </div>
                </Card.Body>
              </Card>

                </Col>



                <Col sm="12" md="3" >
                <Card className="store_card">
                <Card.Img variant="top" className="store_cardImg" src={p5} />
                <div class="store-hot"><GiCampfire></GiCampfire> &nbsp;Hot</div>
                
                <Card.Body className="store_cardBody">
                  <a href="product-page">
                    {" "}
                    <p className="prodTitle">Lorem ipsum solar one</p>{" "}
                  </a>

                  <div>
                    {" "}
                    <span className="price"> $300</span> &nbsp;
                    <span className="salePrice">$200</span>
                  </div>
                </Card.Body>
              </Card>

                </Col>

                  
              </Row>
          </Container>

          <div className="more-news">

            <div className="more-news_btn">
                Load More
            </div>

        </div>
        
      </div>


      <Footer></Footer> */}
    </>
  );
};

export default Store;
