import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import img from "../../assets/images/technicals/1.jpg";
import { Button } from "react-bootstrap";
import { AdvancedChart } from "react-tradingview-embed";
import { getTechnicals } from "../../server/firebase.config";
import ScriptTag from "react-script-tag";

const RiskManagement = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  // document.querySelector('.tv-header__link').remove();

  
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <div className="mb">
              <Container fluid>
                <Row>
                  <Col sm="12" md="12" className="livechart">

                    {/* <ScriptTag>
                    <div id="position-size-calculator-716426">
  <script
    type="text/javascript"
    src="https://www.rebatekingfx.com/Content/dist/js/remote-widgets.js?v=8f981f06607b80a54d0439ebd54a775cdd4d2382913c63d4c7f881b25561ec97"
  ></script>
  <script type="text/javascript">
   { RemoteCalc({
      Url: "https://www.rebatekingfx.com",
      TopPaneStyle:
        "YmFja2dyb3VuZDogd2hpdGU7IGJvcmRlcjogc29saWQgMXB4IGJsYWNrOyBib3JkZXItYm90dG9tOiBub25lOyBjb2xvcjogYmxhY2s7",
      BottomPaneStyle:
        "YmFja2dyb3VuZDogd2hpdGU7IGJvcmRlcjogc29saWQgMXB4IGJsYWNrOyBjb2xvcjogYmxhY2s7",
      ButtonStyle:
        "YmFja2dyb3VuZDogYmxhY2s7IGNvbG9yOiB3aGl0ZTsgYm9yZGVyLXJhZGl1czogMjBweDs=",
      IsDisplayTitle: true,
      IsShowChartLinks: false,
      Lang: "en",
      Calculator: "position-size-calculator",
      ContainerId: "position-size-calculator-716426",
    })
  }
  </script>
</div>
                    </ScriptTag>
                   */}
                  </Col>
                </Row>
              </Container>
              </div>

             
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RiskManagement;
