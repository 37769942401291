import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import img from "../../assets/images/technicals/1.jpg";
import { GiBookmark } from "react-icons/gi";
import { FaUsers, FaOpencart, FaProductHunt } from "react-icons/fa";
import { PieChart } from "react-minimal-pie-chart";
import A from "./subpages/clock/A";
import B from "./subpages/clock/B";
import C from "./subpages/clock/C";
import D from "./subpages/clock/D";
import styled from "styled-components";
import {
  getUserOrders,
  getCourses,
  getProduct,
  getUsers,
} from "../../server/firebase.config";
import { take } from "rxjs/operators";
import { firstValueFrom } from "rxjs";

const AdminDashboard = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [Users, setUsers] = useState(null);
  const [Orders, setOrders] = useState(null);
  const [Courses, setCourses] = useState(null);
  const [Products, setProducts] = useState(null);

  const effect = useEffect(() => {
    firstValueFrom(getUsers())
      .then((item) => {
        console.log(item.length);
        setUsers(item);
      })
      .catch((error) => {
        console.log(error);
      });

    firstValueFrom(getUserOrders())
      .then((item) => {
        console.log(item.length);
        setOrders(item);
      })
      .catch((error) => {
        console.log(error);
      });

    firstValueFrom(getCourses())
      .then((item) => {
        setCourses(item);
      })
      .catch((error) => {
        console.log(error);
      });

    firstValueFrom(getProduct())
      .then((item) => {
        setProducts(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const ClockWrapper = styled.div`
    object-fit: contain;
    width: 100%;
    padding-left: 7px;

    h3 {
      margin: 2rem 0 0;
      font-family: Raleway;
      font-size: 1.25rem;
      font-weight: 300;
    }

    @media (max-width: 700px) {
      width: 100%;
      padding: 4rem 0;
    }
  `;

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <div className="mb">
              <Container fluid style={{ paddingRight: "50px" }}>
                <Row>
                  <Col sm="12" md="3">
                    <Card className="stat_card">
                      <div className="admin_stat_bar">
                        <h1 className="admin_stat_num">{Users?.length}</h1>
                        <div className="admin_stat_text">
                          {" "}
                          <FaUsers></FaUsers> &nbsp; Users
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col sm="12" md="3">
                    <Card className="stat_card">
                      <div className="admin_stat_bar">
                        <h1 className="admin_stat_num">{Orders?.length}</h1>
                        <div className="admin_stat_text">
                          {" "}
                          <FaOpencart></FaOpencart> &nbsp; Orders
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col sm="12" md="3">
                    <Card className="stat_card">
                      <div className="admin_stat_bar">
                        <h1 className="admin_stat_num">{Products?.length}</h1>
                        <div className="admin_stat_text">
                          {" "}
                          <FaProductHunt></FaProductHunt> &nbsp; Products
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col sm="12" md="3">
                    <Card className="stat_card">
                      <div className="admin_stat_bar">
                        <h1 className="admin_stat_num">{Courses?.length}</h1>
                        <div className="admin_stat_text">
                          {" "}
                          <GiBookmark></GiBookmark> &nbsp; Courses
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

                <Row style={{ marginTop: "20px" }}>
                  <Col sm="12" md="6">
                    <Card className="stat_card_time">
                      
                          <ClockWrapper className="clock-card">
                            <A size={300} />
                            <h3>New York</h3>
                          </ClockWrapper>
                        
                    </Card>
                  </Col>

                  <Col sm="12" md="6">
                    <Card className="stat_card_time">
                      <div className="admin_stat_bar">
                        <div className="admin_stat_text">
                          <ClockWrapper>
                            <B size={300} />
                            <h3>Poland</h3>
                          </ClockWrapper>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  {/* <Col sm="12" md="4">
                  <Card className="stat_card_time">
                    <div className="admin_stat_bar">

                      <div className="admin_stat_text"> 
                      <ClockWrapper>
                      <C size={300} />
                      <h3>Australia</h3>
                    </ClockWrapper>
        
                      </div>

                        </div>
                      </Card>
                    </Col> */}

                  <Col sm="12" md="6">
                    <Card className="stat_card_time">
                      <div className="admin_stat_bar">
                        <div className="admin_stat_text">
                          <ClockWrapper>
                            <D size={300} />
                            <h3>London</h3>
                          </ClockWrapper>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Container>

              <Container fluid className="admin_dashboard_stat_card2">
                <Row>
                  <Col sm="12" md="8">
                    <Card className="stat_card2">
                      <div className="admin_stat_bar2">
                        <PieChart
                          className="piechart"
                          segmentsShift={[1.5]}
                          data={[
                            { title: "Signals", value: 10, color: "#ffd228" },
                            {
                              title: "Live Course",
                              value: 15,
                              color: "#cf121f",
                            },
                            {
                              title: "Online Course",
                              value: 20,
                              color: "#2d2513",
                            },
                          ]}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col sm="12" md="4">
                    <Row>
                      <Col sm="12" md="12">
                        <Card className="stat_card">
                          <div className="admin_stat_bar">
                            <h1 className="admin_stat_num">20</h1>
                            <div className="admin_stat_text">
                              {" "}
                              <FaProductHunt></FaProductHunt> &nbsp; Visitors
                            </div>
                          </div>
                        </Card>
                      </Col>
                      &nbsp;
                      <Col sm="12" md="12">
                        <Card className="stat_card21">
                          <div className="admin_stat_bar">
                            <h1 className="admin_stat_num">20</h1>
                            <div className="admin_stat_text">
                              {" "}
                              <FaProductHunt></FaProductHunt> &nbsp; Chats
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminDashboard;
