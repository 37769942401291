import React, { useEffect, useState, Suspense } from "react";
import "../../assets/css/front/style.css";
import "../../assets/css/front/store.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import  Button  from "react-bootstrap/Button";
import {  addContact } from "../../server/firebase.config";
// const Header = React.lazy(() => import('./components/header'));
// const addContact = React.lazy(() => import('../../server/firebase.config'));

const Contact = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->


  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    

    // console.log(valueFields);

    // return

    addContact(valueFields)
    document.getElementById("form").reset();
   alert('Form Submitted')
    
    
  };

  return (
    <>

{/* <Suspense fallback={<div>Loading...</div>}> */}
      <Header></Header>
      {/* </Suspense> */}

      <div className="clearfix"></div>

      <div className="crypto">
        <div className="crypto_head">
          <div className="crypto_head_title">&nbsp;Contact Us</div>

          <div className="crypto_head_subtitle">Get in touch with us</div>
        </div>

        <Container fluid>
          <Row>
            <Col sm="12" md="6">
              <div className="contact_point">
                <div className="point_icon">
                  <FaEnvelope></FaEnvelope>
                </div>

                <div className="point_body">&nbsp; <a href="mailto:info@obsidianforex.com">info@obsidianforex.com</a></div>
              </div>

              {/* <div className="contact_point">
                <div className="point_icon">
                  <FaPhone></FaPhone>
                </div>

                <div className="point_body">&nbsp; test@test.com</div>
              </div> */}
            </Col>

            <Col sm="12" md="6">
              <form id="form" onSubmit={(event) => {
                      formData(event);
                    }}>
                <Row>
                  <Col sm="12" md="6">
                    <div className="form-group">
                      <label for="fname">Name</label>
                      <input type="text" className="form-control" id="fname" />
                    </div>
                  </Col>

                  <Col sm="12" md="6">
                    <div className="form-group">
                      <label for="email">Email</label>
                      <input type="email" className="form-control" id="email" />
                    </div>
                  </Col>
                </Row>
                <div className="form-group">
                  <label for="subject">Subject</label>
                  <input type="text" className="form-control" id="subject" />
                </div>
                <div className="form-group">
                  <label for="body">Body</label>
                  <textarea className="form-control" id="body"></textarea>
                </div>

                <div className="form-group">
                  <Button type="submit" variant="outline-dark">Send</Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Contact;
