import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";
import { authState } from "rxfire/auth";
import { collection, collectionData, docData } from "rxfire/firestore";
// import {} from "firebase/functions";
import {
  refCount,
  shareReplay,
  publishReplay,
  switchMap,
  filter,
  map,
  timestamp,
} from "rxjs/operators";
import { BehaviorSubject, of } from "rxjs";
import { getDownloadURL } from "rxfire/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB0OY75AmaZZtwGZTMDeNU_NxJDOK_-VYQ",
  authDomain: "obsidian-4dba9.firebaseapp.com",
  projectId: "obsidian-4dba9",
  storageBucket: "obsidian-4dba9.appspot.com",
  messagingSenderId: "416328853029",
  appId: "1:416328853029:web:724bbb793386b60a6b81cc",
  measurementId: "G-XZW408WZ1B",
};

firebase.initializeApp(firebaseConfig);
// if (window.location.hostname === "localhost") {
//   // firebase.firestore().settings({
//   //   host: "localhost:8080",
//   //   ssl: false,
//   // });

// //   firebase.auth().useEmulator('http://localhost:9099/');

//   // firebase.functions().useEmulator('localhost', 5001);
// }

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const fnc = firebase.functions();

export const emailPasswordProvider = firebase.auth.EmailAuthProvider;

// export const firebaseFunctions = firebase.functions();

export const fireAuthState = authState(auth);

// export const userOrders$ = fireAuthState.pipe(
//   switchMap((user) => (user?.uid ? getUserOrders(user.uid) : of([])))
// );

//Register Users
export const registerEmailandPassword = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

export const getUsers = () => {
  const ref = db.collection("users").orderBy('regDate', 'desc');
  return collectionData(ref, "id");
};

export const getOneUsers = (id) => {
  const ref = db.doc(`users/${id}`);
  return docData(ref, "id");
};

 export const getUserDetails$ = fireAuthState.pipe(switchMap(user => getOneUsers(user.uid)), shareReplay(1));

export const getPaymentDetails = (id) => {
  const ref = db.collection(`users/${id}/paymentDetails`);
  
  return collectionData(ref, "id");
};

export const deleteUser = async (id) => {
  await db.doc(`users/${id}`).delete();
  return await auth.ref(`${id}`).delete();
};

export const editUser = (id, valueFields) => {
  return db.doc(`users/${id}`).set(valueFields, { merge: true });
};

export const updateDetailsafterSignup = (
  userId,
  firstname,
  lastname,
  email,
  username,
  subscription
) => {
  const regdate = Date.now();
  var regDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(regdate);

  // console.log(userId, firstname, lastname, email, username, subscription, regDate)
  return db
    .doc(`users/${userId}`)
    .set({ userId, firstname, lastname, email, username, subscription, regDate }, { merge: true });
};

//Login Users
export const signInUsers = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const userlogout = () => {
  auth.signOut();
};

export const lastlogged = (id) => {
  // alert(id)
// const username = 'mr blaaack'
  const lastseen = Date.now()
  var lastOnline = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(lastseen);
  return db.doc(`users/${id}`).set({lastOnline}, { merge: true });
  
};


export const resetPassword = (email) => {
  return auth.sendPasswordResetEmail(email);
};

// language
const langSubject$ = new BehaviorSubject("PL");
export const language$ = langSubject$.pipe(
  switchMap((_) => {
    const language = localStorage.getItem("language");
    if (language) {
      return of(language);
    }
    return of("PL");
  })
);

export const changeLanguage = (lan) => {
  localStorage.setItem("language", lan);
  langSubject$.next(lan);
};

// GET, UPDATE and DELETE POST //

//get
export const getPostFromFirebase = () => {
  const ref = db.collection("post");
  return collectionData(ref, "id");
};

export const getOnePostFromFirebase = (id) => {
  const ref = db.doc(`post/${id}`);
  return docData(ref, "id");
};

export const getPostFromFirebaseByCategory = (category) => {
  const ref = db.collection("post").where("cat", "==", category).orderBy('timestamp', 'desc');
  return collectionData(ref, "id");
};

export const getonePostFromFirebaseByCategory = (category) => {
  const ref = db.collection("post").where("cat", "==", category).where("featured", "==", "yes").where("view", "==", 'public').orderBy('timestamp', 'desc').limit(1);
  return collectionData(ref, "id");
};

export const getFourPostFromFirebase = () => {
  const ref = db.collection("post").orderBy('timestamp', 'desc').limit(4);
  return collectionData(ref, "id");
};

//CREATE Post
export const createPost = (post) => {
  return db.collection("post").add(post);
  // return collectionData(ref, "id");
};

export const editPost = (id, valueFields) => {
  return db.doc(`post/${id}`).set(valueFields, { merge: true });
};

export const deletePost = (id) => {
  return db.doc(`post/${id}`).delete();
};

//CREATE Draft
// export const createPostDraft = (postdraft) => {
//   return db.collection("postdraft").add(postdraft);
//   // return collectionData(ref, "id");
// };

// export const editPostDraft = (id, valueFields) => {
//   return db.doc(`postdraft/${id}`).set(valueFields, { merge: true });
// };

// export const deletePostDraft = (id) => {
//   return db.doc(`postdraft/${id}`).delete();
// };

// GET, UPDATE and DELETE CATEGORY //

export const createCategories = (categories) => {
  return db.collection("categories").add(categories);
};

export const getCategories = () => {
  const ref = db.collection("categories");
  return collectionData(ref, "id");
};

export const getOneCategories = (id) => {
  const ref = db.doc(`categories/${id}`);
  return collectionData(ref, "id");
};

export const editCategory = (id, valueFields) => {
  return db.doc(`categories/${id}`).set(valueFields, { merge: true });
};

export const deleteCategory = (id) => {
  return db.doc(`categories/${id}`).delete();
};

//images
export const saveImage = (images) => {
  // const timestamp = Date.now();
  return db.collection("images").add(images);
};

export const getimageFromFirebase = () => {
  const ref = db.collection("images").orderBy('timestamp', 'desc');
  return collectionData(ref, "id");
};

export const deleteImage = async (id, path) => {
  await db.doc(`images/${id}`).delete();
  return await storage.ref(`${path}`).delete();
};

//NewsLetter
// export const addEmail = (email) => {
//     return db.collection('email').add(email);
//   };

export const getEmails = () => {
  const ref = db.collection("email");
  return collectionData(ref, "id");
};

export const deleteEmail = (id) => {
  return db.doc(`email/${id}`).delete();
};

// Contact us
export const addMessage = (contactForm) => {
  return db.collection("contactForm").add(contactForm);
};

export const getMessage = () => {
  const ref = db.collection("contactForm");
  return collectionData(ref, "id");
};

// Orders

export const getUserOrders = () => {
  const ref = db.collection("orders");
  return collectionData(ref, "id");
};

// Course

export const getCourses = () => {
  const ref = db.collection("course").orderBy('timestamp', 'desc');
  return collectionData(ref, "id");
};

export const getAscCourses = () => {
  const ref = db.collection("course").orderBy('timestamp', 'asc');
  return collectionData(ref, "id");
};
export const getOneCourse = (id) => {
  const ref = db.doc(`course/${id}`);
  return collectionData(ref, "id");
};

export const  addCourse = (course, id) => {
  const courseRef =  db.doc(`course/${id}`);
  const notRef =  db.doc(`notifications/course`);
 return  db.runTransaction ((transaction) => {
  // const notDoc = await transaction.get('notifications/course');
  // const courseCount = notDoc.data().courseCount;
  // courseCount = []
  transaction.set(courseRef, course, {merge: true});
  transaction.set(notRef, {courseCount: firebase.firestore.FieldValue.arrayUnion(`${id}/${course.timestamp}`)}, {merge: true});
  return Promise.resolve('done');
}); 
};

export const editCourses = (id, valueFields) => {
  return db.doc(`course/${id}`).set(valueFields, { merge: true });
};

export const deleteCourse = (id) => {
  return db.doc(`course/${id}`).delete();
};

// Sub Course


export const getClass = (id) => {
  const ref = db.collection(`course/${id}/class`).orderBy('timestamp', 'asc');
  return collectionData(ref, "id");
};

export const getOneClass = (id,secondid) => {
  console.log(id)
  console.log(secondid)
  const ref = db.doc(`course/${id}/class/${secondid}`);
  return docData(ref, "id");
};


export const addClass = (id, classes) => {
  return db.collection(`course/${id}/class`).add(classes);
};


export const editClass = (firstid, secondid, valueFields) => {
  return db.doc(`course/${firstid}/class/${secondid}`).set(valueFields, { merge: true });
};

export const deleteClass = (id, secondid) => {
  return db.doc(`course/${id}/class/${secondid}`).delete();
};





// Product

export const getProduct = () => {
  const ref = db.collection("product");
  return collectionData(ref, "id");
};

// Live Course

export const getLivecourse = () => {
  const ref = db.collection("livecourse");
  return collectionData(ref, "id");
};

export const addLivecourse = (livecourse) => {
  return db.collection("livecourse").add(livecourse);
};

export const deleteLivecourse = (id) => {
  return db.doc(`livecourse/${id}`).delete();
};


// Signals

export const getSignal = () => {
  const ref = db.collection("signals");
  return collectionData(ref, "id");
};

export const getOneSignal = (id) => {
  const ref = db.doc(`signals/${id}`);
  return docData(ref, "id");
};

export const editSignal = (id, valueFields) => {
  return db.doc(`signals/${id}`).set(valueFields, { merge: true });
};


export const createSignal = (livecourse) => {
  return db.collection("signals").add(livecourse);
};

export const deleteSignal = (id) => {
  return db.doc(`signals/${id}`).delete();
};

// Weekly Technicals

export const getTechnicals = () => {
  const ref = db.collection("technicals").limit(4);
  return collectionData(ref, "id");
};

export const addTechnicals = (technicals) => {
  return db.collection("technicals").add(technicals);
};

export const deleteTechnicals = (id) => {
  return db.doc(`technicals/${id}`).delete();
};


// Videos

export const getVideos = () => {
  const ref = db.collection("videos").limit(4);
  return collectionData(ref, "id");
};

export const addVideos = (technicals) => {
  return db.collection("videos").add(technicals);
};

export const deleteVideos = (id) => {
  return db.doc(`videos/${id}`).delete();
};




// Support Ticket

export const getTickets = () => {
  const ref = db.collection("ticket");
  return collectionData(ref, "id");
};

export const addTicket = (ticket) => {
  return db.collection("ticket").add(ticket);
};

export const deleteTicket = (id) => {
  return db.doc(`ticket/${id}`).delete();
};


// membership

export const getSignalMembership = () => {
  const ref = db.doc(`membership/CBqwsjGpJYusEiBFtEiQ/signals/ZGIIwQ0ap6qOboZyRDGR`);
  return docData(ref, "id");
};

export const getLiveMembership = () => {
  const ref = db.doc(`membership/CBqwsjGpJYusEiBFtEiQ/live/7ybx03rdqqiI8fjaqx72`);
  return docData(ref, "id");
};

export const getOnlineMembership = () => {
  const ref = db.doc(`membership/CBqwsjGpJYusEiBFtEiQ/online/PaSIHQnZbb109YQ6H5JX`);
  return docData(ref, "id");
};


export const editLiveMembership = (valueFields) => {
  return db.doc(`membership/CBqwsjGpJYusEiBFtEiQ/live/7ybx03rdqqiI8fjaqx72`).set(valueFields, { merge: true });
};

export const editOnlineMembership = (valueFields) => {
  return db.doc(`membership/CBqwsjGpJYusEiBFtEiQ/online/PaSIHQnZbb109YQ6H5JX`).set(valueFields, { merge: true });
};

export const editSignalMembership = (valueFields) => {
  return db.doc(`membership/CBqwsjGpJYusEiBFtEiQ/signals/ZGIIwQ0ap6qOboZyRDGR`).set(valueFields, { merge: true });
};


// Settings

export const getSettings = () => {
  const ref = db.collection("settings");
  return collectionData(ref, "id");
};

export const getAboutSettings = () => {
  const ref = db.collection(`settings/Dh2aw5Qi6TgVTxCaJQfx/about`);
  return collectionData(ref, "id");
};
export const editSettings = (valueFields) => {
  return db.doc(`settings/Dh2aw5Qi6TgVTxCaJQfx`).set(valueFields, { merge: true });
};

export const editAboutSettings = (valueFields) => {
  return db.doc(`settings/Dh2aw5Qi6TgVTxCaJQfx/about/YBV3xQ0ymIPfIR6DGxbS`).set(valueFields, { merge: true });
};

// About

export const getAbout = () => {
  const ref = db.collection(`settings/Dh2aw5Qi6TgVTxCaJQfx/about`);
  return collectionData(ref, "id");
};

export const editAbout = (id, valueFields) => {
  return db.doc(`settings/Dh2aw5Qi6TgVTxCaJQfx/about/${id}`).set(valueFields, { merge: true });
};

// Newsletter

export const getNewsletter = () => {
  const ref = db.collection("newsletter").orderBy('timestamp', 'desc');
  return collectionData(ref, "id");
};

export const addNewsletter = (newsletter) => {
  return db.collection("newsletter").add(newsletter);
};

export const editNewsletter = (id, valueFields) => {
  return db.doc(`newsletter/${id}`).set(valueFields, { merge: true });
};

export const deleteNewsletter = (id) => {
  return db.doc(`newsletter/${id}`).delete();
};


// Coupons

export const getCoupons = () => {
  const ref = db.collection("coupons").orderBy('regDate', 'desc');
  return collectionData(ref, "id");
};

export const addCoupon = (newsletter) => {
  return db.collection("coupons").add(newsletter);
};

export const editCoupon = (id, valueFields) => {
  return db.doc(`coupons/${id}`).set(valueFields, { merge: true });
};

export const deleteCoupon = (id) => {
  return db.doc(`coupons/${id}`).delete();
};


// Contact
export const addContact = (newsletter) => {
  return db.collection("contacts").add(newsletter);
};

export const getContacts = () => {
  const ref = db.collection("contacts");
  return collectionData(ref, "id");
};


export const createPaymentIntent = (price) => {
  return fnc.httpsCallable('createPaymentIntent')(price);
}

export const createCustomer = (customer) => {
  return fnc.httpsCallable('createCustomer')(customer);
}

export const createProduct = (product) => {
  return fnc.httpsCallable('createProduct')(product);
}

export const stripeSetUpIntentCardSave = (userData) => {
  return fnc.httpsCallable('stripeSetUpIntentCardSave')(userData);
}

export const createSubscription = (subs) => {
  return fnc.httpsCallable('createSubscription')(subs);
}

export const sendEmailVerification = (email, userid) => {

  // const details = {email, userid}
  return fnc.httpsCallable('sendEmailVerification')({email, userid});

}


