import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { firstValueFrom } from "rxjs";
import queryString from "query-string";
import { getOneUsers, editUser } from "../../../../server/firebase.config";

const EditUser = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [users, setUsers] = useState(null);

  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];

  const effect = useEffect(() => {
    firstValueFrom(getOneUsers(realId))
      .then((item) => {
        console.log(item);
        setUsers(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    // console.log(valueFields);

    // return

    editUser(realId, valueFields);
    alert('User Edited')
    // document.getElementById("form").reset();
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <div className="mb">
              <Container fluid>
                <Row>
                  <Col sm="12" md="12" className="livechart">
                    <Form
                      className="add-form"
                      id="form"
                      onSubmit={(event) => {
                        formData(event);
                      }}
                    >
                      <div className="form-group">
                        <label for="username">Username</label>
                        <input
                          type="text"
                          id="username"
                          className="form-control"
                          defaultValue={users?.username}
                          name="username"
                        />
                      </div>

                      <div className="form-group">
                        <label for="email">Email</label>
                        <input
                          type="text"
                          id="email"
                          className="form-control"
                          defaultValue={users?.email}
                          name="email"
                        />
                      </div>

                      <div className="form-group">
                        <label for="cat">Subscription</label>
                        <select
                          id="cat"
                          className="form-control"
                          name="subscription"
                        >
                          <option defaultValue={users?.subscription}>
                            {users?.subscription}
                          </option>
                          <optgroup></optgroup>
                          <option value="online">Online Course</option>
                          <option value="live">Live course</option>
                          <option value="signals">Signals</option>
                          <option value="all">All</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label for="cat">Admin</label>
                        <select
                          id="cat"
                          className="form-control"
                          name="admin"
                        >
                          <option defaultValue={users?.admin}>{users?.admin}</option>
                          
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <Button type="submit" variant="outline-dark">
                          Edit User
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditUser;
