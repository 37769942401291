import React, { useEffect, useState } from "react";
import "../../../assets/css/front/style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import logo2 from "../../../assets/images/logo/logo2.png"
import {FaTelegram, FaTwitter, FaInstagram, FaYoutube} from "react-icons/fa";
import {  addNewsletter, getSettings } from "../../../server/firebase.config";
const Footer = () => {
  
    const [settings, setSettings] = useState(null);
    // console.log(settings)

    const effect = useEffect(() => {
        const subscription = getSettings().subscribe(
          (item) => {
            //   console.log(item)
            setSettings(item);
          }
        );

        return () => {
            subscription.unsubscribe();
          };
        }, []);

    const formData = (event) => {
        event.preventDefault();
    
        const values = Array.from(event.target.elements)
          .map((item) => ({
            name: item.name,
            value: item.value,
          }))
          .filter((val) => val.name !== "");
    
        let valueFields = {};
    
        for (const items in values) {
          const { name, value } = values[items];
          valueFields[name] = value;
        }

        valueFields = { ...valueFields, timestamp: Date.now() };
        
    
        // console.log(valueFields);
    
        // return
    
        addNewsletter(valueFields)
        document.getElementById("form").reset();
       alert('Email added')
        
        
      };
    

  return (
    <>

    <div className="footer">

    
    <div className="newsletter">
        <div className="newsletter_title">
            Subscribe To Our Newsletter 
        </div>

        <div className="newsletter_body">
        For up to date Market articles, fundamental news, weekly technicals and first hand information about general upgrade on the platform.
        </div>

        <form className="newsletter_form" id="form" onSubmit={(event) => {
                      formData(event);
                    }}>
            <div className="form-group newsletter_input">
                <input type="email" className="form-control input" name="email" placeholder="your email" />
            </div>
                <input type="hidden"  name="status" value="active" />
           

            <input type="submit" className="newsletter_input_btn" value="SUBSCRIBE" />
        </form>
    </div>

        <Container fluid className="footer_sec">
            <Row>
                
                <Col xs="12" md="3" className="footer_sec1 order-4 order-lg-1">

                    <img src={logo2} />

                    <div className="footer_sec1-copyright">
                    &copy; The Obsidian LLC 2021,
All right Reserved
                    </div>
                </Col>

                <Col xs="12" md="3" className="footer_sec2 0rder-1 order-lg-2">

                    <ul>
                        <li className="footer_sec2_menu_head">Menu</li>

                        <li className="footer_sec2_menu"> <a href="/technicals">Technicals</a></li>
                        <li className="footer_sec2_menu"> <a href="/market-news">Market News</a></li>
                        <li className="footer_sec2_menu"> <a href="/crypto">Cryptocurrency</a></li>
                        <li className="footer_sec2_menu"> <a href="/store">Store</a></li>
                        {/* <li className="footer_sec2_menu">Community</li> */}
                    </ul>

                </Col>

                <Col xs="12" md="3" className="footer_sec3 order-2 order-lg-3">

                {settings?.map((item) => 
                <ul>
                        <li className="footer_sec2_menu_head">Stay Connected</li>


                        <li className="footer_sec2_menu"> <a href={item?.telegram} target="_blank"><FaTelegram></FaTelegram> &nbsp; Telegram </a></li>
                        <li className="footer_sec2_menu"> <a href={item?.twitter} target="_blank"><FaTwitter></FaTwitter> &nbsp; Twitter </a></li>
                        <li className="footer_sec2_menu"> <a href={item?.instagram} target="_blank"><FaInstagram></FaInstagram> &nbsp; Instagram </a></li>
                        <li className="footer_sec2_menu"> <a href={item?.youtube} target="_blank"><FaYoutube></FaYoutube> &nbsp; Youtube</a></li>
                    </ul>
                    )} 

                </Col>

                <Col xs="12" md="3" className="footer_sec4 order-3 order-lg-4">
                    
                    <ul>
                        <li className="footer_sec2_menu_head">Support</li>

                        <li className="footer_sec2_menu"> <a href="/faqs">FAQs  </a> </li>
                        <li className="footer_sec2_menu"> <a href="/contact">Contact Us </a></li>
                        <li className="footer_sec2_menu"> <a href="/privacy">Privacy Policy </a></li>
                        <li className="footer_sec2_menu"> <a href="/terms">Terms & Conditions</a></li>
                    </ul>

                </Col>

                
            </Row>

            
        </Container>

        

        
    </div>


    

     
    </>
  );
};

export default Footer;
