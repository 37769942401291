import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useHistory,
  Redirect,
  Route,
} from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";

import Home from "./pages/front/home";
import Technicals from "./pages/front/technicals";
import News from "./pages/front/news";
import Crypto from "./pages/front/crypto";
import Store from "./pages/front/store";
import Single from "./pages/front/single";
import TechnicalArt from "./pages/front/technical-articles";
import Auth from "./pages/auth/auth";
import SignUp from "./pages/auth/register";
import ForgotAuth from "./pages/auth/forgot";
import Dashboard from "./pages/clients/dashboard";
import Course from "./pages/clients/course";
import Feeds from "./pages/clients/feeds";
import Tech from "./pages/clients/tech";
import Signals from "./pages/clients/signals";
import Live from "./pages/clients/live";
import Chart from "./pages/clients/chart";
import CoursePage from "./pages/clients/coursePage";
import Downloads from "./pages/clients/downloads";
import Support from "./pages/clients/support";
import AdminDashboard from "./pages/admins/dashboard";
import AddPost from "./pages/admins/subpages/post/addPost";
import Viewpost from "./pages/admins/subpages/post/viewPost";
import EditPost from "./pages/admins/subpages/post/editPost";
import AddUsers from "./pages/admins/subpages/users/addUsers";
import ViewUsers from "./pages/admins/subpages/users/viewUser";
import Imgfiles from "./pages/admins/images";
import AddProducts from "./pages/admins/subpages/store";
import Viewproduct from "./pages/admins/subpages/store/view";
import Inventory from "./pages/admins/subpages/store/inventory";
import LiveCourse from "./pages/admins/subpages/live/index";
import Golive from "./pages/admins/subpages/live/live";
import EditUser from "./pages/admins/subpages/users/edit";
import AdminSupport from "./pages/admins/subpages/support";
import ViewCourse from "./pages/admins/subpages/course";
import AddCourse from "./pages/admins/subpages/course/add";
import AddClass from "./pages/admins/subpages/course/addClass";
import Viewclass from "./pages/admins/subpages/course/viewClass";
import { fireAuthState, getOneUsers } from "./server/firebase.config";
import { firstValueFrom } from "rxjs";
import View from "./pages/clients/viewpost";
import Techart from "./pages/clients/tech-art";
import Profile from "./pages/clients/profile";
import Settings from "./pages/admins/admin-settings";
import EditClass from "./pages/admins/subpages/course/editclass";
import EditCourse from "./pages/admins/subpages/course/edit";

import Faqs from "./pages/front/faqs";
import Help from "./pages/front/help";
import Contact from "./pages/front/contact";
import Privacy from "./pages/front/privacy";
import Newsletter from "./pages/admins/subpages/newsletter";
import AddWeekly from "./pages/admins/subpages/post/weekly";
import Subscription from "./pages/clients/subs";
import RiskManagement from "./pages/clients/risk";
import Demo from "./pages/auth/confirm";
import { switchMap } from "rxjs/operators";
import DashSignals from "./pages/admins/subpages/signals";
import AddVideos from "./pages/admins/subpages/post/videos";
import Videos from "./pages/clients/videos";
import EmailConfirmed from "./pages/auth/confirm";
import NFT from "./pages/clients/nft";

// const Home = React.lazy(() => import("./pages/front/home"));
// const Technicals = React.lazy(() => import("./pages/front/technicals"));
// const News = React.lazy(() => import("./pages/front/news"));
// const Crypto = React.lazy(() => import("./pages/front/crypto"));
// const Store = React.lazy(() => import("./pages/front/store"));
// const Single = React.lazy(() => import("./pages/front/single"));
// const TechnicalArt = React.lazy(() =>
//   import("./pages/front/technical-articles")
// );
// const Faqs = React.lazy(() => import("./pages/front/faqs"));
// const Help = React.lazy(() => import("./pages/front/help"));
// const Contact = React.lazy(() => import("./pages/front/contact"));
// const Privacy = React.lazy(() => import("./pages/front/privacy"));

// const Auth = React.lazy(() => import("./pages/auth/auth"));
// const SignUp = React.lazy(() => import("./pages/auth/register"));
// const ForgotAuth = React.lazy(() => import("./pages/auth/forgot"));

const App = () => {
  const requireLogin = async (to, from, next) => {
    try {
      const user = await firstValueFrom(fireAuthState);
      if (user && user.emailVerified) {
        next();
      } else {
        if (user) {
          const userProfile = await firstValueFrom(getOneUsers(user.uid));
          if (userProfile?.verified === "yes") {
            next();
            return;
          }
          next.redirect("/dashboard");
        }
        next.redirect("/auth");
      }
    } catch (error) {
      next.redirect("/auth");
    }
  };

  const requireAdmin = async (to, from, next) => {
    // const userProfile = fireAuthState.pipe(switchMap(user => getOneUsers(user.uid)))
    try {
      const user = await firstValueFrom(fireAuthState);
      if (user) {
        const userProfile = await firstValueFrom(getOneUsers(user.uid));
        if (userProfile?.admin === "yes") {
          next();
          return;
        }
        next.redirect("/dashboard");
      }
      next.redirect("/auth");
    } catch (error) {
      next.redirect("/auth");
    }
  };

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
        <Route exact path="/">
    <Redirect to="/home" />
</Route>
          <Route path="/home" exact component={Home} />
          <Route path="/technicals" exact component={Technicals} />
          <Route path="/market-news" exact component={News} />
          <Route path="/crypto" exact component={Crypto} />
          <Route path="/store" exact component={Store} />
          <Route path="/technical-articles" exact component={TechnicalArt} />
          <Route path="/faqs" exact component={Faqs} />
          <Route path="/terms" exact component={Help} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/single" exact component={Single} />
          <Route path="/confirm-email" component={EmailConfirmed} />

          <Route path="/auth" exact component={Auth} />
          <Route path="/signup" exact component={SignUp} />
          <Route path="/forgot" component={ForgotAuth} />
          {/* <Route path="**" exact component={EmailConfirmed} /> */}
        </Switch>
      </Suspense>

      <GuardProvider guards={[requireLogin]}>
        <GuardedRoute path="/demo" exact component={Demo} />

        <GuardedRoute path="/dashboard" exact component={Dashboard} />
        <GuardedRoute path="/course" exact component={Course} />
        <GuardedRoute path="/feeds" exact component={Feeds} />
        <GuardedRoute path="/tech" exact component={Tech} />
        <GuardedRoute path="/signals" exact component={Signals} />
        <GuardedRoute path="/live" exact component={Live} />
        <GuardedRoute path="/view-class" exact component={CoursePage} />
        <GuardedRoute path="/view-post" exact component={View} />
        <GuardedRoute path="/downloads" exact component={Downloads} />
        <GuardedRoute path="/support" exact component={Support} />
        <GuardedRoute path="/chart" exact component={Chart} />
        <GuardedRoute path="/tech-articles" exact component={Techart} />
        <GuardedRoute path="/profile" exact component={Profile} />
        <GuardedRoute path="/subscription" exact component={Subscription} />
        <GuardedRoute path="/videos" exact component={Videos} />
        <GuardedRoute path="/nft" exact component={NFT} />
        <GuardedRoute
          path="/risk-management"
          exact
          component={RiskManagement}
        />
      </GuardProvider>

      <GuardProvider guards={[requireAdmin]}>
        <GuardedRoute
          path="/bl4ck444/dashboard"
          exact
          component={AdminDashboard}
        />
        <GuardedRoute path="/bl4ck444/add-post" exact component={AddPost} />
        <GuardedRoute path="/bl4ck444/view-post" exact component={Viewpost} />
        <GuardedRoute path="/bl4ck444/edit-post" exact component={EditPost} />

        <GuardedRoute path="/bl4ck444/add-user" exact component={AddUsers} />
        <GuardedRoute path="/bl4ck444/view-user" exact component={ViewUsers} />
        <GuardedRoute path="/bl4ck444/edit-users" exact component={EditUser} />

        <GuardedRoute path="/bl4ck444/signals" exact component={DashSignals} />

        <GuardedRoute path="/bl4ck444/images" exact component={Imgfiles} />

        <GuardedRoute
          path="/bl4ck444/add-products"
          exact
          component={AddProducts}
        />
        <GuardedRoute
          path="/bl4ck444/view-products"
          exact
          component={Viewproduct}
        />
        <GuardedRoute path="/bl4ck444/inventory" exact component={Inventory} />

        <GuardedRoute
          path="/bl4ck444/live-course"
          exact
          component={LiveCourse}
        />
        <GuardedRoute path="/bl4ck444/live" exact component={Golive} />

        <GuardedRoute path="/bl4ck444/settings" exact component={Settings} />

        <GuardedRoute path="/bl4ck444/support" exact component={AdminSupport} />

        <GuardedRoute
          path="/bl4ck444/all-courses"
          exact
          component={ViewCourse}
        />
        <GuardedRoute path="/bl4ck444/add-course" exact component={AddCourse} />
        <GuardedRoute
          path="/bl4ck444/edit-course"
          exact
          component={EditCourse}
        />
        <GuardedRoute path="/bl4ck444/add-class" exact component={AddClass} />
        <GuardedRoute path="/bl4ck444/view-class" exact component={Viewclass} />
        <GuardedRoute path="/bl4ck444/edit-class" exact component={EditClass} />
        <GuardedRoute
          path="/bl4ck444/newsletter"
          exact
          component={Newsletter}
        />
        <GuardedRoute
          path="/bl4ck444/weekly-breakdowns"
          exact
          component={AddWeekly}
        />

        <GuardedRoute path="/bl4ck444/videos" exact component={AddVideos} />
      </GuardProvider>
    </Router>
  );
};

export default App;
