import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { config } from "rxjs";
import {
  createPost,
  getimageFromFirebase,
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import Toast from "react-bootstrap/Toast";
// import ToastContainer from 'react-bootstrap/ToastContainer';

const AddProducts = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [showToast, setshowToast] = useState(false);
  
  const file = useRef(null);
  const editorData = useRef("");


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Image, setImages] = useState(null);

  const effect = useEffect(() => {
    firstValueFrom(getimageFromFirebase())
      .then((item) => {
        // console.log(item);
        setImages(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    // console.log(file.current);

    if (file.current) {
      // them upload the file ,
      // then get the download url ;
      console.log(file.current);
      valueFields = {
        ...valueFields,
        mainImage: file.current,
        editorData: editorData.current,
      };
    }

    // console.log(valueFields);

    // return

    createPost(valueFields)

    setshowToast(true)
    document.querySelector(".add-form").value = null;
    
  };

  // const img = (event) => {
  //   if (event.target.checked) {
  //      productImage = event.target.value;

  //   }
  // }

  const selectImage = (event) => {
    console.log({ event });
    file.current = event;
    handleClose();
  };

  return (
    <div>
      {/* <Container fluid> */}
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>




  {/* <ToastContainer position="top-end" className="p-3"> */}

  
    <Toast onClose={() => setshowToast(false)} show={showToast} delay={3000} className="toast" autohide>
      
      <Toast.Body className="success">Post Added</Toast.Body>
    </Toast>
  {/* </ToastContainer> */}

   

          <div className="clearfix"></div>
          <div className="mb">
            <Container fluid>
              <Row>
                <Col sm="12" md="12" className="livechart">
                  <Form
                    className="add-form"
                    onSubmit={(event) => {
                      formData(event);
                    }}
                  >
                    <div className="form-group">
                      <label for="ptitle">Product Name</label>
                      <input
                        type="text"
                        id="ptitle"
                        className="form-control"
                        name="title"
                      />
                    </div>

                    <div className="form-group">
                      <label for="pimg">Product Image</label>
                      {/* <input type="file" id="pimg" className="form-control" name="" /> */}
                      <div className="dropzone" onClick={handleShow}>
                        <FaUpload className="dragicon"></FaUpload>
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label for="cat">Category</label>
                      <select id="cat" className="form-control" name="cat">
                        <option defaultValue>Choose</option>
                        <optgroup></optgroup>
                        <option value="apparel">Apparel</option>
                        <option value="books">Books</option>
                        <option value="bot">Bot</option>
                        <option value="softwares">Softwares</option>
                        <option value="stickers">Stickers</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="ptitle">Price</label>
                      <input
                        type="text"
                        id="ptitle"
                        className="form-control"
                        name="title"
                      />
                    </div>

                    <div className="form-group">
                      <label for="ptitle">Sales Price</label>
                      <input
                        type="text"
                        id="ptitle"
                        className="form-control"
                        name="title"
                      />
                    </div>

                    <div className="form-group">
                      <label for="cat">Sales</label>
                      <select id="cat" className="form-control" name="cat">
                        <option defaultValue>Choose</option>
                        <optgroup></optgroup>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>

                    

                    <div className="form-group">
                      <label for="body">Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={(config.height = "500")}
                        onChange={(event, editor) => {
                          editorData.current = editor.getData();
                          // console.log( { event, editor, data } );
                        }}
                        name="body"
                      />
                    </div>

                    <div className="form-group">
                      <Button type="submit" variant="outline-dark">
                        Publish
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {Image?.map((item) => (
                <Col sm="12" md="4">
                  <Card
                    onClick={() => {
                      selectImage(item);
                    }}
                    className="imgSelection-li"
                  >
                    <label class="img-label" for={`cb${item.imageUrl}`}>
                      <Card.Img
                        variant="top"
                        className=""
                        src={item.imageUrl}
                      />
                    </label>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* </Container> */}
    </div>
  );
};

export default AddProducts;
