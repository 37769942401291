import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/styleNew.css";
import oc from "../../assets/images/dashboard/oc.png";
import { Link } from "react-router-dom";
import { GiCampfire } from "react-icons/gi";
import { EconomicCalendar } from "react-tradingview-embed";
import Alert from "react-bootstrap/Alert";
import { Button } from "react-bootstrap";
import "elix/define/SlidingPages";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import {
  getClass,
  getAscCourses,
  getOneUsers,
} from "../../server/firebase.config";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { firstValueFrom } from "rxjs";
import { fireAuthState } from "../../server/firebase.config";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";

const Course = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  SwiperCore.use([Navigation, Pagination, A11y]);
  const [Class, setClass] = useState(null);
  const [Courses, setCourses] = useState(null);
  const idRef = useRef(null);
  const swipe = useRef(null);

  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];

  const location = useLocation();

  const effect = useEffect(() => {
    const query = new URLSearchParams(location.search);
    // alert(query.get("id"))

const courseId = query.get("id") ?? "Introduction to Forex1627454808602"
    const subscription = getClass(courseId).subscribe((item) => {
     
      setClass(item);
    });

    firstValueFrom(getAscCourses())
      .then((course) => {
        // alert(course)
        if (course) {
          setCourses(course);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      subscription.unsubscribe();

      // subs.forEach((element) => {
      //   element.unsubscribe();
      // });
      // subscription.unsubscribe();
    };
  }, [location]);

  const next = () => {
    console.log(swipe.current.swipe);
    // swipe.current.slideNext()
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>
            <br />
              <br />

            <div className="swiper">
                <Swiper
                  
                  ref={swipe}
                  spaceBetween={50}
                  slidesPerView={4}
                  navigation={{
                    nextEl: ".next",
                    prevEl: ".previous",
                  }}
                >
                  <div className="previous">
                    <FaLongArrowAltLeft
                      className="previous"
                      className="icon"
                    ></FaLongArrowAltLeft>
                  </div>

                  <div className="swiperMain">
                    {Courses?.map((item) => (
                      <SwiperSlide>
                        <Link
                          to={`/course?id=${item.id}`}
                          className="course_cat"
                        >
                          {" "}
                          {item?.title}
                        </Link>
                        <div className="s_dot"></div>
                        &nbsp;
                        <div className="l_dot"></div>
                        &nbsp;
                        <div className="s_dot"></div>
                      </SwiperSlide>
                    ))}
                  </div>

                  <div className="next">
                    <FaLongArrowAltRight
                      className="next"
                      className="icon"
                    ></FaLongArrowAltRight>
                  </div>
                </Swiper>
              </div>

            <Container fluid className="mb">
              

              

              <Row>
                {Class?.length === 0 ? (
                  <></>
                ) : (
                  Class?.map((item) => (
                    <Col sm="12" md="3">
                      <Card className="main-carousel">
                        <img src={item?.mainImage?.imageUrl} />

                        {item?.hot === "yes" ? (
                          <div class="crypto-hot">
                            <GiCampfire></GiCampfire> &nbsp;Hot
                          </div>
                        ) : (
                          <> </>
                        )}

                        {item?.new === "yes" ? (
                          <div class="crypto-new">New</div>
                        ) : (
                          <> </>
                        )}

                        <Card.Body>
                          <Card.Title className="main-carousel_title">
                            {item?.title}
                          </Card.Title>
                          <Card.Text>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${item?.editorData.substring(
                                  0,
                                  "100"
                                )}...`,
                              }}
                            ></div>
                          </Card.Text>

                          <a
                            href={`/view-class?id=${item.id}&idclass=${realId}`}
                          >
                            <Button className="main-carousel_btn">
                              Read More
                            </Button>
                          </a>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Course;
