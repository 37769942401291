import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/clients/style.css";
import { FaEdit, FaTrash, FaUpload } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getTickets, addTicket } from "../../../../server/firebase.config";
import Dropzone from "react-dropzone";
import { getDownloadURL, put } from "rxfire/storage";
import { firstValueFrom, lastValueFrom } from "rxjs";
import Form from "react-bootstrap/Form";
import Badge from 'react-bootstrap/Badge'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const AdminSupport = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [ticket, setTicket] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const editorData = useRef("");


  const regdate = Date.now();
    var regDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(regdate);

  const effect = useEffect(() => {
    const subscription = getTickets().subscribe((item) => {
      setTicket(item);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);


  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    if (editorData.current) {
        // console.log(file.current);
        valueFields = {
          ...valueFields,
          editorData: editorData.current,
          status: 'open',
          startdate: regDate,
        };
      }
    
    // console.log(valueFields);
    
    // return

    addTicket(valueFields)
    document.getElementById("form").reset();
    document.getElementById("editor").reset();
    // setshowToast(true)
    
    
  };

  return (
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>

          <div className="clearfix"></div>

          <Container fluid className="mb feeds crypto_posts">
            <div className="admin_hero_head">
              <button className="btn btn-outline-dark" onClick={handleShow}>
                Create Ticket
              </button>
            </div>

            <Row>
              <Col
                sm="12"
                md="12"
                className=""
                style={{ background: "white" }}
              >
                <Table    style={{ marginTop: "10px" }}>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Ticket</th>
                      <th>Status</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticket?.map((item) => (
                      <tr>
                        <td>{item?.username}</td>
                        <td>{item?.supportTitle}</td>
                        <td>
                            { item?.status==='open'? 
                            <Badge bg="warning" text="dark">
                            {item?.status}
                          </Badge>
                            
                            :
                  <> </>

                }

{ item?.status==='ongoing'? 
                            <Badge bg="info">{item?.status}</Badge>
                            :
                  <> </>

                }

{ item?.status==='closed'? 
                            <Badge bg="danger">{item?.status}</Badge>
                            :
                  <> </>

                }

{ item?.status==='completed'? 
                            <Badge bg="success">{item?.status}</Badge>
                            :
                  <> </>

                }
                            </td>
                        <td>{item?.startdate} </td>
                        <td>{item?.enddate}</td>
                        <td>
                          <span className="prodby">
                            {" "}
                            <a href={`/admin/support?id=${item.id}`}>
                              <FaEdit className="icons"></FaEdit>{" "}
                            </a>{" "}
                          </span>{" "}
                          {/* &nbsp;
                          <span className="proddate">
                            <FaTrash className="FaCalendar"></FaTrash>{" "}
                          </span> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="form" onSubmit={(event) => {
                      formData(event);
                    }}>
            <div className="form-group">
              <input className="form-control" type="text" name="supportTitle" />
            </div>

            <div className="form-group">
            <CKEditor
            id="editor"
              editor={ClassicEditor}
              // config={(config.height = "500")}
              onChange={(event, editor) => {
                  editorData.current = editor.getData();
                // console.log( { event, editor, data } );
              }}
              name="body"
            />
            </div>
            <div className="form-group">
<Button type="submit" variant="secondary">Send</Button>
</div>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          
        </Modal.Footer> */}
      </Modal>

      </Container>
    </div>
  );
};

export default AdminSupport;
