import React, { useEffect, useState,  } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../assets/css/front/style.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Button from "react-bootstrap/Button";
import { AiFillSignal } from "react-icons/ai";
import { AiOutlineWifi } from "react-icons/ai";
import { RiLiveLine } from "react-icons/ri";
import { GiCampfire } from "react-icons/gi";
import { EconomicCalendar } from "react-tradingview-embed";
import icon from "../../assets/images/logo/fav.jpg";
import {
  getPostFromFirebaseByCategory,
  getAbout,
  getSignalMembership,
  getLiveMembership,
  getOnlineMembership,
  getonePostFromFirebaseByCategory,
} from "../../server/firebase.config";
import Moment from 'react-moment';
import {Link} from "react-router-dom";

// const Header = React.lazy(() => import('./components/header'));
// const Footer = React.lazy(() => import('./components/footer'));
const Home = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [tech, setTech] = useState(null);
  const [crypto, setCrypto] = useState(null);
  const [about, setAbout] = useState(null);
  const [signals, setSignals] = useState(null);
  const [live, setLive] = useState(null);
  const [online, setOnline] = useState(null);
  const [time, setTime] = useState(null);

  const effect = useEffect(() => {
    const subscription = getonePostFromFirebaseByCategory(
      "technicals"
    ).subscribe((item) => {
      setTech(item);
    });

    const subs = getonePostFromFirebaseByCategory("techArt").subscribe(
      (item) => {
        setCrypto(item);
       
      }
    );

    const subscribe = getAbout().subscribe((item) => {
      setAbout(item);
    });

    const Signalsubscribe = getSignalMembership().subscribe((item) => {
      setSignals(item);
    });

    const Livesubscribe = getLiveMembership().subscribe((item) => {
      setLive(item);
    });

    const Onlinesubscribe = getOnlineMembership().subscribe((item) => {
      setOnline(item);
    });

    return () => {
      subscription.unsubscribe();
      subs.unsubscribe();
      subscribe.unsubscribe();
      Signalsubscribe.unsubscribe();
      Livesubscribe.unsubscribe();
      Onlinesubscribe.unsubscribe();
    };
  }, []);
  
  return (
    <div>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Header></Header>
      {/* </Suspense> */}

      <div className="clearfix"></div>

      <Container fluid>
        <Row>
          {tech?.length === 0 ? (
            <></>
          ) : (
            tech?.map((item) => (
              <Col xs="12" md="4">
                <Card className="main-carousel">
                  <img src={item?.mainImage?.imageUrl} />

                  {item?.hot === "yes" ? (
                    <div class="crypto-hot">
                      <GiCampfire></GiCampfire> &nbsp;Hot
                    </div>
                  ) : (
                    <> </>
                  )}

                  {item?.new === "yes" ? (
                    <div class="crypto-new">New</div>
                  ) : (
                    <> </>
                  )}

                  <Card.Body>
                    <Card.Title className="main-carousel_title">
                      {item?.title}
                    </Card.Title>
                    <Card.Text>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${item?.editorData.substring(0, "400")}...`,
                        }}
                      ></div>
                    </Card.Text>
                    <span>
                    <Moment format="D MMM YYYY" withTitle>{item?.timestamp}</Moment>
                    {/* <Moment fromNow>{item?.timestamp}</Moment> */}
                    
                      &nbsp;
                    </span>
                    <Link to={`/single?id=${item.id}`}>
                      <Button className="main-carousel_btn">Read More</Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}

          {crypto?.length === 0 ? (
            <div>No crypto news</div>
          ) : (
            crypto?.map((item) => (
              <Col xs="12" md="4">
                <Card className="main-carousel">
                  <img src={item?.mainImage?.imageUrl} />

                  {item?.hot === "yes" ? (
                    <div class="crypto-hot">
                      <GiCampfire></GiCampfire> &nbsp;Hot
                    </div>
                  ) : (
                    <> </>
                  )}

                  {item?.new === "yes" ? (
                    <div class="crypto-new">New</div>
                  ) : (
                    <> </>
                  )}

                  <Card.Body>
                    <Card.Title className="main-carousel_title">
                      {item?.title}
                    </Card.Title>
                    <Card.Text>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${item?.editorData.substring(0, "370")}....`,
                        }}
                      ></div>
                      {/* {item?.editorData.substring(0, "485")}... */}
                    </Card.Text>

                    <span>
                    <Moment format="D MMM YYYY" withTitle>{item?.timestamp}</Moment>
                      {/* {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(item?.timestamp)}{" "} */}
                      &nbsp;
                    </span>
                    
                    <Link to={`/single?id=${item.id}`}>
                      <Button className="main-carousel_btn">Read More</Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}

          <Col sm="12" md="4" className="marketView">
            <EconomicCalendar
              widgetProps={{
                width: "100%",
                theme: "light",
                style: "1",
                locale: "en",
                // isTransparent: true,
                displayMode: "adaptive",
                locale: "en",
              }}
            ></EconomicCalendar>
          </Col>
        </Row>
      </Container>

      <div className="clearfix"></div>

      <div className="about_main_page">
        <Container fluid>
          {about?.length === 0 ? (
            <></>
          ) : (
            about?.map((item) => (
              <Row>
                <Col xs="12" md="6" className="order-2 order-lg-1">
                  <div className="about_main_page_title">{item?.header1}</div>

                  <div className="about_main_page_desc">
                    <div
                      dangerouslySetInnerHTML={{ __html: `${item?.body1}` }}
                    ></div>
                  </div>
                </Col>

                <Col xs="12" md="6" className="order-1 order-lg-2">
                  <div className="about_main_page_video">
                    <iframe
                      src={item?.video1}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>

                    <div className="about_main_page_videoCover"></div>
                  </div>
                </Col>
              </Row>
            ))
          )}
        </Container>
      </div>

      <div className="clearfix"></div>

      <div className="price-table">
        <div className="price-table_m-title">Membership</div>

        {/* <div className="price-table_m-subtitle">
          Select an option from the price table
        </div> */}

        <div className="price-table_m-body">
          <p>Select an option from the price table</p>
        </div>

        <Container fluid>
          <Row>
            {/* {signals?.length === 0 ? (
              <></>
            ) : (
              signals?.map((item) => ( */}
                <Col sm="4" md="4">
                  <Card className="main-carousel membership-1">
                    <Card.Body>
                      <div className="membership-signals_title">
                        <AiFillSignal></AiFillSignal> SIGNALS
                      </div>

                      <div className="membership-signals_body">
                        <div
                          dangerouslySetInnerHTML={{ __html: `${signals?.body}` }}
                        ></div>
                      </div>

                      <div className="membership-signals_price">
                        <div className="membership-signals_price-ccc">$</div>
                        <div className="membership-signals_price-pp">
                          {signals?.price}
                        </div>
                        <div className="membership-signals_price-mm">
                          /{signals?.duration}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              {/* ))
            )} */}

           
                <Col sm="4" md="4">
                  <Card className="main-carousel membership-2">
                    <Card.Body>
                      <div className="membership-course_title">
                        <AiOutlineWifi></AiOutlineWifi> ONLINE COURSE
                      </div>

                      <div className="membership-course_body">
                        <div
                          dangerouslySetInnerHTML={{ __html: `${online?.body}` }}
                        ></div>
                      </div>

                      <div className="membership-course_price">
                        <div className="membership-course_price-ccc">$</div>
                        <div className="membership-course_price-pp">
                          {online?.price}
                        </div>
                        <div className="membership-course_price-mm">
                          /{online?.duration}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
             

              <Col sm="4" md="4">
                <Card className="main-carousel membership-3">
                  <Card.Body>
                    <div className="membership-live_title">
                      <RiLiveLine></RiLiveLine> Live Course
                    </div>

                    <div className="membership-live_body">
                      <div
                        dangerouslySetInnerHTML={{ __html: `${live?.body}` }}
                      ></div>
                    </div>

                    <div className="membership-live_price">
                      <div className="membership-live_price-ccc">$</div>
                      <div className="membership-live_price-pp">
                        {live?.price}
                      </div>
                      <div className="membership-live_price-mm">
                        /{live?.duration}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
          </Row>
        </Container>
      </div>

      <div className="about_main_page2">
        <Container fluid>
          {about?.map((item) => (
            <Row>
              <Col sm="12" md="6">
                <Row>
                  <Col sm="6" md="6" className="about2_img">
                    <img src={item?.img1} />
                  </Col>

                  <Col sm="6" md="6" className="about2_img1">
                    <img src={item?.img2} />
                  </Col>

                  <Col sm="6" md="6" className="about2_img">
                    <img src={item?.img3} />
                  </Col>

                  <Col sm="6" md="6" className="about2_img1">
                    <img src={item?.img4} />
                  </Col>
                </Row>
              </Col>

              <Col xs="12" md="6">
                <div className="about_main_page2-title">{item?.header2}</div>

                <div className="about_main_page2-subtittle">
                  {item?.subHead}
                </div>

                <div className="about_main_page2-body">
                  <div
                    dangerouslySetInnerHTML={{ __html: `${item?.body2}` }}
                  ></div>
                </div>
              </Col>
            </Row>
          ))}
        </Container>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Home;
