import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../assets/css/front/style.css";
import logo1 from "../../assets/images/logo/logo1.png";
import Header from "../front/components/header";
import Footer from "../front/components/footer";
import { Button } from "react-bootstrap";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import { signInUsers, lastlogged } from "../../server/firebase.config";
import { fireAuthState } from "../../server/firebase.config";
import { firstValueFrom } from "rxjs";
const Auth = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  // const history = useHistory();

  const signin = async (event) => {
  try {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    // console.log(valueFields);

    // return
    await   signInUsers(valueFields.email, valueFields.password);
    document.getElementById("form").reset();
    const user = await  firstValueFrom(fireAuthState);
    await lastlogged(user.uid);

    window.location.href = "/dashboard";

    // if (item?.admin == 'yes'){
    //   window.location.href = "/bl4ck444/dashboard";
    // }
    // else{
      
    // }
    
      

  }catch(error){
    console.log(error);
    if (error.code === "auth/wrong-email") {
      alert("Invalid Email");
    }
    if (error.code === "auth/wrong-password") {
      alert("Invalid Password");
    }
    if (error.code === "auth/user-not-found") {
      alert("User Not found");
    }
  }
  };

  const blurEvent = (event, amount, textContent) => {
    // alert('i bluredd')

    const formParent = event.target.parentNode;
    const errorSpan = formParent.querySelector(".error");
    // console.log(event.target.value)
    if (event.target.value.length < amount) {
      errorSpan.textContent = textContent;
      errorSpan.style = "display: block";
      formParent.classList.add("hasError");
    } else {
      errorSpan.textContent = "";
      errorSpan.style = "display: none";
      formParent.classList.remove("hasError");
    }
  };

  return (
    <div className="authbody">
      {/* <Header></Header> */}

      <div className="clearfix"></div>

      <Container fluid>
        <div className="login_auth" style={{ marginTop: "90px" }}>
          <div className="auth_header">
            <img src={logo1} />
          </div>

          {/* <div className="auth_btns">
          <div className="auth_btns_title">Sign in using</div>

          <div style={{ display: "flex" }}>
            <Button className="auth_btns_btn1">
              <FaGoogle></FaGoogle> &nbsp; Google
            </Button>

            <Button className="auth_btns_btn2">
              <FaFacebook></FaFacebook> &nbsp; Facebook
            </Button>
          </div>
        </div>

        <div className="line_spacer">
          <div className="line_spacer_line_1"></div>

          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>Or</div>

          <div className="line_spacer_line_2"></div>
        </div> */}

          <br></br>

          <form id="form" onSubmit={signin}>
            <div className="form-group">
              <label for="uname"> Email *</label>
              <input
                className="form-control"
                type="email"
                id="uname"
                name="email"
                onBlur={(event) => {
                  blurEvent(event, 6, "Please type your email address");
                }}
              ></input>

              <span className="error"></span>
            </div>

            <div className="form-group">
              <label for="pass">Password *</label>
              <input
                className="form-control"
                type="password"
                id="pass"
                name="password"
                onBlur={(event) => {
                  blurEvent(event, 6, "Please type your Password");
                }}
              ></input>

              <span className="error"></span>
            </div>

            <Button type="submit" className="auth_form_submit">
              Sign In
            </Button>
          </form>

          <div style={{ textAlign: "center" }}>
            Not a member? <a href="/signup">SignUp</a>
            <p>
              Forgot Login details? <a href="/forgot">Recover Here</a>
            </p>
          </div>
        </div>
      </Container>

      {/* <Footer></Footer> */}
    </div>
  );
};

export default Auth;
