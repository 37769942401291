import React, { useEffect, useState } from "react";
import "../../assets/css/front/style.css";
import "../../assets/css/front/news.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {EconomicCalendar} from "react-tradingview-embed";
import {GiCampfire} from "react-icons/gi";
import {getPostFromFirebaseByCategory} from "../../server/firebase.config";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
const News = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->


  const [news, setNews] = useState(null);

  const effect = useEffect(() => {

    const subscription = getPostFromFirebaseByCategory("market").subscribe(
      (item) => {
        setNews(item);
      }
    );

    return () => {
      subscription.unsubscribe();
    };

  }, []);

  return (
    <>
      <Header></Header>

      <div className="clearfix"></div>

      <div className="news">
        <div className="news_head">
          <div className="news_head_title"> Market News</div>

          <div className="news_head_subtitle">
            Your insights to keep you afloat
          </div>
        </div>
        <Container fluid>
          <Row>
            <Col xs="12" md="12">
              <Row>

              {news?.length === 0? (<></>) : news?.map( (item) => (
                <Col sm="12" md="12" className="news_post_main">
                  <Row>
                    <Col sm="12" md="2" className="news_post_main_img">
                    <img src={item?.mainImage?.imageUrl} />

                    </Col>

                    <Col sm="12" md="6" className="news_post_main2">
                      <div className="news_post_main_title">
                        {" "}
                        {item?.title}
                      </div>

                      <div className="news_post_main2_bottom">
                        <div className="news_post_main_by">
                         <div dangerouslySetInnerHTML={{ __html: `${item?.editorData.substring(0, "100")}...` }}></div>

                         <div>
                      {/* {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(item?.timestamp)}{" "} */}
                      <Moment format="D MMM YYYY" interval={10000} withTitle>{item?.timestamp}</Moment>
                      &nbsp;
                    </div>
                        
                        </div>

                      </div>
                    </Col>

                    <Col xs="12" md="4" className="news_post_main_btn">
                      
                      <Link to={`/single?id=${item.id}`}>
                        <div className="news_post_main_btn-btn">Read More</div>
                      </Link>
                    </Col>
                  </Row>
                </Col>

))}

              </Row>
            </Col>

            {/* <Col xs="12" md="5">
            <EconomicCalendar widgetProps={{
              "theme": "light",
              "style": "1",
              "locale": "en",
              isTransparent: true,
            displayMode: "adaptive",
            locale: "en",
            }}
            >

            </EconomicCalendar>
            </Col> */}
          </Row>
        </Container>

        <div className="more-news">
          <div className="more-news_btn">Load More</div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default News;
