import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import {FaEdit, FaTrash} from 'react-icons/fa';
import Table  from "react-bootstrap/Table";
import {getUsers, deleteUser} from '../../../../server/firebase.config';

const ViewUsers = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [users, setUsers] = useState(null);



  const effect = useEffect(() => {

    
    const subscription = getUsers().subscribe(item => {
      setUsers(item)
    })
     

      return () => {
        subscription.unsubscribe()
      }


     

  }, []);
 


  return (
    <div>

        <Container fluid>
            <Row>
                <Col md="2" className="sidebar-col">
                    <Sidebar></Sidebar>
                </Col>
                <Col sm="12" md="10" className="mainbody">
                    <Header></Header>

<div className="clearfix"></div>
                    <div className="mb">

                        <Container fluid >
                            <Row>
                                <Col sm="12" md="12" className="livechart" style={{background:"white"}}>

                                <Table striped bordered hover style={{marginTop:"10px"}}>
  <thead>
    <tr>
      <th>#</th>
      <th>User Name</th>
      <th>Email</th>
      <th>Subscription Type</th>
      <th>Reg Date</th>
      <th>Last Online</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>

    {users?.map( (item, index) => 
    <tr key={index}>
      <td>1</td>
      <td>{item?.username}</td>
      <td>{item?.email}</td>
      <td>{item?.subscription}</td>
      <td>{item?.regDate} </td>
      <td>{item?.lastOnline}</td>
      <td>
      <span className="prodby"> <a href={`/bl4ck444/edit-users?id=${item.id}`}><FaEdit className="icons"></FaEdit> </a> </span> &nbsp;
      <span className="proddate" onClick={()=> {deleteUser(item.id, item.userId)} }><FaTrash className="FaCalendar"></FaTrash> </span>
      </td>
    </tr>
    )}
  </tbody>
</Table>
                              
                                </Col>
                                </Row>
                        </Container>

                                

                    </div>

                    
                </Col>
            </Row>

        </Container>
      

      
    </div>
  );
};

export default ViewUsers;
