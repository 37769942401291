import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { config } from "rxjs";
import {
  getOnePostFromFirebase,
  editAboutSettings,
  getAboutSettings,
  getimageFromFirebase,
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import queryString from "query-string";
import Toast from "react-bootstrap/Toast";

const AboutSettings = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [showToast, setshowToast] = useState(false);

  const img1 = useRef(null);
  const img2 = useRef(null);
  const img3 = useRef(null);
  const img4 = useRef(null);
  const body1 = useRef("");
  const body2 = useRef("");


  const [Image, setImages] = useState(null);
  const [showimg1, setShowimg1] = useState(false);
  const [showimg2, setShowimg2] = useState(false);
  const [showimg3, setShowimg3] = useState(false);
  const [showimg4, setShowimg4] = useState(false);

  const [about, setAbout] = useState(null);

  

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];
  // console.log(realId);

  const effect = useEffect(() => {
    firstValueFrom(getimageFromFirebase())
    .then((item) => {
      // console.log(item);
      setImages(item);
    })
    .catch((error) => {
      console.log(error);
    });


    firstValueFrom(getAboutSettings())
      .then((item) => {
        //   console.log(item)
        setAbout(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    // console.log(file.current);

    if (img1.current) {
      valueFields = {
        ...valueFields,
        img1: img1.current,
      };
    }

    if (img2.current) {
      valueFields = {
        ...valueFields,
        img2: img2.current,
      };
    }


    if (img3.current) {
      valueFields = {
        ...valueFields,
        img3: img3.current,
      };
    }


    if (img4.current) {
      valueFields = {
        ...valueFields,
        img4: img4.current,
      };
    }

    valueFields = {...valueFields,  body1: body1.current}
    valueFields = {...valueFields,  body2: body2.current}

    // console.log(valueFields);

    // return

    editAboutSettings( valueFields);
    alert("Content Updated");
  };

  const selectImage1 = (event) => {
    // console.log({ event });
    img1.current = event;
    setShowimg1(true)
    setShowimg2(false)
    setShowimg3(false)
    setShowimg4(false)
    handleClose();
    
  };

  const selectImage2 = (event) => {
    // console.log({ event });
    img2.current = event;
    
    setShowimg1(false)
    setShowimg2(true)
    setShowimg3(false)
    setShowimg4(false)
    handleClose();
  };

  const selectImage3 = (event) => {
    // console.log({ event });
    img3.current = event;
    handleClose();
    setShowimg1(false)
    setShowimg2(false)
    setShowimg3(true)
    setShowimg4(false)
  };

  const selectImage4 = (event) => {
    // console.log({ event });
    img4.current = event;
    handleClose();
    setShowimg1(false)
    setShowimg2(false)
    setShowimg3(false)
    setShowimg4(true)
  };

  return (
    <div>
      <Container fluid>

      <Toast
        onClose={() => setshowToast(false)}
        show={showToast}
        delay={3000}
        className="toast"
        autohide
      >
        <Toast.Body className="success">Post Added</Toast.Body>
      </Toast>

      <div className="clearfix"></div>
      {/* <div className="mb"> */}
      <Container fluid>
        <Row>
          <Col sm="12" md="12" className="livechart">
              {about?.map((item) => 
            <Form
              className="add-form"
              id="form"
              onSubmit={(event) => {
                formData(event);
              }}
            >


                    
              <div className="form-group">
                <label for="ptitle">Header 1</label>
                <input
                  type="text"
                  id="ptitle"
                  className="form-control"
                  defaultValue={item?.header1}
                  name="header1"
                />
              </div>



              <div className="form-group">
                <label for="ptitle">Header 2</label>
                <input
                  type="text"
                  id="ptitle"
                  className="form-control"
                  defaultValue={item?.header2}
                  name="header2"
                />
              </div>

              <div className="form-group">
                <label for="ptitle">Sub Head 2</label>
                <input
                  type="text"
                  id="ptitle"
                  className="form-control"
                  defaultValue={item?.subHead}
                  name="subHead"
                />
              </div>


              <div className="form-group">
                <label for="ptitle">Video</label>
                <input
                  type="text"
                  id="ptitle"
                  className="form-control"
                  defaultValue={item?.video1}
                  name="video1"
                />
              </div>

              <div className="form-group">
                
                <Row>
                  <Col sm="12" md="3">
                    <img
                      src={item?.img1}
                      style={{ height: "200px", width: "200px" }}
                      onClick={handleShow}
                    />
                  </Col>
                  <Col sm="12" md="3">
                    <img
                      src={item?.img2}
                      style={{ height: "200px", width: "200px" }}
                      onClick={handleShow}
                    />
                  </Col>
                  <Col sm="12" md="3">
                    <img
                      src={item?.img3}
                      style={{ height: "200px", width: "200px" }}
                      onClick={handleShow}
                    />
                  </Col>
                  <Col sm="12" md="3">
                    <img
                      src={item?.img4}
                      style={{ height: "200px", width: "200px" }}
                    />
                  </Col>
                </Row>
              </div>

              

              <div className="form-group">
                <label for="body">Body 1</label>
                <CKEditor
                  data={item?.body1}
                  editor={ClassicEditor}
                  config={(config.height = "500")}
                  onChange={(event, editor) => {
                    body1.current = editor.getData();
                    // console.log( { event, editor, data } );
                  }}
                  name="body"
                />
              </div>

              <div className="form-group">
                <label for="body">Body 2</label>
                <CKEditor
                  data={item?.body2}
                  editor={ClassicEditor}
                  config={(config.height = "500")}
                  onChange={(event, editor) => {
                    body2.current = editor.getData();
                    // console.log(  editorData.current  );
                  }}
                  name="body"
                />
              </div>

              

              <div className="form-group">
                <Button type="submit" variant="outline-dark">
                  Publish
                </Button>
              </div>
            </Form>
            )}
          </Col>
        </Row>
      </Container>
      {/* </div> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {Image?.map((item) => (
                <Col sm="12" md="4">
                  {showimg1?
                  <Card
                  
                    onClick={() => {
                      selectImage1(item);
                    }}
                  
                    className="imgSelection-li"
                  >
                    image 1
                    <label class="img-label" for={`cb${item.imageUrl}`}>
                      <Card.Img
                        variant="top"
                        className=""
                        src={item.imageUrl}
                      />
                    </label>
                  </Card>
                  : null
                  }

{showimg2?
                  <Card
                  
                    onClick={() => {
                      selectImage2(item);
                    }}
                  
                    className="imgSelection-li"
                  >

                    image 2
                    <label class="img-label" for={`cb${item.imageUrl}`}>
                      <Card.Img
                        variant="top"
                        className=""
                        src={item.imageUrl}
                      />
                    </label>
                  </Card>
                  : null
                  }
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      </Container>
    </div>
  );
};

export default AboutSettings;
