import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../assets/css/front/style.css";
import logo1 from "../../assets/images/logo/logo1.png";
import {Link} from "react-router-dom";
import Footer from "../front/components/footer";
import { Button } from "react-bootstrap";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import { signInUsers, lastlogged } from "../../server/firebase.config";
import { fireAuthState, getOneUsers, editUser } from "../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import queryString from "query-string";
import { AiOutlineVerified } from "react-icons/ai";
const EmailConfirmed = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  // const history = useHistory();
  const [users, setUsers] = useState(null);


  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];

  const effect = useEffect(() => {
    firstValueFrom(getOneUsers(realId))
      .then((item) => {
        // console.log(item);
        setUsers(item);

       
        
    // alert('User Edited')
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if(realId){
    let valueFields = {};

    valueFields = { ...valueFields, verified: 'yes' };

    editUser(realId, valueFields);
  }
  

 

   
    
    // document.getElementById("form").reset();
  


 


  return (
    <div className="authbodys">
<div className="clearfix"></div>
<Container fluid>
        <div className="login_auth" style={{ marginTop: "90px" }}>
          <div className="auth_header">
            <img src={logo1} />
          </div>

<div className="confimE">

  Hi {users?.username}, your account has been confirmed.
  <p>Procced to <Link to="/auth"> <Button variant="danger"> Sign in </Button> </Link> </p>

</div>
        </div>
      </Container>
    </div>
  );
};

export default EmailConfirmed;
