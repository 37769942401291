import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/styleNew.css";
import oc from "../../assets/images/dashboard/oc.png";
import { Button } from "react-bootstrap";
import { AdvancedChart } from "react-tradingview-embed";
import video from "../../assets/images/dashboard/vid.png";
import { getPostFromFirebaseByCategory } from "../../server/firebase.config";

const Techart = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [news, setNews] = useState(null);

  const effect = useEffect(() => {
    const subscription = getPostFromFirebaseByCategory("techArt").subscribe(
      (item) => {
        setNews(item);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <br />
            <br />
            <Container className="mb">
              <Row>

              {news?.length === 0 ? (
                    <></>
                  ) : (
                    news?.map((item) => (
                <Col sm="12" md="10" className="techart">
                  <Row>
                    <Col sm="2" md="2" className="techart_img">
                      <img src={item?.mainImage?.imageUrl} />
                    </Col>
                    <Col sm="7" md="7" className="techart_body">
                      <div className="ta_title">
                      {item?.title}
                      </div>

                      <div className="ta_details">
                      <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${item?.editorData.substring(
                                      0,
                                      "100"
                                    )}...`,
                                  }}
                                ></div>
                        &nbsp; &nbsp;
                        <div>Oct 21 . 5 min read </div>
                      </div>
                    </Col>
                    <Col sm="3" md="3">
                    <a href={`/view-post?id=${item.id}`}>
                    <Button className="techart_btn">Read More</Button>
                            </a>
                      
                    </Col>
                  </Row>
                </Col>
                ))
                )}


              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Techart;
