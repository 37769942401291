import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import user from "../../assets/images/icons/uss.png";
import { getLivecourse } from "../../server/firebase.config";

const NFT = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [live, setLive] = useState(null);



  const effect = useEffect(() => {

    
    const subscription = getLivecourse().subscribe(item => {
        setLive(item)
    })
     

      return () => {
        subscription.unsubscribe()
      }


     

  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="mb">

            {live?.length === 0? (<div className="noLive">No NFT's available</div>) : live?.map( (item) => (
            <Row>
              <Col sm="12" md="10" className="live">
                <iframe
                  src={item?.url}
                  title="Obsidian Live Course"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  autoplay
                  allowfullscreen
                ></iframe>
              </Col>

              <Col sm="12" md="7" className="live-chat">
                <div className="live-chat_tittle">
                  Chat
                  <span>Participant</span>
                </div>

                <div className="live-chat_body">
                  <div className="live-chat_body_chats">
                    <div className="live-chat_body_picture">
                      <div>
                        <img src={user} />
                      </div>
                    </div>

                    <div className="live-chat_body_user">
                      <div>DRock</div>
                    </div>

                    <div className="live-chat_body_chat">
                      <div>TREY WILL BE ON BLOOMBERG at 12</div>
                    </div>
                  </div>

                  <div className="live-chat_body_chats">
                    <div className="live-chat_body_picture">
                      <div>
                        <img src={user} />
                      </div>
                    </div>

                    <div className="live-chat_body_user">
                      <div>DRock</div>
                    </div>

                    <div className="live-chat_body_chat">
                      <div>TREY WILL BE ON BLOOMBERG at 12</div>
                    </div>
                  </div>

                  <div className="live-chat_body_chats">
                    <div className="live-chat_body_picture">
                      <div>
                        <img src={user} />
                      </div>
                    </div>

                    <div className="live-chat_body_user">
                      <div>DRock</div>
                    </div>

                    <div className="live-chat_body_chat">
                      <div>TREY WILL BE ON BLOOMBERG at 12</div>
                    </div>
                  </div>

                  <div className="live-chat_body_chats">
                    <div className="live-chat_body_picture">
                      <div>
                        <img src={user} />
                      </div>
                    </div>

                    <div className="live-chat_body_user">
                      <div>DRock</div>
                    </div>

                    <div className="live-chat_body_chat">
                      <div>TREY WILL BE ON BLOOMBERG at 12</div>
                    </div>
                  </div>

                  <div className="live-chat_body_chats">
                    <div className="live-chat_body_picture">
                      <div>
                        <img src={user} />
                      </div>
                    </div>

                    <div className="live-chat_body_user">
                      <div>DRock</div>
                    </div>

                    <div className="live-chat_body_chat">
                      <div>TREY WILL BE ON BLOOMBERG at 12</div>
                    </div>
                  </div>
                </div>

                <form className="chat-input">
                  <div className="form-group">
                    <textarea name=""></textarea>
                  </div>
                </form>
              </Col>
            </Row>
            
            ))}
            </div>
          </Col>
          
        </Row>
        
        
      </Container>
    </div>
  );
};

export default NFT;
