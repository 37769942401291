import React, { useEffect, useState } from "react";
import "../../../assets/css/front/style.css";
import { BiSearchAlt2 } from "react-icons/bi";
import {
  FaTelegram,
  FaInstagram,
  FaShoppingCart,
  FaTwitter,
  FaUserAlt,
  FaYoutube,
} from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMenu } from "react-icons/hi";
import { BiUserCircle } from "react-icons/bi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import logo1 from "../../../assets/images/logo/logo1.png";
import { fireAuthState, getSettings } from "../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import {
  
  Link
} from "react-router-dom";
const Header = () => {
  const [User, setUser] = useState(null);
  const [authState, setAuthState] = useState(false);
  const [socials, setSocials] = useState(null);

  const [show, setShow] = useState(false);

  const openMenu = () => {
    setShow(true)
  }

  const closeMenu = () => {
    setShow(false)
  }

  const effect = useEffect(() => {
    firstValueFrom(fireAuthState)
      .then((user) => {
        if (user) {
          // console.log(user);
          setUser(user);
          setAuthState(true);
        } else {
          setUser(false);
        }
      })

      .catch((error) => {
        window.location.href = "/auth";
        // console.log(error);
      });

    firstValueFrom(getSettings())
      .then((item) => {
        //   console.log(item)
        setSocials(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="top-head">
        <div className="top-head_title">Welcome to Obsidian Fx</div>

        <div className="top-head_right_social">
          {socials?.map((item) => (
            <ul>
              <li>
                {" "}
                <a href={item?.telegram} target="_blank">
                  <FaTelegram></FaTelegram>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href={item?.twitter} target="_blank">
                  <FaTwitter></FaTwitter>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href={item?.instagram} target="_blank">
                  <FaInstagram></FaInstagram>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href={item?.youtube} target="_blank">
                  <FaYoutube></FaYoutube>{" "}
                </a>
              </li>
            </ul>
          ))}
        </div>

        <div className="top-head_right_cart">
          <ul>
            <li>
              {" "}
              <FaShoppingCart></FaShoppingCart>
            </li>
          </ul>
        </div>
      </div>
      {/* <header className="navbar"> */}
      <Container fluid  className="head">
        <Row >
          {/* <Col className="col1"> */}
          <Col sm="4" md="2" className="logo">
            {/* <img src="" /> */}

            <Link to="/home">
              <div>
                <img src={logo1} />
              </div>
              </Link>
          </Col>

          {/* <Col className="col2"> */}
          <Col sm="7" md="6" className=" menu">
            <ul>
              <li>
                {" "}

                <Link to="/technicals">Technicals</Link>
                {/* <a href="/technicals">Technicals</a>{" "} */}
              </li>
              <li>
                {" "}
                <Link to="/market-news">Market News</Link>
                {/* <a href="/market-news">Market News</a>{" "} */}
              </li>
              <li>
                {" "}
                <Link to="/technical-articles">Technical Articles</Link>
                {/* <a href="/technical-articles">Technical Articles</a>{" "} */}
              </li>
              <li>
                {" "}
                <Link to="/crypto">Cryptocurrency</Link>
                {/* <a href="/crypto">Cryptocurrency</a>{" "} */}
              </li>
              <li>
                {" "}
                <Link to="/store">Store</Link>
                {/* <a href="/store">Store </a>{" "} */}
              </li>
            </ul>
          </Col>

          {/* <Col className="col3"> */}

          {authState === true ? (
            <Col sm="3" md="3" className=" menu-1">
              <Link to="/dashboard">
                <div className=" my-dash">
                  <BiUserCircle></BiUserCircle> Dashboard
                  {/* {User?.displayName} */}
                </div>
              </Link>
            </Col>
          ) : (
            <Col sm="3" md="3" className=" menu-1">
              <Link to="/auth">
                <Button className="signin-btn">Login</Button>
              </Link>

              <Link to="/signup">
                <Button className="signup-btn">Get Started</Button>
              </Link>
            </Col>
          )}
        </Row>
      </Container>

      <div fluids className="m_menu_head">
        <div sm="4" className="logo">
          {/* <img src="" /> */}

          <Link to="/home">
            <div>
              <img src={logo1} />
            </div>
          </Link>
        </div>

        {authState === true ? (
          <div sm="8" className="m-menu">
            <div className="m-menu_menulist">
              <div className="m-menu_icons">
                <HiOutlineMenu onClick={openMenu}></HiOutlineMenu>
              </div>
              {show ? 
              <div className="m-menu_tab">

                <div className="close">
                  <AiOutlineClose onClick={closeMenu}></AiOutlineClose>
                  </div>

                <div className="m-menu_tab_ul">

                  <div className="m-menu_tab_li"><Link to="/technicals"> Technicals </Link></div>
                  <div className="m-menu_tab_li"><Link to="/market-news"> Market News </Link></div>
                  <div className="m-menu_tab_li"><Link to="/technical-articles"> Technical Articles </Link></div>
                  <div className="m-menu_tab_li"><Link to="crypto"> Cryptocurrency </Link></div>
                  <div className="m-menu_tab_li"><Link to="/store"> Store </Link></div>

                </div>
                </div>
                : null}
            </div>
            

            <Link to="/dashboard">
              <div className="m-menu_icons">
                <BiUserCircle></BiUserCircle>
                {/* {User?.displayName} */}
              </div>
            </Link>
          </div>
        ) : (
          <div sm="8" className="m-menu">
            <ul>
              <li>
                {" "}
                <Link to="/auth">Login</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/signup">Join</Link>{" "}
              </li>
            </ul>
          </div>
        )}
      </div>
      {/* </header> */}
    </>
  );
};

export default Header;
