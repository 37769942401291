import React, { useEffect, useState } from "react";
import "../../assets/css/front/style.css";
import "../../assets/css/front/crypto.css";
import Header from "./components/header";
import Footer from "./components/footer";
import  Container from "react-bootstrap/Container";
import  Row from "react-bootstrap/Row";
import  Col from "react-bootstrap/Col";
import  Card from "react-bootstrap/Card";
import  Button from "react-bootstrap/Button";
import {GiCampfire} from "react-icons/gi";
import {getPostFromFirebaseByCategory} from "../../server/firebase.config";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
const Technicals = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->



  const [tech, setTech] = useState(null);

  const effect = useEffect(() => {

    const subscription = getPostFromFirebaseByCategory("technicals").subscribe(
      (item) => {
        setTech(item);
      }
    );

    return () => {
      subscription.unsubscribe();
    };

  }, []);

  return (
    <>
      <Header></Header>

      <div className="clearfix"></div>

      <div className="crypto">

      <div className="crypto_head">
          <div className="crypto_head_title">&nbsp;Technicals</div>

          <div className="crypto_head_subtitle">
            Your insights to keep you afloat
          </div>
        </div>

          <Container fluid className="crypto_posts">
              <Row >

              {tech?.length === 0? (<></>) : tech?.map( (item) => (
          <Col xs="12" md="3" >
            <Card className="main-carousel">
              <img src={item?.mainImage?.imageUrl} />

{ item?.hot==='yes'? 

<div class="crypto-hot"><GiCampfire></GiCampfire> &nbsp;Hot</div>
:
<> </>

}

{ item?.new==='yes'? 
<div class="crypto-new">New</div>
:
<> </>

}

              <Card.Body>
                <Card.Title className="main-carousel_title">
                  {item?.title}
                </Card.Title>
                <Card.Text>
                <div dangerouslySetInnerHTML={{ __html: `${item?.editorData.substring(0, "100")}...` }}></div>

                <div>
                      {/* {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(item?.timestamp)}{" "}
                      &nbsp; */}

<Moment format="D MMM YYYY" interval={10000} withTitle>{item?.timestamp}</Moment>
                    </div>
                {/* {
                     item?.editorData.substring(0, '30')
                  } ... */}
                </Card.Text>
                
                <Link to={`/single?id=${item.id}`}>
                <Button className="main-carousel_btn">Read More</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}


                

                  
              </Row>
          </Container>

          <div className="more-news">

            <div className="more-news_btn">
                Load More
            </div>

        </div>
        
      </div>


      <Footer></Footer>
    </>
  );
};

export default Technicals;
