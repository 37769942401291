import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { config } from "rxjs";
import {
  registerEmailandPassword,
  updateDetailsafterSignup,
} from "../../../../server/firebase.config";
const AddUsers = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const registerUser = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    // console.log(valueFields);

    // return
    registerEmailandPassword(valueFields.email, valueFields.password)
      // .then((user) => {
      //     return sendEmailVerification();
      //   })
      .then((user) => {
        user.user.sendEmailVerification();
         user.user.updateProfile({
          displayName: `${valueFields.username}`,
        });

         updateDetailsafterSignup(
          user.user.uid,
          user.user.firstname,
          user.user.lastname,
          valueFields.email,
          valueFields.username,
          valueFields.subscription
        );
      })


      .catch((error) => {
        console.log(error);
      });

    document.getElementById("form").reset();
    alert("User Created");
  };

  // try {

  //   const user = await registerEmailandPassword(valueFields.email, valueFields.password)
  //   await user.user.sendEmailVerification()
  //   await user.user.updateProfile({displayName: `${valueFields.cname} ${valueFields.cnip}` })
  // await updateDetailsafterSignup(user.user.uid, valueFields.cname, valueFields.cnip, valueFields.phone )
  // outlet('home')
  // } catch (error) {
  //   console.error(error)
  // }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <div className="mb">
              <Container fluid>
                <Row>
                  <Col sm="12" md="12" className="livechart">
                    <Form
                      className="add-form"
                      id="form"
                      onSubmit={(event) => {
                        registerUser(event);
                      }}
                    >
                      <div className="form-group">
                        <label for="ptitle">Firstname</label>
                        <input
                          type="text"
                          id="ptitle"
                          className="form-control"
                          name="firstname"
                        />
                      </div>
                      <div className="form-group">
                        <label for="ptitle">Lastname</label>
                        <input
                          type="text"
                          id="ptitle"
                          className="form-control"
                          name="lastname"
                        />
                      </div>
                      <div className="form-group">
                        <label for="ptitle">Username</label>
                        <input
                          type="text"
                          id="ptitle"
                          className="form-control"
                          name="username"
                        />
                      </div>

                      <div className="form-group">
                        <label for="ptitle">Email</label>
                        <input
                          type="email"
                          id="ptitle"
                          className="form-control"
                          name="email"
                        />
                      </div>

                      <div className="form-group">
                        <label for="ptitle">Password</label>
                        <input
                          type="password"
                          id="ptitle"
                          className="form-control"
                          name="password"
                        />
                      </div>

                      <div className="form-group">
                        <label for="cat">Subscription</label>
                        <select
                          id="cat"
                          className="form-control"
                          name="subscription"
                        >
                          <option value="none">None</option>
                          <option value="online">Online Course</option>
                          <option value="live">Live course</option>
                          <option value="signals">Signals</option>
                          <option value="all">All</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <Button type="submit" variant="outline-dark">
                          Add User
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddUsers;
