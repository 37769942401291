import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/styleNew.css";
import { AdvancedChart } from "react-tradingview-embed";
import video from "../../assets/images/dashboard/vid.png";
import { getVideos } from "../../server/firebase.config";

const Videos = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [technicals, setTechnicals] = useState(null);

  const effect = useEffect(() => {
    const subscription = getVideos().subscribe((item) => {
      setTechnicals(item);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <div className="mb">
              
              {/* <h3 className="hero_title">Weekly BreakDowns</h3> */}

<br />
<br />
              <Row>

              {technicals?.length === 0 ? (
                    <></>
                  ) : (
                    technicals?.map((item) => (
                <Col sm="12" md="3" className="video_card">
                   <Card className="crypto_card">
                          <iframe
                            src={item?.video}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <Card.Body className="crypto_cardBody">
                            <p className="prodTitle">{item.title}</p>{" "}
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  )}

                

                
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Videos;
