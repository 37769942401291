import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../assets/css/front/style.css";
import "../../assets/css/payment.css";
import "../../assets/css/front/checkout.css";
import logo1 from "../../assets/images/logo/logo1.png";
import { Button } from "react-bootstrap";
import {
  FaCcAmex,
  FaCcMastercard,
  FaCcPaypal,
  FaCcVisa,
  FaFacebook,
} from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import {
  registerEmailandPassword,
  updateDetailsafterSignup,
  createCustomer,
} from "../../server/firebase.config";





const SignUp = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // const history = useHistory();

  

   const  register = async (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    // console.log(valueFields);

    // return

    if (valueFields.pass2 === valueFields.pass1) {
      const password = valueFields.pass2;
      const subscription = "none";
      
      try {
        setLoading(true)
        const user = await registerEmailandPassword(
          valueFields.email,
          password
        );
        // await user.user.sendEmailVerification();
        await user.user.updateProfile({
          displayName: `${valueFields.username}`,
        });
        await updateDetailsafterSignup(
          user.user.uid,
          valueFields.firstname,
          valueFields.lastname,
          valueFields.email,
          valueFields.username,
          subscription
        );
        const customer = await createCustomer({
          userid : user.user.uid,
          name: `${valueFields.firstname} ${valueFields.lastname}` ,
          description: subscription
        })
        document.getElementById("form").reset();
        alert("registered successfully");
        alert("Check your email for confirmation link ");
        window.location.href = "/auth";
      } catch (error) {
        console.error(error);
        if (error.code === "auth/email-already-exists") {
          alert("Email Exist Already");
        }
      }

      // registerEmailandPassword(valueFields.email, password)
      // .then((user) => {
      //   user.user.sendEmailVerification();
      //    user.user.updateProfile({
      //     displayName: `${valueFields.username}`,
      //   });

      //    updateDetailsafterSignup(
      //     user.user.uid,
      //     user.user.firstname,
      //     user.user.lastname,
      //     valueFields.email,
      //     valueFields.username,
      //     subscription
      //   );
      // })

      // .catch((error) => {
      //   console.log(error);
      // });

      // // Add Toast Here and clear form
      // document.getElementById("form").reset();
      // alert("registered successfully");
      // alert("Check your email for confirmation link ");
    } else {
      alert("Passwords not the same");
    }
  };

  const blurEvent = (event, amount, textContent) => {
    // alert('i bluredd')

    const formParent = event.target.parentNode;
    const errorSpan = formParent.querySelector(".error");
    // console.log(event.target.value)
    if (event.target.value.length < amount) {
      errorSpan.textContent = textContent;
      errorSpan.style = "display: block";
      formParent.classList.add("hasError");
    } else {
      errorSpan.textContent = "";
      errorSpan.style = "display: none";
      formParent.classList.remove("hasError");
    }

    if (event.target.value.length < amount) {
      errorSpan.textContent = textContent;
      errorSpan.style = "display: block";
      formParent.classList.add("hasError");
    } else {
      errorSpan.textContent = "";
      errorSpan.style = "display: none";
      formParent.classList.remove("hasError");
    }
  };

  // const loadingPage = () =>{
    
  //   {setTimeout(setLoading(true), 1000)}
  // }


  
  return (
    <>


{/* {loading?
<>
<p>test</p>
</>
:

<>
<p>Test 2</p>

</>

} */}

{loading?
  <div class="loading">
	<div class="yellow"></div>
	<div class="red"></div>
	<div class="blue"></div>
	<div class="violet"></div>
</div>  : 
<> </>
}

      <div className="authbody">
        {/* <Header></Header> */}
        <div className="clearfix"></div>



        <div className="auth">
          <div className="auth_header">
            <img src={logo1} />
          </div>

          {/* <div className="auth_btns">
          <div className="auth_btns_title">Sign Up using</div>

          <div style={{ display: "flex" }}>
            <Button className="auth_btns_btn1">
              <FaGoogle></FaGoogle> &nbsp; Google
            </Button>

            <Button className="auth_btns_btn2">
              <FaFacebook></FaFacebook> &nbsp; Facebook
            </Button>
          </div>
        </div>

        <div className="line_spacer">
          <div className="line_spacer_line_1"></div>

          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>Or</div>

          <div className="line_spacer_line_2"></div>
        </div> */}

          <form id="form" onSubmit={register}>
            <div className="form-group">
              <label for="uname">First Name</label>
              <input
                className="form-control"
                type="text"
                id="uname"
                name="firstname"
                required
                onBlur={(event) => {
                  blurEvent(event, 3, "Please type your Firstname");
                }}
              ></input>
              <span className="error"></span>
            </div>

            <div className="form-group">
              <label for="uname">Lastname</label>
              <input
                className="form-control"
                type="text"
                id="uname"
                name="lastname"
                required
                onBlur={(event) => {
                  blurEvent(event, 3, "Please type your lastname");
                }}
              ></input>
              <span className="error"></span>
            </div>

            <div className="form-group">
              <label for="uname">Username</label>
              <input
                className="form-control"
                type="text"
                id="uname"
                name="username"
                required
                onBlur={(event) => {
                  blurEvent(event, 3, "Please type your username");
                }}
              ></input>
              <span className="error"></span>
            </div>

            <div className="form-group">
              <label for="email">Email</label>
              <input
                className="form-control"
                type="email"
                id="email"
                name="email"
                required
                onBlur={(event) => {
                  blurEvent(event, 6, "Please type your email address");
                }}
              ></input>
              <span className="error"></span>
            </div>

            <Row>
              <Col sm="12" md="6">
                <div className="form-group">
                  <label for="pass">Password</label>
                  <input
                    className="form-control"
                    type="password"
                    id="pass"
                    name="pass1"
                    required
                    onBlur={(event) => {
                      blurEvent(
                        event,
                        6,
                        "Your password must be atleast 6 characters"
                      );
                    }}
                  ></input>
                  <span className="error"></span>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="form-group">
                  <label for="cpass">Confirm Password</label>
                  <input
                    className="form-control"
                    type="password"
                    id="cpass"
                    name="pass2"
                    required
                    onBlur={(event) => {
                      blurEvent(event, 6, "Please type your password again");
                    }}
                  ></input>
                  <span className="error"></span>
                </div>
              </Col>
            </Row>

            <Button type="submit" className="auth_form_submit">
              Register
            </Button>
          </form>
          <div style={{ textAlign: "center" }}>
            Already a member? <a href="/auth">Sign In</a>
          </div>
        </div>

        {/* <Footer></Footer> */}
      </div>
    </>
  );
};

export default SignUp;
