import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/clients/style.css";
import img from "../../../../assets/images/technicals/1.jpg";
import { AiOutlineEye } from "react-icons/ai";
import { GiCampfire } from "react-icons/gi";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "react-bootstrap";
import {
    getCourses,
    deleteCourse
  
} from "../../../../server/firebase.config";

const ViewCourse = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [course, setCourse] = useState(null);



  const effect = useEffect(() => {

    
    const subscription = getCourses().subscribe(item => {
        setCourse(item)
    })
     

      return () => {
        subscription.unsubscribe()
      }


      
     

  }, []);

  
  const stringToHTML = (str) => {
      const newString = str?str:'<div> </div>'
    const parser = new DOMParser();
    const doc = parser.parseFromString(newString, "text/html");
    return doc.body.firstElementChild;
  };


  return (
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>

          <div className="clearfix"></div>

          <Container fluid className="mb feeds crypto_posts">
            <Row>
           
                {course?.map( (item) => 
              <Col sm="12" md="3">
                <Card className="crypto_card">
                  <Card.Img
                    variant="top"
                    className="crypto_cardImg"
                    src={item?.mainImage?.imageUrl}
                  />

<div class="crypto-hot" > <a onClick={()=> {deleteCourse(item.id)} } style={{cursor:'pointer'}}><FaTrash></FaTrash></a>  </div>
<div class="crypto-new"> <a href={`/admin/edit-course?id=${item.id}`}><FaEdit></FaEdit> </a></div>
{/* { item?.hot==='yes'? 

<div class="crypto-hot"><GiCampfire></GiCampfire> &nbsp;Hot</div>
:
<> </>

}

{ item?.new==='yes'? 
<div class="crypto-new">New</div>
:
<> </>

} */}


                  
                  <Card.Body className="crypto_cardBody">
                    {" "}
                    <p className="prodTitle">{item?.title}</p>
                    <p>
                      {/* {stringToHTML (item?.editorData)} */}
                      <div dangerouslySetInnerHTML={{__html: item?.editorData}} />
                          
                    </p>
                    <div>
                      {" "}
                      <span className="prodby">
                        {" "}
                        <a href={`/admin/add-class?id=${item.id}`}>
                          <Button className="main-carousel_btn">Add</Button>
                        </a>
                      </span>{" "}
                      &nbsp;
                      <span className="proddate">
                      <a href={`/admin/view-class?id=${item.id}`} >
                          <Button className="main-carousel_btn">View Classes</Button>
                        </a>
                    </span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              )}



              

            </Row>
          </Container>
        </Col>
      </Row>

      </Container>
    </div>
  );
};

export default ViewCourse;
