import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import "../../../assets/css/front/style.css";
import "../../../assets/css/payment.css";
import "../../../assets/css/front/checkout.css";
// import logo1 from "../../../assets/images/logo/logo1.png";
import { Button } from "react-bootstrap";
import {
  FaCcAmex,
  FaCcMastercard,
  FaCcPaypal,
  FaCcVisa,
  FaFacebook,
} from "react-icons/fa";
import { IoReturnUpBack } from "react-icons/io5";
import { RiVisaFill } from "react-icons/ri";
import {
  stripeSetUpIntentCardSave,
  getOneUsers,
  getSignalMembership,
  getLiveMembership,
  getOnlineMembership,
  createPaymentIntent,
  getPaymentDetails,
  getUserDetails$,
  createSubscription,
  getCoupons,
} from "../../../server/firebase.config";
import { AiFillSignal } from "react-icons/ai";
import { AiOutlineWifi } from "react-icons/ai";
import { RiLiveLine } from "react-icons/ri";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { firstValueFrom } from "rxjs";
import { fireAuthState } from "../../../server/firebase.config";
import { switchMap } from "rxjs/operators";

export function CheckoutForm({
  getPecentage,
  formType,
  priceId,
  productId,
  Price,
}) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [userPayment, setUserPayment] = useState([]);

  const nameRef = useRef(null);

  useEffect(() => {
    const subscribtion = fireAuthState
      .pipe(switchMap((user) => getPaymentDetails(user.uid)))
      .subscribe((item) => {
        if (item) {
          setUserPayment(item);
          // console.log(item);
        }
      });

    return () => {
      subscribtion.unsubscribe();
    };

    // Create PaymentIntent as soon as the page loads
  }, []);
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const usr = await firstValueFrom(getUserDetails$);
    const result = await createPaymentIntent({
      description: formType.plan,
      userid: usr.userId,
      price: getPecentage() * 10,
    });

    const payload = await stripe.confirmCardPayment(result.data.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setError('Payment Not successful');
      console.log(payload.error.message)
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      alert("Payment Successful");
      window.location.href = "/dashboard";
    }
  };

  // const handleSubsChange = async (event) => {
  //   // Listen for changes in the CardElement
  //   // and display any errors as the customer types their card details
  //   setDisabled(event.empty);
  //   setError(event.error ? event.error.message : "");
  // };

  // const handleSubsSubmit = async (ev) => {
  //   ev.preventDefault();
  //   setProcessing(true);

  //   const result = await firstValueFrom(getUserDetails$);

  //   const secret = await stripeSetUpIntentCardSave({
  //     userId: result.uid,
  //     customerId: result.customerId,
  //   });

  //   const data = await stripe.confirmCardSetup(secret.data, {
  //     payment_method: {
  //       card: elements.getElement(CardElement),
  //       billing_details: {
  //         name: nameRef.current.value,
  //       },
  //     },
  //   });
  // };

  const subscribe = async (event) => {
    try {
      event.preventDefault();
      // alert(priceId)

      // returnva
      setProcessing(true);

      const values = Array.from(event.target.elements)
        .map((item) => ({
          name: item.name,
          value: item.value,
        }))
        .filter((val) => val.name !== "");

      let valueFields = {};

      for (const items in values) {
        const { name, value } = values[items];
        valueFields[name] = value;
      }

      // console.log(priceId);

      const userDetials = await firstValueFrom(getUserDetails$);
      const status = await createSubscription({
        customer: userDetials.customerId,
        // price: getPecentage(),
        price: priceId,
        userid: userDetials.id,
        defaultPaymentMethod: valueFields.rad,
        productId,
        
      });

      await stripe.confirmCardPayment(status.data, {
        payment_method: valueFields.rad,
        
        
      })

      // if (status.data !== 'active'){
      //   alert('Payment not successful')
      //   return
      // }


   
     await alert("Payment Successful");
      window.location.href = "/dashboard";
    } catch (error) {
      alert("error");
      console.log(error);
    }
  };
  return (
    <>
      {formType?.plan === "live" || formType?.plan === "online" ? (
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
          <button
            style={{ marginTop: "20px" }}
            disabled={processing || disabled || succeeded}
            id="submit"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
          {/* Show a success message upon completion */}
          <p className={succeeded ? "result-message" : "result-message hidden"}>
            Payment successful
          </p>
        </form>
      ) : (
        // {/* SUBSCRIPTION FORM */}

        // <form id="payment-form" onSubmit={handleSubsSubmit}>
        //   <div class="form-group">
        //     <label for="name"> Name </label>
        //     <input type="text" className="form-control" id="name" ref={nameRef} />
        //     </div>
        //   <CardElement
        //     id="card-element"
        //     options={cardStyle}
        //     onChange={handleSubsChange}
        //   />
        //   <button
        //     style={{ marginTop: "20px" }}
        //     disabled={processing || disabled || succeeded}
        //     id="submit"
        //   >
        //     <span id="button-text">
        //       {processing ? (
        //         <div className="spinner" id="spinner"></div>
        //       ) : (
        //         "Subscribe"
        //       )}
        //     </span>
        //   </button>
        //   {/* Show any error that happens when processing the payment */}
        //   {error && (
        //     <div className="card-error" role="alert">
        //       {error}
        //     </div>
        //   )}
        //   {/* Show a success message upon completion */}
        //   <p className={succeeded ? "result-message" : "result-message hidden"}>
        //     Payment successful
        //   </p>
        // </form>

        <form onSubmit={subscribe}>
          {userPayment?.map((item, index) => (
            <>
              <div key={index}>
                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    value={item.id}
                    name="rad"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">{item.card.last4}</div>
                </label>
              </div>

              <div>
                <Button
                  variant="outline-primary"
                  type="submit"
                  style={{ marginTop: "20px" }}
                  // id="submit"
                >
                  Subscribe
                </Button>
              </div>

              <p>Note: Add your Card details in your profile </p>
            </>
          ))}
        </form>
      )}
    </>
  );
}

const promise = loadStripe(
  "pk_live_51JLrWiGTx2jTSYdz7FzHtNVBWH89XkMtP7iFHDmwDTN5sdwe0uK9yc4qPAn1stlHTuU8EoVtfDIfzxrhOXBLFltt00PPc4sXBf"
);

const Subscription = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  // document.querySelector('.tv-header__link').remove();

  const [signals, setSignals] = useState(null);
  const [live, setLive] = useState(null);
  const [online, setOnline] = useState(null);
  const [planValue, setPlanValue] = useState(null);
  const [showPlan, setShowPlan] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [showcard, setshowCard] = useState(true);
  const [selectedCard, setselectedCard] = useState(false);
  const [check, setCheck] = useState(true);
  const [coupon, setCoupon] = useState([]);
  const [percentToGet, setPercentToGet] = useState("");
  const [couponAdd, setCouponAdded] = useState("");
  const [percent, setPercent] = useState("");
  const paidRef = useRef(false);
  const pricev = useRef("");
  const planName = useRef("");
  const couponAdded = useRef(false);
  // const percentToGet = useRef(0);

  pricev.current = planValue?.price;
  planName.current = planValue?.plan;

  var vat = 15;
  var totalAfter = (vat / 100) * pricev.current;

  var totalAfterVat = totalAfter + parseInt(pricev.current);
  const [afterVat, setAfterVat] = useState(totalAfterVat);

  const couponInput = (event) => {
    event.preventDefault();
    // console.log(event.target.value)

    const inputCode = event.target.value;
    const couponIndex = coupon.findIndex((ind) => ind.coupon === inputCode)
    // console.log(couponIndex)
    if(couponIndex > -1){
     let percentage = Number(coupon[couponIndex].percentage)
     percentage = (percentage/100)*percent;
     if(!couponAdded.current){
      setPercent(percentage)
      couponAdded.current = true
      setCouponAdded(true)
      return
     }

     
     
    }

    // var vat = 15;
    //   var totalAfter = (vat / 100) * item.price;
    //   var totalAfterVat = totalAfter + parseInt(item.price);
    // coupon?.map((item) => {
    //   if (item?.coupon === inputCode) {
    //     setPercentToGet(item?.percentage);
    //     console.log(percentToGet)
    //   }
    // });
  };

  const initialOptions = {
    "client-id":
      "ARpkR7Yn1sH4CrG1qWzcHCGiw5to5kvpqPMlJvdN3y-0xw3rTqrADYChqbJlyYKxUGlqSlqdngZ4jB3r",
    currency: "USD",
    intent: "capture",
  };

  const checked = () => {
    setCheck(!check);
  };

  const pay = () => {
    setShowPay(true);
    setShowResults(false);
    setShowPlan(false);
  };

  const Backtosubscription = () => {
    setShowPay(false);
    setShowResults(false);
    setShowPlan(true);
  };

  const submitSignals = (price) => {
    // console.log(price)
    setPlanValue({ price, plan: "signals" });
    setShowResults(true);
    setShowPlan(false);
  };

  const submitOnline = (price) => {
    // console.log(price)
    setPlanValue({ price, plan: "online" });
    setShowResults(true);
    setShowPlan(false);
  };

  const submitLive = (price) => {
    // console.log(price)
    setPlanValue({ price, plan: "live" });
    setShowResults(true);
    setShowPlan(false);
  };

  const paypal = () => {
    setshowCard(true);
    setselectedCard(true);
  };
  const card = () => {
    setshowCard(false);
    setselectedCard(false);
  };

  const effect = useEffect(() => {
    firstValueFrom(getCoupons())
      .then((item) => {
        // console.log(item)
        setCoupon(item);
      })
      .catch((error) => {
        console.log(error);
      });

    // Paypal Payment Setup

    const Signalsubscribe = getSignalMembership().subscribe((item) => {
      setSignals(item);
      var vat = 15;
      var totalAfter = (vat / 100) * item.price;
      var totalAfterVat = totalAfter + parseInt(item.price);
    });

    const Livesubscribe = getLiveMembership().subscribe((item) => {
      setLive(item);
      var vat = 15;
      var totalAfter = (vat / 100) * item.price;
      var totalLiveAfterVat = totalAfter + Number(item.price);
    });

    const Onlinesubscribe = getOnlineMembership().subscribe((item) => {
      setOnline(item);
      var vat = 15;
      var totalAfter = (vat / 100) * item.price;
      var totalAfterVat = totalAfter + Number(item.price);
    });

    return () => {
      Signalsubscribe.unsubscribe();
      Livesubscribe.unsubscribe();
      Onlinesubscribe.unsubscribe();
    };
  }, []);

  const getPecentage = () => {
    return percent;
  };

  const subsType = () => {};

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <div className="mb">
              {showPlan ? (
                <Container fluid className="subs">
                  <h1>Choose Plan</h1>
                  <Row>
                    {/* {signals?.length === 0 ? (
                <></>
              ) : (
                signals?.map((item) => ( */}
                    <Col sm="4" md="4">
                      <Card
                        className="main-carousel membership-1 selected"
                        tabindex="1"
                        onClick={() => {
                          submitSignals(signals?.price);
                          // var vat = 0;
                          // var totalAfter = (vat / 100) * signals?.price;
                          // var totalAfterVat =
                          //   totalAfter + parseInt(signals?.price);
                          // setPercent(totalAfterVat);
                          setPercent(signals?.price)
                        }}
                      >
                        <Card.Body>
                          <div className="membership-signals_title ">
                            <AiFillSignal></AiFillSignal> SIGNALS
                          </div>

                          <div className="membership-signals_body">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${signals?.body}`,
                              }}
                            ></div>
                          </div>

                          <div className="membership-signals_price">
                            <div className="membership-signals_price-ccc">
                              $
                            </div>
                            <div className="membership-signals_price-pp">
                              {signals?.price}
                            </div>
                            <div className="membership-signals_price-mm">
                              /{signals?.duration}
                            </div>
                          </div>
                        </Card.Body>
                        {/* <input
                    type="radio"
                    id="signals"
                    name="plan"
                    value="signals"
                  /> */}
                      </Card>
                    </Col>
                    {/* ))
              )} */}

                    <Col sm="4" md="4">
                      <Card
                        className="main-carousel membership-2 selected"
                        style={{cursor:'none'}}
                        tabindex="1"
                        onClick={() => {
                          alert('Not available')
                          // submitOnline(online?.price);
                          // var vat = 15;
                          // var totalAfter = (vat / 100) * online?.price;
                          // var totalAfterVat =
                          //   totalAfter + parseInt(online?.price);
                          // setPercent(totalAfterVat);
                        }}
                      >
                        <Card.Body>
                          <div className="membership-course_title">
                            <AiOutlineWifi></AiOutlineWifi> ONLINE COURSE
                          </div>

                          <div className="membership-course_body">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${online?.body}`,
                              }}
                            ></div>
                          </div>

                          <div className="membership-course_price">
                            <div className="membership-course_price-ccc">$</div>
                            <div className="membership-course_price-pp">
                              {online?.price}
                            </div>
                            <div className="membership-course_price-mm">
                              /{online?.duration}
                            </div>
                          </div>
                        </Card.Body>
                        {/* <input type="radio" id="online" name="plan" value="online" /> */}
                      </Card>
                    </Col>

                    <Col sm="4" md="4">
                      <Card
                        className="main-carousel membership-3 selected"
                        tabindex="1"
                        onClick={() => {
                          // submitLive(live?.price);
                          // var vat = 15;
                          // var totalAfter = (vat / 100) * live?.price;
                          // var totalAfterVat =
                          //   totalAfter + parseInt(live?.price);
                          // setPercent(totalAfterVat);
                          alert('Not available')
                        }}
                      >
                        <Card.Body>
                          <div className="membership-live_title">
                            <RiLiveLine></RiLiveLine> Live Course
                          </div>

                          <div className="membership-live_body">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${live?.body}`,
                              }}
                            ></div>
                          </div>

                          <div className="membership-live_price">
                            <div className="membership-live_price-ccc">$</div>
                            <div className="membership-live_price-pp">
                              {live?.price}
                            </div>
                            <div className="membership-live_price-mm">
                              /{live?.duration}
                            </div>
                          </div>
                        </Card.Body>
                        {/* <input type="radio" id="live" name="plan" value="live" /> */}
                      </Card>
                    </Col>
                  </Row>
                </Container>
              ) : null}

              {showResults ? (
                <Container fluid>
                  <h2 style={{ textAlign: "center", marginTop: "30px" }}>
                    Checkout and Proceed to Payment
                  </h2>

                  <div>
                    <Button variant="outline-dark" onClick={Backtosubscription}>
                      {" "}
                      <IoReturnUpBack className="icon"></IoReturnUpBack>{" "}
                    </Button>
                  </div>
                  <Row>
                    <Col sm="12" md="6" className="payment_tab">
                      <div class="container payment">
                        <div class="card__container">
                          <div class="payment_card">
                            {planValue.plan === "signals" || planValue.plan === "live" || planValue.plan === "online" ? (
                              <></>
                            ) : (
                              <div class="row paypal">
                                <div class="left">
                                  <input
                                    id="pp"
                                    type="radio"
                                    onChange={paypal}
                                    name="payment"
                                  />
                                  <div class="radio"></div>
                                  <label for="pp">Paypal</label>
                                </div>
                                <div
                                  class="right"
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "30px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  <FaCcPaypal
                                    style={{
                                      marginLeft: "10px",
                                      color: "#1E2148",
                                    }}
                                  ></FaCcPaypal>
                                </div>

                                <div
                                  style={{
                                    position: "relative",
                                    margin: "0 auto",
                                  }}
                                >
                                  {selectedCard ? (
                                    <PayPalScriptProvider
                                      options={initialOptions}
                                    >
                                      <PayPalButtons
                                        disabled={check}
                                        createOrder={(data, actions) => {
                                          return actions.order.create({
                                            purchase_units: [
                                              {
                                                amount: {
                                                  value: percent,
                                                },
                                              },
                                            ],
                                          });
                                        }}
                                        onApprove={(data, actions) => {
                                          // setPaid(true);

                                          return actions.order
                                            .capture()
                                            .then(function () {
                                              alert("Transaction complete!");
                                              // paidRef.value = true;
                                            });
                                          // console.log(order);
                                        }}
                                        onError={(err) => {
                                          //   setError(err),
                                          // alert('error')
                                          console.error(err);
                                        }}
                                        style={{
                                          background: "#f83a0c",
                                          border: "none",
                                        }}
                                      >
                                        Pay Now
                                      </PayPalButtons>
                                    </PayPalScriptProvider>
                                  ) : (
                                    <> </>
                                  )}
                                </div>
                              </div>
                            )}

                            <div class="row credit">
                              <div class="left">
                                <input
                                  id="cd"
                                  type="radio"
                                  onChange={card}
                                  name="payment"
                                />
                                <div class="radio"></div>
                                <label for="cd">Debit/ Credit Card</label>
                              </div>
                              <div
                                class="right"
                                style={{
                                  marginLeft: "20px",
                                  fontSize: "30px",
                                  marginTop: "-10px",
                                }}
                              >
                                {/* <FaCcVisa></FaCcVisa> */}
                                <RiVisaFill
                                  style={{
                                    marginLeft: "10px",
                                    color: "#1E2148",
                                  }}
                                ></RiVisaFill>
                                <FaCcMastercard
                                  style={{ marginLeft: "10px" }}
                                ></FaCcMastercard>
                                <FaCcAmex
                                  style={{ marginLeft: "10px" }}
                                ></FaCcAmex>
                              </div>
                            </div>
                            <div class="row cardholder">
                              {selectedCard ? (
                                <>
                                
                                 </>
                              ) : (
                                <div
                                  className="App"
                                  style={{ width: "100%", marginTop: "20px" }}
                                >
                                  <Elements stripe={promise}>
                                    <CheckoutForm
                                      getPecentage={getPecentage}
                                      formType={planValue}
                                      priceId={signals?.stripePriceId}
                                      productId={signals?.stripeProductId}
                                    />
                                  </Elements>

                                  {planValue.plan === "signals"? <p>Add Your card details in your profile</p>:<></> }
                                  
                                </div>
                                
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div class="button">
       <button type="submit"><i class="ion-locked"></i> Confirm and Pay</button>
   </div> */}
                      </div>
                    </Col>

                    <Col sm="12" md="5" className="payment_tab2">
                      <div className="checkout_summary">
                        <div className="summary_left">
                          Billed monthly
                          {/* <p>Save 20% with annual billing</p> */}
                        </div>
                        <div className="summary_right">
                          $ {pricev.current}/mo
                        </div>
                      </div>

                      <div className="checkout_summary">
                        <div className="summary_left">
                          Coupon
                          <p>Add your coupon code here</p>
                        </div>
                        {couponAdd? <> </> :
                        <div className="summary_right">
                          <input
                            type="text"
                            className="form-control"
                            onInput={couponInput}
                            style={{
                              borderBottom: "1px solid rgba(44, 37, 19, 0.8)",
                              boxShadow: "none",
                            }}
                          />
                        </div>
                        }
                      </div>

                      <div className="checkout_summary">
                        <div className="summary_left">
                          <p>Vat</p>
                          Total
                        </div>
                        <div className="summary_right">
                          <p>{planValue.plan === "signals"? <div>0%</div>: <div>15%</div>} </p>$ {percent}/mo
                        </div>
                      </div>

                      <br></br>
                      <div style={{ display: "flex", padding: "20px" }}>
                        <input
                          type="checkbox"
                          className="form-control"
                          onChange={checked}
                          style={{
                            width: "10%",
                            border: "none",
                            boxShadow: "none",
                          }}
                        />
                        &nbsp;
                        <span style={{ width: "90%" }}>
                          I agree to Obsidian{" "}
                          <a href="/help">Terms & Conditions</a> and{" "}
                          <a href="/privacy">Privacy Policy</a>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Container>
              ) : null}

              {showPay ? <></> : null}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Subscription;
