import {storage} from '../server/firebase.config'
import {  getDownloadURL, put  } from 'rxfire/storage';
import { lastValueFrom } from "rxjs";

import {Subject } from 'rxjs';
export const uploadResult$ = new Subject()

class UploadAdapter {
    constructor(loader) {
        this.loader = loader
    }

    upload() {
        return this.loader.file.then(
            (file) => {
                const fileRef = `images/${file.name}`
                const ref = storage.ref(fileRef);
                const task = put(ref, file)

                return lastValueFrom(task).then(() => lastValueFrom(getDownloadURL(ref)).then((url) => {
                    uploadResult$.next({
                        url, fileRef 
                    });
                    return {default: url}
                }));
            }
                
        )}

    abort() {
       
    }
}





export default UploadAdapter