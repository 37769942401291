import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/clients/style.css";
import { getClass, getOneCourse, deleteClass } from "../../../../server/firebase.config";
import queryString from "query-string";
import { Button } from "react-bootstrap";
import Moment from 'react-moment';

const Viewclass = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->


  const [Class, setClass] = useState(null);
  const [course, setCourse] = useState(null);

  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];

  const effect = useEffect(() => {
    const subscription = getClass(realId).subscribe((item) => {
      setClass(item);
    });

  //   const subs = getOneCourse().subscribe(item => {
  //     setCourse(item)
  // })
   


    return () => {
      subscription.unsubscribe();
      // subs.unsubscribe()
    };
  }, []);

  return (
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>

          <div className="clearfix"></div>



          <Container fluid className="mb feeds">

          <div className="news_head">
          
          
          {/* <div className="news_head_title"> {course?.title} Class</div> */}
          
          

          
        </div>


          <Row>

          {Class?.map((item) => (
            <Col xs="12" md="12">
              <Row>
                <Col xs="12" md="12" className="news_post_main">
                  <Row>
                    <Col xs="12" md="2" className="news_post_main_img">
                    <img src={item?.mainImage?.imageUrl} />

                 

                
                  

                    </Col>

                    

                    <Col xs="12" md="6" className="news_post_main2">
                      <div className="news_post_main_title">
                        {" "}
                        {item?.title}
                      </div>

                      <div className="news_post_main2_bottom">
                        <div className="news_post_main_by">
                        <div
                            dangerouslySetInnerHTML={{
                              __html: `${item?.editorData.substring(
                                0,
                                "100"
                              )}...`,
                            }}
                          ></div>
                        </div>

                        <div className="news_post_main_date">
                        <Moment format="D MMM YYYY" withTitle>{item?.timestamp}</Moment>
                          </div>
                      </div>
                    </Col>

                    <Col xs="12" md="4" className="news_post_main_btn">
                      <a href={`/admin/edit-class?id=${item.id}&idclass=${realId}`}>
                        <div className="news_post_main_btn-btn">Edit Class</div>
                      </a>

                      
                    </Col>
                  </Row>
                  <Button variant="outline-danger" onClick={()=> {deleteClass( realId,item.id)} }>Delete</Button>
                </Col>

                
              </Row>
            </Col>
            ))
            }

            
          </Row>

          <div className="more-news">
          <div className="more-news_btn">Load More</div>
        </div>

        </Container>

        
      

        </Col>
      </Row>

      </Container>
    </div>
  );
};

export default Viewclass;
