import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import logo from "../../assets/images/logo/logo2.png";

const Downloads = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  return (
    <div className="cryptocurrencybg">
<img src={logo}  style={{marginLeft: '30%', float: 'center', height: '200px', width: '50%' }} />

<div className=" cryptocurrency">

  CryptoCurrency Widgets coming soon :)

<br></br>
  <a href="/dashboard"> <Button variant="outline-danger">Go Back</Button> </a>
  </div>


      

      
    </div>
  );
};

export default Downloads;
