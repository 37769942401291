import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import {FaEdit, FaTrash} from 'react-icons/fa';
import Table  from "react-bootstrap/Table";
import {getNewsletter} from '../../../../server/firebase.config';

const Newsletter = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [emails, setEmails] = useState(null);



  const effect = useEffect(() => {

    
    const subscription = getNewsletter().subscribe(item => {
        setEmails(item)
    })
     

      return () => {
        subscription.unsubscribe()
      }


     

  }, []);
 


  return (
    <div>

        <Container fluid>
            <Row>
                <Col md="2" className="sidebar-col">
                    <Sidebar></Sidebar>
                </Col>
                <Col sm="12" md="10" className="mainbody">
                    <Header></Header>

<div className="clearfix"></div>
                    <div className="mb">

                        <Container fluid >
                            <Row>
                                <Col sm="12" md="12" className="livechart" style={{background:"white"}}>

                                
                                <Table striped bordered hover style={{marginTop:"10px"}}>
  <thead>
    <tr>
      <th>Email</th>
      <th>Status</th>
      <th>Reg Date</th>
      {/* <th><span className="prodby"> <a href={`/admin/edit-users?id=${item.id}`}><FaEdit className="icons"></FaEdit> </a> </span> </th> */}
    </tr>
  </thead>
  <tbody>

  {emails?.map( (item) => 
    <tr>
      <td>{item?.email}</td>
      <td>{item?.status}</td>
      <td>
      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(item?.timestamp)} </td>
      <td>
      
      </td>
    </tr>
     )}
   
  </tbody>
</Table>

                              
                                </Col>
                                </Row>
                        </Container>

                                

                    </div>

                    
                </Col>
            </Row>

        </Container>
      

      
    </div>
  );
};

export default Newsletter;
