import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import img from "../../assets/images/technicals/1.jpg";
import { GiCampfire } from "react-icons/gi";
import { FaEdit, FaTrash, FaUpload } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  getimageFromFirebase,
  storage,
  saveImage,
  deleteImage,
} from "../../server/firebase.config";
import Dropzone from "react-dropzone";
import { getDownloadURL, put } from "rxfire/storage";
import { firstValueFrom, lastValueFrom } from "rxjs";
const Imgfiles = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Image, setImages] = useState(null);

  const addToSorage = (date, item) => {
    const imageref = storage.ref(`images/${date}`);
    const task = put(imageref, item);
    return lastValueFrom(task)
      .then(() => lastValueFrom(getDownloadURL(imageref)))
      .then((download) => ({
        imageUrl: download,
        imagePath: `images/${date}`,
        timestamp: Date.now(),
      }));
  };

  const img = (event) => {
    // console.log('Received files: ', event);
    // console.log(event)
    // event.preventDefault();

    // console.log(event.target.elements)
    if (event) {
      // console.log(event)
      const files = Array.from(event);

      const promises = [];
      files.forEach((item) => {
        const date = Date.now();
        promises.push(addToSorage(date, item));
      });
      const promises2 = [];
      // alert("working");
      Promise.allSettled(promises)
        .then((urls) => {
          alert("uploaded");
          return urls.forEach((item) => {
            // console.log({item})
            saveImage(item.value).then((result) => {
              console.log(result);
            })
            .catch((error) => {
              console.log({ error });
            });

          });
        })
        .then(() => {
          document.querySelector(".imageInput").value = null;
        })
        .catch((error) => {
          console.log({ error });
        });
      // Promise.allSettled(promises2)
      // .then(()=>{
      //   alert('Files Uploaded')
      // }

      // )
      // .catch((error) => {
      //   console.log(error);
      // });
    }
  };

  const effect = useEffect(() => {
    const subscription = getimageFromFirebase().subscribe((item) => {
      setImages(item);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>

            <Container fluid className="mb feeds crypto_posts">
              {/* <div className="news_head">
          <div className="news_head_title"> </div>

          <div className="news_head_subtitle">
            Your insights to keep you afloat
          </div>
        </div>
         */}

              <div className="admin_hero_head">
                <button className="btn btn-outline-dark" onClick={handleShow}>
                  Add Image(s)
                </button>
              </div>

              <Row>
                {Image?.map((item) => (
                  <Col sm="12" md="3">
                    <Card className="crypto_card">
                      <Card.Img
                        variant="top"
                        className="crypto_cardImg"
                        src={item.imageUrl}
                      />
                      <Card.Body className="crypto_cardBody">
                        <div>
                          {" "}
                          <span className="prodby">
                            {" "}
                            <a href="/admin/edit-post">
                              <FaEdit className="icons"></FaEdit>{" "}
                            </a>{" "}
                          </span>{" "}
                          &nbsp;
                          <span className="proddate">
                            <FaTrash
                              className="FaCalendar"
                              onClick={() => {
                                deleteImage(item.id, item.imagePath);
                              }}
                            ></FaTrash>{" "}
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>

              <div className="more-news">
                <div className="more-news_btn">Load More</div>
              </div>
            </Container>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Images</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Dropzone
                  name="pimg"
                  onDrop={(event) => {
                    img(event);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} className="imageInput" />

                        <FaUpload className="dragicon"></FaUpload>
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Imgfiles;
