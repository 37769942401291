import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { config } from "rxjs";
import {
  createPost,
  getimageFromFirebase,
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import { MDBNotification } from "mdbreact";
// import Toast from "react-bootstrap/Toast";
// import ToastContainer from 'react-bootstrap/ToastContainer';
import UploadAdapter, { uploadResult$} from "../../../../utils/custom-ck-adapter";
import { Prompt } from 'react-router'

const AddPost = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [showToast, setshowToast] = useState(false);

  const imageDetails = useRef(null);

  const file = useRef(null);
  const editorData = useRef("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Image, setImages] = useState(null);

  const effect = useEffect(() => {
    firstValueFrom(getimageFromFirebase())
      .then((item) => {
        // console.log(item);
        setImages(item);
      })
      .catch((error) => {
        console.log(error);
      });

      const sub = uploadResult$.subscribe(item => {
        // console.log(item);
        imageDetails.current = item
      });


    return () =>{
      sub.unsubscribe();
    }  }, []);

  const formData = (event) => {
    event.preventDefault();
    // const publish = event.nativeEvent.submitter.publish;
    // const confirmation = publish === 'publish'? window.confirm('Are you sure want to post this?') : window.confirm('Are you sure want to draft this?');
    // if(!confirmation){
    //     return
    //   }

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    // console.log(file.current);

    if (file.current) {
      // console.log(file.current);
      valueFields = {
        ...valueFields,
        mainImage: file.current,
        // editorData: editorData.current,
        
        // publish
      };
    }

    valueFields = { ...valueFields, editorData: editorData.current };
    valueFields = { ...valueFields, timestamp: Date.now() };

    // console.log(valueFields);

    // return

    createPost(valueFields);
    document.getElementById("form").reset();
    alert('Post Added')
    editorData.current = " ";
    setshowToast(true);
  };

  const selectImage = (event) => {
    console.log({ event });
    file.current = event;
    handleClose();
  };

  // const editor = () =>{
  //   ClassicEditor
  //                     .create( document.querySelector( '#editor' ), {
  //                         cloudServices: {
  //                             tokenUrl: 'https://example.com/cs-token-endpoint',
  //                             uploadUrl: 'https://your-organization-id.cke-cs.com/easyimage/upload/'
  //                         }
  //                     } )
  //                     .then( ... )
  //                     .catch( ... );
  // }

  return (
    <>
     <Prompt
      when={true}
      message='You have unsaved changes, are you sure you want to leave?'
    />
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>

          {/* <ToastContainer position="top-end" className="p-3"> */}

          {/* <Toast onClose={() => setshowToast(false)} show={showToast} delay={3000} className="toast" autohide>
      
      <Toast.Body className="success">Post Added</Toast.Body>
    </Toast> */}

          <MDBNotification
            onClose={() => setshowToast(false)}
            show={showToast}
            fade
            iconClassName="text-primary"
            message="Post Added"
            style={{
              position: "fixed",
              top: "10px",
              background: "green",
              color: "white",
              right: "10px",
              zIndex: 9999,
            }}
          />
          {/* </ToastContainer> */}

          <div className="clearfix"></div>
          <div className="mb">
            <Container fluid>
              <Row>
                <Col sm="12" md="12" className="livechart">
                  <Form
                    id="form"
                    className="add-form"
                    onSubmit={(event) => {
                      formData(event);
                    }}
                  >
                    <div className="form-group">
                      <label for="ptitle">Title</label>
                      <input
                        type="text"
                        id="ptitle"
                        className="form-control"
                        name="title"
                      />
                    </div>

                    <div className="form-group">
                      <label for="pimg">Post Image</label>
                      {/* <input type="file" id="pimg" className="form-control" name="" /> */}
                      <div className="dropzone" onClick={handleShow}>
                        <FaUpload className="dragicon"></FaUpload>
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label for="cat">Category</label>
                      <select id="cat" className="form-control" name="cat">
                        <option value="technicals">Technicals</option>
                        <option value="techArt">Technical Articles</option>
                        <option value="market">Market News</option>
                        <option value="crypto">Cryptocurrency</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="cat">Hot</label>
                      <select id="cat" className="form-control" name="hot">
                        <option selected="selected">Choose </option>
                        <optgroup></optgroup>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="cat">New</label>
                      <select id="cat" className="form-control" name="new">
                        <option selected="selected">Choose </option>
                        <optgroup></optgroup>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="cat">Featured</label>
                      <select id="cat" className="form-control" name="featured">
                        <option selected="selected">Choose </option>
                        <optgroup></optgroup>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="cat">Post Type</label>
                      <select id="cat" className="form-control" name="view">
                        <option selected="selected">Choose </option>
                        <optgroup></optgroup>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="body">Body</label>
                      <CKEditor
                        onReady={(editor) => {
                         
                          editor.plugins.get(
                            "FileRepository"
                          ).createUploadAdapter = (loader) => {
                            return new UploadAdapter(loader);
                          };
                        }}
                        editor={ClassicEditor}
                        config={(config.height = "500")}
                        // console
                        onChange={(event, editor) => {
                         
                          // console.log(editor.getData());
                          editorData.current = editor.getData();
                          return false
                          // console.log( { event, editor, data } );
                        }}
                        name="body"
                      />
                    </div>

                    <div className="form-group">
                      <Button
                        type="submit"
                        variant="outline-dark"
                        publish="publish"
                      >
                        Publish
                      </Button>
                      &nbsp;
                      {/* <Button type="submit" variant="outline-danger" publish="draft">
                        Draft
                      </Button> */}
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {Image?.map((item) => (
                <Col key={item.id} sm="12" md="4">
                  <Card
                    onClick={() => {
                      selectImage(item);
                    }}
                    className="imgSelection-li"
                  >
                    <label class="img-label" for={`cb${item.imageUrl}`}>
                      <Card.Img
                        variant="top"
                        className=""
                        src={item.imageUrl}
                      />
                    </label>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      </Container>
    </div>
    </>
  );
};

export default AddPost;
