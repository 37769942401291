import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/styleNew.css";
import { Link } from "react-router-dom";
import { BsEmojiSmile } from "react-icons/bs";
import noti from "../../assets/images/dashboard/notii.png";
import "elix/define/SlidingPages";

import A from "./components/clock/A";
import E from "./components/clock/E";
import C from "./components/clock/C";
import D from "./components/clock/D";
import styled from "styled-components";
import { EconomicCalendar } from "react-tradingview-embed";
import Alert from "react-bootstrap/Alert";

import {
  fireAuthState,
  getFourPostFromFirebase,
  getOneUsers,
  getPaymentDetails,
} from "../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import { switchMap } from "rxjs/operators";

const Dashboard = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [User, setUser] = useState(null);
  const [news, setNews] = useState(null);
  const [users, setUsers] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const effect = useEffect(() => {
    const subs = [];

    firstValueFrom(fireAuthState)
      .then((user) => {
        if (user) {
          setUser(user);

          // const id = User?.uid;
          // console.log(id);
          const subscribe = getOneUsers(user.uid).subscribe((item) => {
            // console.log(item);
            setUsers(item);
          });

          subs.push(subscribe);
        }
      })

      .catch((error) => {
        window.location.href = "/auth";
        console.log(error);
      });

    const subss = getFourPostFromFirebase().subscribe((item) => {
      setNews(item);
    });

    const subscribtion = fireAuthState
      .pipe(switchMap((user) => getPaymentDetails(user.uid)))
      .subscribe((item) => {
        if (item) {
          setPaymentDetails(item);
          console.log(item);
        }
      });

    return () => {
      subscribtion.unsubscribe();

      subs.forEach((element) => {
        element.unsubscribe();
      });
      subss.unsubscribe();
      // document.body.removeChild(script);
    };
  }, []);

  const ClockWrapper = styled.div`
    object-fit: contain;
    width: 100%;
    padding-left: 7px;

    h3 {
      margin: 2rem 0 0;
      font-family: Raleway;
      font-size: 1.25rem;
      font-weight: 300;
    }

    @media (max-width: 700px) {
      width: 100%;
      padding: 4rem 0;
    }
  `;

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" lg="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" lg="10" className="mainbodyNew">
            <Header></Header>

            <div className="wb_text">
              <h3>Welcome back</h3>
              <div className="wb_username">
                <BsEmojiSmile> </BsEmojiSmile> {users?.firstname}{" "}
                {users?.lastname}
              </div>

              {paymentDetails === null || paymentDetails?.length === 0 ? (
                <Alert variant="warning" style={{ marginRight: "20px" }}>
                  Hi {users?.username}, <a href="/profile"> click here </a> to
                  add your payment details
                </Alert>
              ) : (
                <p> </p>
              )}
            </div>

            <Container className="weather_con ">
              <Row>
              <Col sm="12" md="6" className="weather_card">
                        <iframe
                          src="https://www.meteoblue.com/en/weather/widget/daily?geoloc=detect&days=4&tempunit=CELSIUS&windunit=KILOMETER_PER_HOUR&precipunit=MILLIMETER&coloured=monochrome&pictoicon=0&pictoicon=1&maxtemperature=0&maxtemperature=1&mintemperature=0&mintemperature=1&windspeed=0&windspeed=1&windgust=0&winddirection=0&winddirection=1&uv=0&humidity=0&precipitation=0&precipitation=1&precipitationprobability=0&precipitationprobability=1&spot=0&spot=1&pressure=0&layout=light"
                          frameborder="0"
                          style={{
                            pointerEvents: "none",
                            cursor: "default",
                            textDecoration: "none",
                            color: "black",
                          }}
                          scrolling="NO"
                          allowtransparency="true"
                          sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
                        ></iframe>
                        {/* <div><!-- DO NOT REMOVE THIS LINK --><a href="https://www.meteoblue.com/en/weather/week/index?utm_source=weather_widget&utm_medium=linkus&utm_content=daily&utm_campaign=Weather%2BWidget" target="_blank">meteoblue</a></div> */}
                      </Col>
              </Row>
            </Container>

            {/* <elix-sliding-pages id="searchSlide" className="mobile_slide">
              <div id="m_card">
                <div className="weather_card">
                  <p>Wed Oct 27 </p>
                  <p>09:00am</p>
                  <h1>5&#8451;</h1>
                  <h3>Mostly Cloudy</h3>
                </div>
              </div>
              <div>
                <div className="weather_card">
                  <p>Wed Oct 27 </p>
                  <p>09:00am</p>
                  <h1>5&#8451;</h1>
                  <h3>Mostly Cloudy</h3>
                </div>
              </div>
              &nbsp;
              <div>
                <div className="weather_card">
                  <p>Wed Oct 27 </p>
                  <p>09:00am</p>
                  <h1>5&#8451;</h1>
                  <h3>Mostly Cloudy</h3>
                </div>
              </div>
            </elix-sliding-pages>

            <br />
            <br /> */}

            <Container className="weather_con">
              <Row>
                <Col sm="12" md="5" lg="5" className="notifi_card">
                  <EconomicCalendar
                    widgetProps={{
                      width: "100%",
                      theme: "light",
                      style: "1",
                      locale: "en",
                      isTransparent: true,
                      displayMode: "adaptive",
                      locale: "en",
                    }}
                  ></EconomicCalendar>
                </Col>

                <Col sm="6" md="6" lg="6" className="notifi_card">
                  <h3>Notifications</h3>

                  <div className="noti_items">
                    {news?.length === 0 ? (
                      <></>
                    ) : (
                      news?.map((item) => (
                        <a href={`/view-post?id=${item.id}`}>
                          <div className="noti">
                            <div className="noti_img">
                              <img src={item?.mainImage?.imageUrl} />
                              {/* <div className="crypto-new"> {item?.cat} </div> */}
                            </div>

                            <div sm="5" md="5" className="noti_title">
                              <h2> {item?.title} </h2>

                              {/* <div class="crypto-new">&nbsp;Hot</div> */}
                              
                              
                            </div>

                            
                          </div>
                        </a>
                      ))
                    )}
                  </div>
                </Col>
              </Row>
            </Container>

            <br />

            <Container  fluid className="clock">
              <Row>
                <Col sm="10" md="12" className=" clk_card">
                  <Row>
                    <Col sm="3" md="3">
                      <ClockWrapper className="clock-card">
                        <A size={300} className="aa" />
                        <h3>New York</h3>
                      </ClockWrapper>
                    </Col>

                    <Col sm="3" md="3">
                      <ClockWrapper className="clock-card">
                        <E size={300} className="aa" />
                        <h3>Tokyo</h3>
                      </ClockWrapper>
                    </Col>

                    <Col sm="3" md="3">
                      <ClockWrapper className="clock-card">
                        <C size={300} className="aa" />
                        <h3>Australia</h3>
                      </ClockWrapper>
                    </Col>

                    <Col sm="3" md="3">
                      <ClockWrapper className="clock-card">
                        <D size={300} className="aa" />
                        <h3>London</h3>
                      </ClockWrapper>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
