import React, { useEffect, useState } from "react";
import "../../assets/css/front/style.css";
import "../../assets/css/front/store.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getSettings } from "../../server/firebase.config";

const Help = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [settings, setSettings] = useState(null);
  // console.log(settings)

  const effect = useEffect(() => {
    const subscription = getSettings().subscribe((item) => {
      //   console.log(item)
      setSettings(item);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return (
    <>
      <Header></Header>

      <div className="clearfix"></div>

      <div className="crypto">
        <div className="crypto_head">
          <div className="crypto_head_title">&nbsp; Terms & Conditions</div>
        </div>

        <Container>
          <Row>
          {settings?.map((item) => (
              <Col sm="12" md="12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${item?.terms }`,
                  }}
                ></div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Help;
