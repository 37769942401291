import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/admin/style.css";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { config } from "rxjs";
import {
    editClass,getimageFromFirebase,getOneClass
  
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import { MDBNotification } from "mdbreact";
import queryString from "query-string";
import UploadAdapter, { uploadResult$} from "../../../../utils/custom-ck-adapter";
// import Toast from "react-bootstrap/Toast";
// import ToastContainer from 'react-bootstrap/ToastContainer';

const EditClass = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [showToast, setshowToast] = useState(false);
  const [Class, setClass] = useState(null);
  const file = useRef(null);
  const editorData = useRef("");
  const imageDetails = useRef(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Image, setImages] = useState(null);

  let url = window.location.href;
  let id = queryString.parse(url);
  const realId = Object.values(id)[0];

  // let secondid = queryString.parse(url);
  const mainId = Object.values(id)[1];

  // console.log(mainId)
  // console.log(realId)

  const effect = useEffect(() => {
    firstValueFrom(getimageFromFirebase())
      .then((item) => {
        // console.log(item);
        setImages(item);
      })
      .catch((error) => {
        console.log(error);
      });

      const subscription = getOneClass(mainId, realId).subscribe((item) => {
          console.log(item)  
        setClass(item);
      });

      const sub = uploadResult$.subscribe(item => {
        // console.log(item);
        imageDetails.current = item
      });


    return () =>{
      sub.unsubscribe();
    
        subscription.unsubscribe();
        // subs.unsubscribe()
      };
  }, []);

 

  const formData = (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }
    // console.log(file.current);

    if (file.current) {
      // console.log(file.current);
      valueFields = {
        ...valueFields,
        mainImage: file.current,
      
      };
    }

    valueFields = {...valueFields,  editorData: editorData.current}

    // console.log(valueFields);

    // return

    editClass(mainId, realId, valueFields)
    alert('Class Edited')
    // document.getElementById("form").reset();
    // editorData.current = " ";
    // setshowToast(true)
    
    
  };

  const selectImage = (event) => {
    // console.log({ event });
    file.current = event;
    handleClose();
  };


  // const editor = () =>{
  //   ClassicEditor
  //                     .create( document.querySelector( '#editor' ), {
  //                         cloudServices: {
  //                             tokenUrl: 'https://example.com/cs-token-endpoint',
  //                             uploadUrl: 'https://your-organization-id.cke-cs.com/easyimage/upload/'
  //                         }
  //                     } )
  //                     .then( ... )
  //                     .catch( ... );
  // }

  return (
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>




  {/* <ToastContainer position="top-end" className="p-3"> */}

  
    {/* <Toast onClose={() => setshowToast(false)} show={showToast} delay={3000} className="toast" autohide>
      
      <Toast.Body className="success">Post Added</Toast.Body>
    </Toast> */}

    <MDBNotification
    
    onClose={() => setshowToast(false)} 
    show={showToast}
        
        fade
        iconClassName="text-primary"
        message="Post Added"
        style={{
          position: "fixed",
          top: "10px",
          background:"green",
          color:"white",
          right: "10px",
          zIndex: 9999
        }}
      />
  {/* </ToastContainer> */}

   

          <div className="clearfix"></div>
          <div className="mb">
            <Container fluid>
              <Row>
                <Col sm="12" md="12" className="livechart">
                {/* {Class?.map((item) => ( */}
                  <Form
                  id="form"
                    className="add-form"
                    onSubmit={(event) => {
                      formData(event);
                    }}
                  >
                      
                    <div className="form-group">
                      <label for="ptitle">Topic</label>
                      <input
                        type="text"
                        id="ptitle"
                        className="form-control"
                        name="title"
                        defaultValue={Class?.title}
                      />
                    </div>
                    




                    {/* <div className="form-group">
                      <label for="video">Video Url</label>
                      <input
                        type="url"
                        id="video"
                        className="form-control"
                        name="classVideo"
                      />
                    </div> */}

                    <div className="form-group">
                      <label for="pimg">Topic Image</label>
                      {/* <input type="file" id="pimg" className="form-control" name="" /> */}
                      <div className="dropzone" onClick={handleShow}>
                        <FaUpload className="dragicon"></FaUpload>
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>

                     <img src={Class?.mainImage?.imageUrl} style={{width:'100px', height:'100px'}}/>
                    </div>

                    

                    

                    <div className="form-group">
                      <label for="body">Description</label>
                      <CKEditor
                      onReady={(editor) => {
                         
                        editor.plugins.get(
                          "FileRepository"
                        ).createUploadAdapter = (loader) => {
                          return new UploadAdapter(loader);
                        };
                      }}
                      data={Class?.editorData}
                        editor={ClassicEditor}
                        config={(config.height = "500")}
                        onChange={(event, editor) => {
                          editorData.current = editor.getData();
                          // console.log( { event, editor, data } );
                        }}
                        name="body"
                      />
                    </div>

                    <div className="form-group">
                      <Button type="submit" variant="outline-dark">
                        Publish
                      </Button>
                      &nbsp;

                      <Button type="submit" variant="outline-danger">
                        Draft
                      </Button>
                    </div>
                  </Form>
                  {/* ))
                } */}
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {Image?.map((item) => (
                <Col sm="12" md="4">
                  <Card
                    onClick={() => {
                      selectImage(item);
                    }}
                    className="imgSelection-li"
                  >
                    <label class="img-label" for={`cb${item.imageUrl}`}>
                      <Card.Img
                        variant="top"
                        className=""
                        src={item.imageUrl}
                      />
                    </label>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      </Container>
    </div>
  );
};

export default EditClass;
