import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/clients/style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {getLivecourse, deleteLivecourse} from "../../../../server/firebase.config";


const Golive = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  const [live, setLive] = useState(null);



  const effect = useEffect(() => {

    
    const subscription = getLivecourse().subscribe(item => {
        setLive(item)
    })
     

      return () => {
        subscription.unsubscribe()
      }


     

  }, []);

  return (
    <div>
      <Container fluid>
      <Row>
        <Col md="2" className="sidebar-col">
          <Sidebar></Sidebar>
        </Col>
        <Col sm="12" md="10" className="mainbody">
          <Header></Header>

          <div className="clearfix"></div>
          <div className="mb">
           

            <Container fluid className="admin_dashboard_stat_card2">
              <Row>
              {live?.map(
                    (item) => 
                <Col sm="12" md="12">
                
                  <Card className="stat_card2">
            

<iframe
src={item?.url} style={{height:'70vh'}}
title="YouTube video player" frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>


                 




</Card>

<Button  onClick={()=> {deleteLivecourse(item.id, item.userId)} } >Finish Live</Button>
                </Col>

                
                )}
                 
                
                
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      </Container>
    </div>
  );
};

export default Golive;
