import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import {
  fireAuthState,
  getOneUsers,
  editUser,
  getUserDetails$,
  stripeSetUpIntentCardSave,
  getPaymentDetails,
} from "../../server/firebase.config";
import { firstValueFrom } from "rxjs";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaCcVisa } from "react-icons/fa";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Moment from "react-moment";
import { switchMap } from "rxjs/operators";
// import { Button } from "@material-ui/core";

export function Card() {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [userPayment, setUserPayment] = useState([]);

  const nameRef = useRef(null);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSubsChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubsSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const result = await firstValueFrom(getUserDetails$);
    // console.log(result.userId)
    const secret = await stripeSetUpIntentCardSave({
      userId: result.userId,
      customerId: result.customerId,
    });

    const data = await stripe.confirmCardSetup(secret.data, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: nameRef.current.value,
        },
      },
    });

    if (data.error) {
      setError(`Card not added ${data.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <>
      {/* SUBSCRIPTION FORM */}

      <form id="payment-form" onSubmit={handleSubsSubmit}>
        <div class="form-group">
          <label for="name"> Name </label>
          <input type="text" className="form-control" id="name" ref={nameRef} />
        </div>
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleSubsChange}
        />
        <button
          style={{ marginTop: "20px" }}
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Add Card "
            )}
          </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? "result-message" : "result-message hidden"}>
          Card Added Successful
        </p>
      </form>
    </>
  );
}

const promise = loadStripe(
  "pk_live_51JLrWiGTx2jTSYdz7FzHtNVBWH89XkMtP7iFHDmwDTN5sdwe0uK9yc4qPAn1stlHTuU8EoVtfDIfzxrhOXBLFltt00PPc4sXBf"
);

const Profile = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [User, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [edit, setEdit] = useState(true);
  const [editPayment, setEditPayment] = useState(true);
  const [userPayment, setUserPayment] = useState([]);

  // const id = useRef('')

  const editpayOpen = () => {
    setEditPayment(false);
  };
  const editpayClose = () => {
    setEditPayment(true);
  };

  const editOneOpen = () => {
    setEdit(false);
  };

  const editOneClose = () => {
    setEdit(true);
  };
  const effect = useEffect(() => {
    const subs = [];
    firstValueFrom(fireAuthState)
      .then((user) => {
        if (user) {
          setUser(user);
          // alert(user.uid)
          const subscribe = getOneUsers(user.uid).subscribe((item) => {
            setUsers(item);
          });

          subs.push(subscribe);
        }
      })

      .catch((error) => {
        console.log(error);
      });

    return () => {
      subs.forEach((element) => {
        element.unsubscribe();
      });
      // subscription.unsubscribe();
    };
  }, []);

  const id = User?.uid;
  console.log(id);
  const formData = async (event) => {
    event.preventDefault();

    const values = Array.from(event.target.elements)
      .map((item) => ({
        name: item.name,
        value: item.value,
      }))
      .filter((val) => val.name !== "");

    let valueFields = {};

    for (const items in values) {
      const { name, value } = values[items];
      valueFields[name] = value;
    }

    // console.log(valueFields);

    // return
    try {
      await editUser(id, valueFields);
      await alert("profile edited");
    } catch (error) {
      console.error(error);
    }

    // document.getElementById("form").reset();
  };

  useEffect(() => {
    const subscribtion = fireAuthState
      .pipe(switchMap((user) => getPaymentDetails(user.uid)))
      .subscribe((item) => {
        if (item) {
          setUserPayment(item);
          // console.log(item);
        }
      });

    return () => {
      subscribtion.unsubscribe();
    };

    // Create PaymentIntent as soon as the page loads
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>
            <div className="clearfix"></div>
            <br/>
            <br/>
            <div className="mb-profile">
              <Container fluid>
                <Row>
                  <Col sm="12" md="5" className="profile_col_side1">
                    <div className="profile_col_side1_bd1">
                      <img
                        className="pro_img"
                        src="http://assets.stickpng.com/images/585e4bf3cb11b227491c339a.png"
                      />

                      <div className="pro_name">
                        {users?.firstname} {users?.lastname}
                      </div>

                      <div className="pro_email">{users?.email}</div>

                      <div className="pro_info">
                        <Table bordered hover>
                          <thead>
                            <tr>
                              <th>Subscription</th>
                              <th>Since</th>
                              <th>Next billing</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{users?.subscription}</td>
                              <td>{users?.regDate}</td>
                              <td>{users?.las}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      {edit ? (
                        <div className="pro_info_acc">
                          <div className="pro_acc_details_head">
                            <div className="hd1">Details</div>
                            <div className="hd2">
                              <AiOutlineEdit
                                style={{ fontSize: "20px" }}
                                onClick={editOneOpen}
                              ></AiOutlineEdit>
                            </div>
                          </div>

                          <div className="pro_acc_details">Account Details</div>
                          <div className="pro_acc_details_body">
                            <p>
                              {users?.firstname} {users?.lastname}
                            </p>
                            <p>{users?.email}</p>
                          </div>

                          <div className="pro_acc_details">Billing Emails</div>
                          <div className="pro_acc_details_body">
                            <div>{users?.email}</div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className=""
                            style={{ float: "right", paddingBottom: "20px" }}
                          >
                            <AiOutlineClose
                              style={{ fontSize: "20px" }}
                              onClick={editOneClose}
                            ></AiOutlineClose>
                          </div>
                          <Form
                            onSubmit={(event) => {
                              formData(event);
                            }}
                          >
                            <Row>
                              <Col sm="12" md="6">
                                <div className="form-group">
                                  <label for="fname">First Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="fname"
                                    defaultValue={users?.firstname}
                                    name="firstname"
                                  />
                                </div>
                              </Col>

                              <Col sm="12" md="6">
                                <div className="form-group">
                                  <label for="lname">Last Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="lname"
                                    name="lastname"
                                    defaultValue={users?.lastname}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="form-group">
                              <label for="uname">Username</label>
                              <input
                                type="text"
                                className="form-control"
                                id="uname"
                                name="username"
                                defaultValue={users?.username}
                              />
                            </div>

                            <div className="form-group">
                              <Button type="submit" variant="outline-danger">
                                Edit
                              </Button>
                            </div>
                          </Form>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col sm="12" md="7" className="profile_col_side2">
                    <div className="profile_col_side2_bd2">
                      <div className="pro_subs">
                        <div className="pro_subs_head">
                          <div className="subs_hd1">Subscriptions</div>
                          <div className="subs_hd2">
                            <Button variant="outline-secondary">Upgrade</Button>
                          </div>
                        </div>

                        <div className="pro_info">
                          <Table bordered hover>
                            <tbody>
                              <tr>
                                <td>{users?.subscription}</td>
                                <td>
                                  <div>
                                    {" "}
                                    <span
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      {" "}
                                      {users?.subscription} Plan{" "}
                                    </span>
                                    <Badge className="success" bg="success">
                                      Active
                                    </Badge>{" "}
                                  </div>
                                  <div>
                                    Billing Monthly &nbsp; | &nbsp; Next Invoice
                                    on &nbsp;

                                    {/* {new Intl.DateTimeFormat("en-US", {
                                      dateStyle: "full",
                                    }).format(
                                      users?.subscriptions?.current_period_end
                                    )} */}



                                  </div>
                                </td>
                                <td>
                                  <ButtonGroup aria-label="Basic example">
                                    <Button variant="outline-dark">
                                      <FiEdit2></FiEdit2>
                                    </Button>
                                    <Button variant="outline-dark">
                                      <AiOutlineDelete></AiOutlineDelete>
                                    </Button>
                                    <Button variant="outline-dark">
                                      <HiOutlineDotsHorizontal></HiOutlineDotsHorizontal>
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      <div className="pro_subs">
                        <div className="pro_subs_head">
                          <div className="subs_hd1">Payments</div>
                          <div className="subs_hd2">
                            <Button variant="outline-secondary">
                              View All
                            </Button>
                          </div>
                        </div>

                        <div className="pro_info">
                          <Table bordered hover>
                            <thead>
                              <tr>
                                <th>Amount</th>
                                <th>Description</th>
                                <th>Date</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td>
                                  ${users?.subscriptions?.plan?.amount_decimal}
                                </td>
                                <td>{users?.subscription}</td>
                                <td>
                                {/* <Moment format="D MMM YYYY" interval={10000} withTitle>{users?.subscriptions?.current_period_start}</Moment> */}
                                  {/* {new Intl.DateTimeFormat("en-US", {
                                    dateStyle: "full",
                                  }).format(
                                    users?.subscriptions?.current_period_start
                                  )} */}
                                </td>
                                <td>
                                  <HiOutlineDotsHorizontal></HiOutlineDotsHorizontal>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      <div className="pro_subs">
                        <div className="pro_subs_head">
                          <div className="subs_hd1">Payment Method</div>
                          <div className="subs_hd2">
                            {/* <Button variant="outline-secondary">
                              View All
                            </Button> */}
                          </div>
                        </div>

                        <div className="pro_info">
                          {userPayment === null || userPayment.length === 0 ? (
                            <>
                            <Elements stripe={promise}>
                              <Card />
                            </Elements>
                          </>
                          ) : (
                            
                            <Table>
                            <tbody>
                              {editPayment ? (
                                <tr>
                                  <td>
                                    <FaCcVisa></FaCcVisa>
                                  </td>
                                  <td>
                                    {userPayment?.map((item, index) => (
                                      <>
                                        <div key={index}>
                                          {item.card.brand} ****{" "}
                                          {item.card.last4}{" "}
                                          <Badge className="" bg="success">
                                            default
                                          </Badge>
                                        </div>
                                        <div>
                                          Expires {item.card.exp_month}{" "}
                                          {item.card.exp_year}
                                        </div>
                                      </>
                                    ))}
                                  </td>
                                  <td>
                                    <ButtonGroup aria-label="Basic example">
                                      <Button variant="outline-dark">
                                        <FiEdit2
                                          onClick={editpayOpen}
                                        ></FiEdit2>
                                      </Button>
                                      <Button variant="outline-dark">
                                        <AiOutlineDelete></AiOutlineDelete>
                                      </Button>
                                      <Button variant="outline-dark">
                                        <HiOutlineDotsHorizontal></HiOutlineDotsHorizontal>
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              ) : (
                                <Elements stripe={promise}>
                                  <Card />
                                </Elements>
                              )}
                            </tbody>
                          </Table>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
