import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import "../../../../assets/css/clients/style.css";
import img from "../../../../assets/images/technicals/1.jpg";
import { GiCampfire } from "react-icons/gi";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  getPostFromFirebase,
  deletePost,
} from "../../../../server/firebase.config";
import { firstValueFrom } from "rxjs";

const Viewpost = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->
  const [Post, setPost] = useState(null);

  const effect = useEffect(() => {
    const subscription = getPostFromFirebase().subscribe((item) => {
      setPost(item);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // const effect = useEffect(() => {

  //   firstValueFrom(getPostFromFirebase())
  //     .then((item) => {
  //       console.log(item)
  //       setPost(item)

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // }, []);

  // const dpost = (id) => {
  //   deletePost(id);

  // }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="2" className="sidebar-col">
            <Sidebar></Sidebar>
          </Col>
          <Col sm="12" md="10" className="mainbody">
            <Header></Header>

            <div className="clearfix"></div>

            <Container fluid className="mb feeds crypto_posts">
              <div className="news_head">
                <form>
                  <div className="form-group">
                    <select className="form-control">
                      <option value="all">All</option>
                      <option value="technicals">Technicals</option>
                      <option value="techArticles">Technical Articles</option>
                      <option value="market">Market News</option>
                      <option value="crypto">Cryptocurrency</option>
                    </select>
                  </div>
                </form>
              </div>

              <Row>
                {Post?.map((item) => (
                  <Col sm="12" md="3">
                    <Card className="crypto_card">
                      <Card.Img
                        variant="top"
                        className="crypto_cardImg"
                        src={item?.mainImage?.imageUrl}
                      />
                      {item?.hot === "yes" ? (
                        <div class="crypto-hot">
                          <GiCampfire></GiCampfire> &nbsp;Hot
                        </div>
                      ) : (
                        <> </>
                      )}

                      {item?.new === "yes" ? (
                        <div class="crypto-new">New</div>
                      ) : (
                        <> </>
                      )}
                      <Card.Body className="crypto_cardBody">
                        <a href="product-page">
                          {" "}
                          <p className="prodTitle">{item.title}</p>{" "}
                        </a>

                        <div>
                          {" "}
                          <span className="prodby">
                            {" "}
                            <a href={`/admin/edit-post?id=${item.id}`}>
                              {" "}
                              <FaEdit className="icons"></FaEdit>{" "}
                            </a>{" "}
                          </span>{" "}
                          &nbsp;
                          <span
                            className="proddate"
                            onClick={() => {
                              deletePost(item.id);
                            }}
                          >
                            <FaTrash className="FaCalendar"></FaTrash>{" "}
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>

              <div className="more-news">
                <div className="more-news_btn">Load More</div>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Viewpost;
