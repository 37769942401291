import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import "../../assets/css/clients/style.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AboutSettings from "./subpages/settings/about";
import Socials from "./subpages/settings/socials";
import Plans from "./subpages/settings/membership";
import Coupon from "./subpages/settings/coupons";
// import {} from "../../server/firebase.config";

const Settings = () => {
  // <!-- ////////// PRODUCT OF OBSIDIAN INC., WRITTEN AND DESIGNED BY GROOVE DEVELOPERS INC. YOU ARE PROHIBITED FROM USING OR EDITING
  // THIS APPLICATION WITHOUT INFORMING GROOVE DEVELOPERS INC AND OBSIDIAN INC. ///////////-->

  return (
    <div>

        <Container fluid>
            <Row>
                <Col md="2" className="sidebar-col">
                    <Sidebar></Sidebar>
                </Col>
                <Col sm="12" md="10" className="mainbody">
                    <Header></Header>

                    <div className="mb">
                        <div className="downloads_tab">

                    <Tabs defaultActiveKey="about"  id="uncontrolled-tab-example">
  <Tab eventKey="about" title="About Us">
    <div className="downloads_tab_contents">
        <AboutSettings></AboutSettings>
    </div>
  </Tab>
  <Tab eventKey="socials" title="Social Pages">
  <div className="downloads_tab_contents">
        <Socials></Socials>
    </div>
  </Tab>
  <Tab eventKey="plans" title="Membership" >
  <div className="downloads_tab_contents">
        <Plans></Plans>
    </div>
  </Tab>

  <Tab eventKey="coupon" title="Coupons" >
  <div className="downloads_tab_contents">
        <Coupon></Coupon>
    </div>
  </Tab>
</Tabs>

</div>

                       

                    </div>

                    
                </Col>
            </Row>

        </Container>
      

      
    </div>
  );
};

export default Settings;
